import { cn } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";

interface MetricCardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode;
  description?: string;
  variant?: "default" | "success" | "warning" | "danger";
  onClick?: () => void;
  className?: string;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  icon,
  description,
  variant = "default",
  onClick,
  className
}) => {
  const getVariantStyles = () => {
    switch (variant) {
      case "success":
        return {
          iconBg: "bg-emerald-500/10",
          iconColor: "text-emerald-500",
          valueColor: "text-emerald-500"
        };
      case "warning":
        return {
          iconBg: "bg-amber-500/10",
          iconColor: "text-amber-500",
          valueColor: "text-amber-700"
        };
      case "danger":
        return {
          iconBg: "bg-red-500/10",
          iconColor: "text-red-500",
          valueColor: "text-red-500"
        };
      default:
        return {
          iconBg: "bg-primary/10",
          iconColor: "text-primary",
          valueColor: "text-foreground"
        };
    }
  };

  const styles = getVariantStyles();

  return (
    <Card 
      className={cn(
        "overflow-hidden border shadow-sm cursor-pointer bg-card/50 backdrop-blur-sm hover:border-primary/20 transition-colors",
        onClick && "hover:bg-accent/5",
        className
      )} 
      onClick={onClick}
    >
      <CardContent className="p-4 sm:p-5">
        <div className="flex justify-between items-start">
          <div className="space-y-1 sm:space-y-2">
            <p className="text-xs sm:text-sm text-muted-foreground font-medium">
              {title}
            </p>
            <p className={cn("text-lg sm:text-2xl font-bold", styles.valueColor)}>
              {value}
            </p>
            {description && (
              <p className="text-xs text-muted-foreground">
                {description}
              </p>
            )}
          </div>
          <div className={cn("p-2 rounded-lg", styles.iconBg)}>
            <div className={cn("h-5 w-5", styles.iconColor)}>
              {icon}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MetricCard;
