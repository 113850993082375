import { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { ChevronRight, ShieldCheck, Globe } from 'lucide-react';

const quotes = [
  {
    text: "The future of asset ownership is digital",
    author: "EvoCash",
    gradient: "from-blue-600 via-blue-500 to-blue-400",
    icon: "security"
  },
  {
    text: "Secure your wealth in digital gold",
    author: "AGC",
    gradient: "from-amber-600 via-amber-500 to-amber-400",
    icon: "wealth"
  },
  {
    text: "Trade with confidence, secured by blockchain",
    author: "EvoCash",
    gradient: "from-indigo-600 via-indigo-500 to-indigo-400", 
    icon: "trade"
  },
  {
    text: "Your gateway to tokenized assets",
    author: "EvoCash",
    gradient: "from-purple-600 via-purple-500 to-purple-400",
    icon: "gateway"
  }
];

export const QuoteScreen = () => {
  const [quote, setQuote] = useState(quotes[0]);
  const [mounted, setMounted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const quoteRef = useRef(null);
  const x = useMotionValue(0);
  const scale = useTransform(x, [-200, 0, 200], [0.9, 1, 0.9]);
  const opacity = useTransform(x, [-200, 0, 200], [0.5, 1, 0.5]);
  const rotate = useTransform(x, [-200, 0, 200], [5, 0, -5]);
  const background = useTransform(
    x,
    [-200, 0, 200],
    [
      quote.gradient.split(' ')[0].replace('from-', ''), 
      'transparent', 
      quote.gradient.split(' ')[2].replace('to-', '')
    ]
  );

  useEffect(() => {
    setQuote(quotes[0]);
    setMounted(true);
    
    // Auto rotate quotes for demo
    const timer = setInterval(() => {
      nextQuote();
    }, 8000);
    
    return () => clearInterval(timer);
  }, []);

  const triggerHapticFeedback = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate([5]);
    }
  };

  const handleDragEnd = (event, info) => {
    if (Math.abs(info.offset.x) > 100) {
      triggerHapticFeedback();
      if (info.offset.x > 0) {
        previousQuote();
      } else {
        nextQuote();
      }
    }
  };

  const nextQuote = () => {
    const newIndex = (currentIndex + 1) % quotes.length;
    setCurrentIndex(newIndex);
    setQuote(quotes[newIndex]);
  };

  const previousQuote = () => {
    const newIndex = (currentIndex - 1 + quotes.length) % quotes.length;
    setCurrentIndex(newIndex);
    setQuote(quotes[newIndex]);
  };

  const getIcon = (iconName) => {
    switch(iconName) {
      case 'security':
        return <ShieldCheck className="w-6 h-6 text-blue-500" />;
      case 'wealth':
        return <motion.div 
          animate={{ rotate: 360 }}
          transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          className="relative w-6 h-6"
        >
          <div className="absolute inset-0 bg-amber-500 rounded-full opacity-30"></div>
          <div className="absolute inset-1 bg-amber-400 rounded-full"></div>
        </motion.div>;
      case 'trade':
        return <motion.div 
          animate={{ y: [-1, 1, -1] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="text-indigo-500"
        >
          <Globe className="w-6 h-6" />
        </motion.div>;
      default:
        return <motion.div
          animate={{ rotate: [-10, 10, -10] }}
          transition={{ duration: 4, repeat: Infinity }}
          className="relative w-6 h-6 text-purple-500"
        >
          <div className="absolute inset-0 border-2 border-purple-400 rounded-md rotate-45"></div>
          <div className="absolute inset-1 border border-purple-500 rounded-sm"></div>
        </motion.div>;
    }
  };

  return (
    <div className="min-h-[100dvh] bg-black relative flex flex-col items-center justify-center p-6 overflow-hidden">
      {/* Dynamic 3D-like background */}
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-900 via-gray-950 to-black"></div>
      
      {/* Animated grid overlay */}
      <div className="absolute inset-0 bg-grid-white/[0.02] bg-[length:24px_24px] pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
      </div>
      
      {/* Dynamic accent lighting based on current quote */}
      <motion.div 
        animate={{
          opacity: [0.3, 0.5, 0.3],
        }}
        style={{
          background: `radial-gradient(circle at 20% 20%, var(--${quote.gradient.split('-')[1]}-500), transparent 70%)`,
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className="absolute inset-0 pointer-events-none"
      />

      <AnimatePresence mode="wait">
        {mounted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="relative z-10 flex flex-col items-center justify-center w-full max-w-md space-y-12"
          >
            {/* Premium logo effect */}
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.9, delay: 0.3 }}
              className="relative"
            >
              <motion.div
                animate={{
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className={cn(
                  "absolute -inset-8 rounded-full",
                  "bg-gradient-to-r",
                  quote.gradient,
                  "blur-xl opacity-20"
                )}
              />
              <motion.div 
                whileHover={{ scale: 1.05, rotate: 2 }}
                whileTap={{ scale: 0.98 }}
                className="relative p-5 backdrop-blur-sm bg-black/40 rounded-2xl border border-gray-800 shadow-2xl"
              >
                <img 
                  src="/logo.svg"
                  alt="EvoCash Logo" 
                  className="w-20 h-20 object-contain drop-shadow-lg"
                />
              </motion.div>
            </motion.div>

            {/* Swipeable quote cards */}
            <motion.div 
              ref={quoteRef}
              style={{ x, scale, rotate }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.1}
              onDragEnd={handleDragEnd}
              whileTap={{ cursor: "grabbing" }}
              className="w-full px-4 touch-manipulation select-none perspective-1000"
            >
              <motion.div
                style={{ opacity }}
                className={cn(
                  "glass-premium p-8 rounded-2xl backdrop-blur-md",
                  "bg-black/30 border border-gray-800/50",
                  "shadow-[0_8px_32px_rgba(0,0,0,0.4)]"
                )}
              >
                <div className="flex items-start mb-6">
                  <div className="mr-4">
                    {getIcon(quote.icon)}
                  </div>
                  <motion.h1 
                    key={quote.text}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.6 }}
                    className={cn(
                      "text-3xl font-bold tracking-tight",
                      "bg-gradient-to-br",
                      quote.gradient,
                      "bg-clip-text text-transparent"
                    )}
                  >
                    {quote.text}
                  </motion.h1>
                </div>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                  className="flex items-center justify-between"
                >
                  <p className="text-sm text-gray-400 font-medium">
                    — {quote.author}
                  </p>
                  <div className="flex space-x-1">
                    {quotes.map((_, idx) => (
                      <motion.div 
                        key={idx}
                        initial={{ scale: 0.8, opacity: 0.5 }}
                        animate={{ 
                          scale: currentIndex === idx ? 1 : 0.8,
                          opacity: currentIndex === idx ? 1 : 0.5
                        }}
                        transition={{ duration: 0.4 }}
                        className={cn(
                          "w-2 h-2 rounded-full transition-all duration-300",
                          currentIndex === idx
                            ? `bg-${quote.gradient.split('-')[1]}-500`
                            : "bg-gray-700"
                        )}
                      />
                    ))}
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>

            {/* Indicator for swiping */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.7, y: [0, 5, 0] }}
              transition={{ 
                opacity: { delay: 1, duration: 1 },
                y: { repeat: Infinity, duration: 2 }
              }}
              className="flex items-center space-x-2 text-gray-500"
            >
              <motion.div
                animate={{ x: [-5, 0, -5] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <ChevronRight className="w-4 h-4 rotate-180" />
              </motion.div>
              <span className="text-xs font-medium">Swipe to explore</span>
              <motion.div
                animate={{ x: [0, 5, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <ChevronRight className="w-4 h-4" />
              </motion.div>
            </motion.div>

            {/* Sleek buttons */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.6 }}
              className="w-full space-y-4 px-4"
            >
              <Button 
                className={cn(
                  "w-full h-14 rounded-xl text-white font-medium text-lg",
                  "bg-gradient-to-r",
                  quote.gradient,
                  "shadow-lg shadow-blue-900/20",
                  "transition-all duration-300 transform-gpu",
                  "hover:shadow-xl hover:shadow-blue-800/30",
                  "active:scale-98 hover:scale-102"
                )}
                size="lg" 
                asChild
              >
                <Link to="/login" className="flex items-center justify-center">
                  <span>Sign In</span>
                  <motion.div
                    animate={{ x: [0, 5, 0] }}
                    transition={{ duration: 2, repeat: Infinity, repeatDelay: 1 }}
                  >
                    <ChevronRight className="ml-1 w-5 h-5" />
                  </motion.div>
                </Link>
              </Button>
              
              <Button 
                variant="outline" 
                className={cn(
                  "w-full h-14 rounded-xl",
                  "backdrop-blur-md bg-white/5",
                  "border border-gray-700",
                  "text-white font-medium text-lg",
                  "shadow-lg",
                  "transition-all duration-300 transform-gpu",
                  "hover:bg-white/10 hover:border-gray-600",
                  "active:scale-98 hover:scale-102"
                )}
                size="lg" 
                asChild
              >
                <Link to="/signup">Create Account</Link>
              </Button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Enhanced footer */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
        className="absolute bottom-8 flex flex-col items-center space-y-4"
      >
        <motion.div 
          animate={{
            opacity: [0.4, 0.7, 0.4],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            repeatType: "reverse",
          }}
          className="flex items-center justify-center space-x-3"
        >
          <div className="w-8 h-1 rounded-full bg-gradient-to-r from-gray-700 via-gray-500 to-gray-700" />
          <div className="w-2 h-2 rounded-full bg-gray-500" />
          <div className="w-8 h-1 rounded-full bg-gradient-to-r from-gray-700 via-gray-500 to-gray-700" />
        </motion.div>
        <p className="text-xs text-center text-gray-500 px-8 max-w-xs font-medium">
          By continuing, you agree to EvoCash's{' '}
          <Link to="/terms" className={`text-${quote.gradient.split('-')[1]}-400 hover:text-${quote.gradient.split('-')[1]}-300`}>Terms of Service</Link>
          {' & '}
          <Link to="/privacy" className={`text-${quote.gradient.split('-')[1]}-400 hover:text-${quote.gradient.split('-')[1]}-300`}>Privacy Policy</Link>
        </p>
      </motion.div>
    </div>
  );
};