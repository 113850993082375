import { useState } from 'react';
import { Search, Filter, X, Check } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';

export type AssetTypeFilter = 'All' | 'Fiat' | 'Crypto' | 'Stablecoin' | 'Tokenized Real Estate' | 'Tokenized ETF';

interface WalletSearchProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  assetType?: AssetTypeFilter;
  setAssetType: (type: AssetTypeFilter) => void;
  className?: string;
}

const WalletSearch = ({ 
  searchTerm, 
  setSearchTerm, 
  assetType = 'All', 
  setAssetType,
  className 
}: WalletSearchProps) => {
  const [isFocused, setIsFocused] = useState(false);
  
  const handleClearSearch = () => {
    setSearchTerm('');
  };
  
  const assetTypeOptions: AssetTypeFilter[] = [
    'All', 
    'Fiat', 
    'Crypto', 
    'Stablecoin', 
    'Tokenized Real Estate', 
    'Tokenized ETF'
  ];
  
  return (
    <div className={cn("flex items-center gap-3 w-full", className)}>
      <div className={cn(
        "relative flex-1 transition-all rounded-xl border",
        isFocused ? "ring-2 ring-primary/20 border-primary" : ""
      )}>
        <Search className="absolute left-3.5 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
        <Input
          type="text"
          placeholder="Search by name, symbol or address..."
          className="pl-10 pr-10 h-11 rounded-xl border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {searchTerm && (
          <Button
            variant="ghost"
            size="sm"
            className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7 p-0 rounded-full"
            onClick={handleClearSearch}
          >
            <X className="h-3.5 w-3.5" />
            <span className="sr-only">Clear search</span>
          </Button>
        )}
      </div>
      
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant={assetType !== 'All' ? "default" : "outline"} 
            size="sm" 
            className="h-11 px-4 rounded-xl whitespace-nowrap"
          >
            <Filter className="h-4 w-4 mr-2" />
            <span>{assetType === 'All' ? 'Filter' : `${assetType} Only`}</span>
            {assetType !== 'All' && (
              <Badge variant="secondary" className="ml-2 bg-primary/10 text-primary">
                1
              </Badge>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-48">
          <DropdownMenuLabel>Filter Assets</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {assetTypeOptions.map((type) => (
            <DropdownMenuItem 
              key={type}
              onClick={() => setAssetType(type)}
              className="flex items-center justify-between cursor-pointer"
            >
              <span>{type === 'All' ? 'All Assets' : `${type} Only`}</span>
              {type === assetType && (
                <Check className="h-4 w-4 text-primary" />
              )}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default WalletSearch;
