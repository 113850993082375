import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { 
  Wallet, 
  Menu, 
  ExternalLink,
  LineChart,
  HelpCircle,
  Info,
  Mail,
  ShieldCheck,
  Lock,
  FileText,
  CircleDollarSign,
  ArrowLeftRight,
  Newspaper,
  Home,
  BarChart,
  X,
  ChevronLeft,
  Settings,
  User
} from 'lucide-react';
import { useUser } from '@/contexts/UserContext';
import { DialogTitle } from "@/components/ui/dialog";

import NotificationsPopover from '../dashboard/NotificationsPopover';
import ThemeToggle from './navbar/ThemeToggle';
import { Badge } from '@/components/ui/badge';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, isTokenExpired } = useUser(); // Add user to the destructuring
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  useEffect(() => {
     const checkToken = async () => {
       try {
         const expired = await isTokenExpired();
         console.log("Token expired:", expired);
         if (expired) {
          setIsAuthenticated((prev) => {
              console.log("authenticated:", false);
              return false; // Update state to false
          });
      } else {
          setIsAuthenticated((prev) => {
              console.log("authenticated:", true);
              return true; // Update state to true
          });
      }
       } catch (error) {
         console.log("Error caught:", error);
       }
     };
 
     checkToken();
   }, []);


  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Check if we're on a deep page that should display back button
  const shouldShowBackButton = () => {
    // Add paths that should show back button
    const deepPages = ['/markets/', '/help/', '/news/', '/about/', '/terms/', '/privacy/'];
    return deepPages.some(path => location.pathname.startsWith(path));
  };

  const navigationItems = [
    {
      title: "Trading",
      items: [
        {
          title: "Markets Overview",
          href: "/markets",
          description: "Explore available markets and trading pairs",
          icon: <CircleDollarSign className="w-6 h-6" />
        },
        {
          title: "Visit Dashboard",
          href: "/dashboard",
          description: "Access your trading dashboard",
          icon: <BarChart className="w-6 h-6" />,
          highlight: true
        }
      ]
    },
    {
      title: "Resources",
      items: [
        {
          title: "Help Center",
          href: "/help",
          description: "Get support and find answers",
          icon: <HelpCircle className="w-6 h-6" />
        },
        {
          title: "News & Updates",
          href: "/news",
          description: "Latest updates and announcements",
          icon: <Newspaper className="w-6 h-6" />
        },
        {
          title: "About Us",
          href: "/about",
          description: "Learn about our mission and team",
          icon: <Info className="w-6 h-6" />
        },
      ]
    },
    {
      title: "Legal",
      items: [
        {
          title: "Terms of Service",
          href: "/terms",
          description: "Our terms and conditions",
          icon: <Lock className="w-6 h-6" />
        },
        {
          title: "Privacy Policy",
          href: "/privacy",
          description: "How we handle your data",
          icon: <ShieldCheck className="w-6 h-6" />
        }
      ]
    }
  ];
  
  const mainNavItems = [
    { title: 'Home', path: '/', icon: <Home size={18} /> },
    { title: 'FAQ', path: '/faq', icon: <HelpCircle size={18} /> }
  ];

  const legalNavItems = [
    { title: 'Terms', path: '/terms', icon: <FileText size={18} /> },
    { title: 'Privacy', path: '/privacy', icon: <Lock size={18} /> }
  ];

  return (
    <>
      {/* Backdrop overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-background/80 backdrop-blur-sm z-40 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      <header
        className={cn(
          "fixed top-0 left-0 right-0 z-50 transition-all duration-500",
          "before:absolute before:inset-0 before:z-[-1]",
          isScrolled 
            ? "before:bg-background/95 before:backdrop-blur-xl before:border-b before:border-border/40 h-14" // Reduced height on mobile
            : "before:bg-gradient-to-b before:from-background/80 before:to-transparent h-16", // Reduced height on mobile
          "px-3 sm:px-4 lg:px-6 safe-area-inset-top"
        )}
      >
        <div className="flex h-full items-center justify-between w-full max-w-screen-2xl mx-auto">
          {/* Back Button for Deep Pages on Mobile */}
          {shouldShowBackButton() && (
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 p-0 hover:bg-accent/50 lg:hidden -ml-1"
              onClick={() => window.history.back()}
            >
              <ChevronLeft className="h-5 w-5" />
              <span className="sr-only">Back</span>
            </Button>
          )}

          {/* Logo Section - Mobile Optimized */}
          <Link 
            to="/" 
            className={cn(
              "flex items-center gap-2 relative group",
              shouldShowBackButton() ? "ml-1" : "-ml-1"
            )}
          >
            <div className="relative">
              <div 
                className={cn(
                  "absolute -inset-3 rounded-2xl transition-all duration-500",
                  "bg-gradient-to-r from-primary/80 via-primary/50 to-primary/30",
                  "blur-md opacity-60 group-hover:opacity-100",
                  "animate-pulse group-hover:animate-none",
                  "lg:-inset-6 lg:blur-xl" // Different sizes for desktop
                )} 
              />
              <div 
                className={cn(
                  "relative bg-background/90 backdrop-blur-md rounded-xl p-1.5",
                  "ring-1 ring-border/50 shadow-md",
                  "transition-transform duration-300 group-hover:scale-105",
                  "flex items-center justify-center",
                  "lg:p-2.5 lg:rounded-2xl" // Different sizes for desktop
                )}
                style={{ 
                  width: '2.25rem',    // Smaller on mobile
                  height: '2.25rem',   // Smaller on mobile
                }}
              >
                <img 
                  src="/logo.svg"
                  alt="EvoCash Logo" 
                  className="w-full h-full object-contain"
                  style={{
                    transform: 'scale(2.5) translateY(-1px)'
                  }}
                />
              </div>
            </div>
            <div className="relative -ml-1">
              <span className={cn(
                "text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70",
                "lg:text-2xl" // Larger on desktop
              )}>
                EvoCash
              </span>
              <Badge 
                variant="outline" 
                className={cn(
                  "absolute -top-2.5 -right-10", // Better mobile positioning
                  "bg-background/80 backdrop-blur-sm",
                  "border-primary/20 text-primary/80",
                  "animate-float",
                  "text-xs",
                  "lg:-top-3.5 lg:-right-12 lg:text-sm" // Different on desktop
                )}
              >
                Beta
              </Badge>
            </div>
          </Link>

          {/* Mobile Header Actions */}
          <div className="flex items-center gap-1.5 lg:hidden">
            

            <div className="ml-3">
              <ThemeToggle />
            </div>

            {!isAuthenticated && (
              <Button 
                asChild 
                variant="default" 
                size="sm" 
                className="h-8 px-3 ml-2 rounded-full bg-gradient-to-r from-primary to-primary/90 text-sm shadow-sm"
              >
                <Link to="/login">Login</Link>
              </Button>
            )}

            {isAuthenticated && (
              <>
                <NotificationsPopover />
                <Link 
                  to="/dashboard"
                  className="relative flex items-center justify-center h-8 w-8 rounded-full bg-accent/50"
                >
                  <User className="h-4 w-4" />
                </Link>
              </>
            )}

            <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
              <SheetTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="h-8 w-8 p-0 hover:bg-accent/50 rounded-full"
                >
                  <Menu className="h-4 w-4" />
                  <span className="sr-only">Menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent 
                side="right" 
                className="w-full p-0 border-l border-border/50 backdrop-blur-xl bg-background/95 safe-area-inset-top"
              >
                <div className="flex items-center justify-between p-4 border-b border-border/30">
                  <h2 className="text-lg font-semibold">Menu</h2>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 rounded-full"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <X className="h-4 w-4" />
                    <span className="sr-only">Close</span>
                  </Button>
                </div>

                {/* User Profile Section if Authenticated */}
                {isAuthenticated && (
                  <div className="p-4 border-b border-border/30">
                    <div className="flex items-center gap-3">
                      <div className="h-12 w-12 rounded-full bg-primary/20 flex items-center justify-center">
                        <User className="h-6 w-6 text-primary" />
                      </div>
                      <div>
                        <p className="font-medium">{user?.name || 'User'}</p>
                        <p className="text-sm text-muted-foreground">{user?.email || 'user@example.com'}</p>
                      </div>
                    </div>
                    <div className="mt-3 flex gap-2">
                      <Button 
                        asChild
                        className="flex-1 bg-gradient-to-r from-primary to-primary/90 rounded-full"
                        size="sm"
                      >
                        <Link to="/dashboard">Dashboard</Link>
                      </Button>
                      <Button 
                        asChild
                        variant="outline"
                        size="icon"
                        className="h-8 w-8 rounded-full"
                      >
                        <Link to="/settings">
                          <Settings className="h-4 w-4" />
                        </Link>
                      </Button>
                    </div>
                  </div>
                )}

                <div className="overflow-y-auto h-full pb-safe">
                  {/* Main Navigation Items */}
                  <div className="p-4 space-y-6">
                    {navigationItems.map((section) => (
                      <div key={section.title} className="space-y-3">
                        <h2 className="text-sm font-medium text-muted-foreground px-1">
                          {section.title}
                        </h2>
                        <div className="flex flex-col">
                          {section.items.map((item) => (
                            <Link
                              key={item.title}
                              to={item.href}
                              className={cn(
                                "flex items-center gap-3 p-3 rounded-xl",
                                "transition-all duration-300",
                                "hover:bg-accent/70",
                                item.highlight && "bg-primary/5"
                              )}
                              onClick={() => setIsMobileMenuOpen(false)}
                            >
                              <div className="p-2 rounded-lg bg-primary/10 text-primary">
                                {item.icon}
                              </div>
                              <div>
                                <div className="font-medium">{item.title}</div>
                                <p className="text-sm text-muted-foreground line-clamp-1">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}

                    {/* Quick Links Section */}
                    <div className="space-y-3">
                      <h2 className="text-sm font-medium text-muted-foreground px-1">Quick Links</h2>
                      <div className="flex flex-wrap gap-2">
                        {mainNavItems.map((item) => (
                          <Button
                            key={item.path}
                            asChild
                            variant="outline"
                            className="flex-1 rounded-full h-10"
                          >
                            <Link
                              to={item.path}
                              onClick={() => setIsMobileMenuOpen(false)}
                              className="flex items-center justify-center gap-2"
                            >
                              {item.icon}
                              <span>{item.title}</span>
                            </Link>
                          </Button>
                        ))}
                      </div>
                    </div>

                    {/* Authentication Buttons */}
                    {!isAuthenticated && (
                      <div className="space-y-3">
                        <h2 className="text-sm font-medium text-muted-foreground px-1">Account</h2>
                        <div className="flex flex-col gap-2">
                          <Button 
                            asChild 
                            variant="outline" 
                            className="w-full rounded-full h-10"
                          >
                            <Link 
                              to="/login" 
                              onClick={() => setIsMobileMenuOpen(false)}
                            >
                              Login
                            </Link>
                          </Button>
                          <Button 
                            asChild 
                            className="w-full rounded-full h-10 bg-gradient-to-r from-primary to-primary/90"
                          >
                            <Link 
                              to="/signup" 
                              onClick={() => setIsMobileMenuOpen(false)}
                            >
                              Sign Up
                            </Link>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Footer Section */}
                  <div className="mt-auto p-4 border-t border-border/30">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        {legalNavItems.map((item) => (
                          <Button
                            key={item.path}
                            asChild
                            variant="ghost"
                            size="sm"
                            className="h-8 text-xs text-muted-foreground hover:text-foreground"
                          >
                            <Link to={item.path}>
                              {item.title}
                            </Link>
                          </Button>
                        ))}
                      </div>
                      <ThemeToggle />
                    </div>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>

          {/* Desktop Navigation remains largely unchanged */}
          <div className="hidden lg:flex items-center gap-4">
            <NavigationMenu>
              <NavigationMenuList className="gap-1">
                {navigationItems.map((section) => (
                  <NavigationMenuItem key={section.title}>
                    <NavigationMenuTrigger 
                      className={cn(
                        "text-sm font-medium px-4 py-2",
                        "data-[state=open]:bg-accent/50",
                        "transition-colors duration-300"
                      )}
                    >
                      {section.title}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                        {section.items.map((item) => (
                          <li key={item.title}>
                            <Link
                              to={item.href}
                              className={cn(
                                "block select-none space-y-1 rounded-lg p-3.5",
                                "leading-none no-underline outline-none",
                                "transition-all duration-300",
                                "hover:bg-accent hover:text-accent-foreground",
                                "focus:bg-accent focus:text-accent-foreground",
                                "border border-transparent hover:border-border/50",
                                "hover:shadow-md hover:-translate-y-0.5",
                                item.highlight && "bg-primary/5 border-primary/10"
                              )}
                            >
                              <div className="flex items-center gap-2">
                                <div className="p-1 rounded-md bg-primary/10">
                                  {item.icon}
                                </div>
                                <div className="font-medium">{item.title}</div>
                              </div>
                              <p className="line-clamp-2 text-sm leading-relaxed text-muted-foreground mt-1.5">
                                {item.description}
                              </p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                ))}
              </NavigationMenuList>
            </NavigationMenu>

            <div className="flex items-center gap-4">
              <ThemeToggle />
              
              {isAuthenticated ? (
                <div className="flex items-center gap-4">
                  <NotificationsPopover />
                  <Button 
                    asChild 
                    variant="default" 
                    size="sm" 
                    className={cn(
                      "h-9 px-4",
                      "transition-all duration-300",
                      "hover:shadow-md hover:-translate-y-0.5",
                      "bg-gradient-to-r from-primary to-primary/90"
                    )}
                  >
                    <Link to="/dashboard" className="flex items-center gap-2">
                      Dashboard
                      <ExternalLink className="h-4 w-4" />
                    </Link>
                  </Button>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <Button 
                    asChild 
                    variant="ghost" 
                    size="sm" 
                    className="h-9 px-4 hover:bg-accent/50"
                  >
                    <Link to="/login">Login</Link>
                  </Button>
                  <Button 
                    asChild 
                    variant="default" 
                    size="sm" 
                    className={cn(
                      "h-9 px-4",
                      "transition-all duration-300",
                      "hover:shadow-md hover:-translate-y-0.5",
                      "bg-gradient-to-r from-primary to-primary/90"
                    )}
                  >
                    <Link to="/signup">Sign up</Link>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Bottom Navigation Bar for Mobile - App-Like Experience */}
      <div className={cn(
        "fixed bottom-0 left-0 right-0 z-50 lg:hidden",
        "bg-background/95 backdrop-blur-xl border-t border-border/40",
        "py-2 pb-safe" // Safe area for iOS devices
      )}>
        <div className="flex items-center justify-around px-2">
          <Link 
            to="/" 
            className={cn(
              "flex flex-col items-center gap-1 p-1.5 rounded-lg",
              location.pathname === '/' ? "text-primary" : "text-muted-foreground"
            )}
          >
            <Home size={20} />
            <span className="text-xs">Home</span>
          </Link>
          
          <Link 
            to="/markets" 
            className={cn(
              "flex flex-col items-center gap-1 p-1.5 rounded-lg",
              location.pathname.startsWith('/markets') ? "text-primary" : "text-muted-foreground"
            )}
          >
            <LineChart size={20} />
            <span className="text-xs">Markets</span>
          </Link>
          
          {isAuthenticated ? (
            <Link 
              to="/dashboard" 
              className={cn(
                "flex flex-col items-center gap-1 p-1.5 rounded-lg",
                location.pathname.startsWith('/dashboard') ? "text-primary" : "text-muted-foreground"
              )}
            >
              <BarChart size={20} />
              <span className="text-xs">Dashboard</span>
            </Link>
          ) : (
            <Link 
              to="/signup" 
              className="flex flex-col items-center gap-1 p-1.5 rounded-lg text-primary"
            >
              <Button 
                variant="default"
                size="icon"
                className="h-10 w-10 rounded-full bg-gradient-to-r from-primary to-primary/90 shadow-lg"
              >
                <Wallet size={20} />
              </Button>
              <span className="text-xs">Sign Up</span>
            </Link>
          )}
          
          <Link 
            to="/news" 
            className={cn(
              "flex flex-col items-center gap-1 p-1.5 rounded-lg",
              location.pathname.startsWith('/news') ? "text-primary" : "text-muted-foreground"
            )}
          >
            <Newspaper size={20} />
            <span className="text-xs">News</span>
          </Link>
          
          <Link 
            to="/help" 
            className={cn(
              "flex flex-col items-center gap-1 p-1.5 rounded-lg",
              location.pathname.startsWith('/help') ? "text-primary" : "text-muted-foreground"
            )}
          >
            <HelpCircle size={20} />
            <span className="text-xs">Help</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
