import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Send, Clock, ShieldCheck, CreditCard } from 'lucide-react';
import TransferForm from '@/components/transfer/TransferForm';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Asset } from '@/components/wallet/WalletModels';
import { Skeleton } from '@/components/ui/skeleton';
import { useWallet } from '@/contexts/WalletContext';

const TransferPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchUserBalance, userBalances, loading } = useWallet();
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    if (!location.state || !location.state.asset) {
      navigate('/dashboard/wallet', { 
        replace: true,
        state: { error: 'Please select an asset to transfer' } 
      });
      return;
    }

    // Fetch the latest balance when component mounts
    const loadBalance = async () => {
      await fetchUserBalance();
    };

    loadBalance();
  }, [location.state, navigate, fetchUserBalance]);

  // Update balance when userBalances changes
  useEffect(() => {
    if (location.state?.asset && userBalances) {
      const assetBalance = userBalances.find(
        balance => balance.Asset === location.state.asset.assetName
      );
      setBalance(assetBalance?.Balance || 0);
    }
  }, [userBalances, location.state]);

  if (!location.state?.asset || loading) {
    return (
      <DashboardLayout>
        <div className="flex flex-col items-center max-w-4xl mx-auto p-4 animate-fade-in">
          <div className="w-full space-y-6">
            <Skeleton className="h-12 w-3/4" />
            <Skeleton className="h-[400px] w-full rounded-xl" />
          </div>
        </div>
      </DashboardLayout>
    );
  }

  const asset: Asset = location.state.asset;

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center max-w-4xl mx-auto p-4 sm:p-0 animate-fade-in">
        <div className="flex flex-col sm:flex-row sm:items-center gap-3 self-start mb-6 w-full">
          <Button 
            variant="ghost" 
            size="icon"
            onClick={() => navigate('/dashboard/wallet')}
            className="rounded-full h-10 w-10"
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div className="ml-2 sm:ml-0">
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight flex items-center gap-2">
              Transfer {asset.assetName}
              {asset.logo && <span className="text-xl">{asset.logo}</span>}
            </h1>
            <p className="text-muted-foreground text-sm mt-1">
              Send funds instantly to another EvoCash user
            </p>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
          {/* Main transfer form - stacked on mobile, spans 2 columns on desktop */}
          <div className="lg:col-span-2 order-2 lg:order-1">
            <div className="rounded-xl bg-gradient-to-r from-primary/10 via-primary/5 to-background p-4 sm:p-8 backdrop-blur-sm border shadow-sm">
              <div className="flex items-center gap-2 mb-4 sm:mb-6">
                <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                  <Send className="h-4 w-4 text-primary" />
                </div>
                <h2 className="text-xl font-semibold">Transfer Details</h2>
              </div>
              <TransferForm />
            </div>
          </div>

          {/* Info cards - show first on mobile */}
          <div className="space-y-4 order-1 lg:order-2">
            {/* Available balance card - now using real-time balance */}
            <div className="rounded-xl bg-card p-4 sm:p-6 border shadow-sm">
              <h3 className="font-medium mb-2">Available Balance</h3>
              <div className="flex items-center justify-between">
                <span className="text-2xl font-semibold">{balance}</span>
                <span className="text-muted-foreground">{asset.symbol}</span>
              </div>
            </div>
            
            {/* Transfer info card */}
            <div className="rounded-xl bg-card p-4 sm:p-6 border shadow-sm">
              <h3 className="font-medium mb-3">Transfer Information</h3>
              <ul className="space-y-3 text-sm text-muted-foreground">
                <li className="flex items-center gap-3">
                  <Send className="h-4 w-4 min-w-4 text-primary" />
                  <span>Instant transfers between EvoCash users</span>
                </li>
                <li className="flex items-center gap-3">
                  <CreditCard className="h-4 w-4 min-w-4 text-primary" />
                  <span>No fees for internal transfers</span>
                </li>
                <li className="flex items-center gap-3">
                  <Clock className="h-4 w-4 min-w-4 text-primary" />
                  <span>Available 24/7</span>
                </li>
                <li className="flex items-center gap-3">
                  <ShieldCheck className="h-4 w-4 min-w-4 text-primary" />
                  <span>Secure and traceable</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TransferPage;
