import { useEffect, useState } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { useUser } from '@/contexts/UserContext';
import { 
  Clock, 
  MapPin, 
  Monitor, 
  Shield, 
  AlertTriangle, 
  Check, 
  ChevronLeft, 
  ChevronRight, 
  Filter,
  Globe,
  CalendarRange,
  RefreshCw
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { useToast } from '@/components/ui/use-toast';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import { getLocationFromIP } from '@/utils/ipLocation';

const LoginHistory = () => {
  const { getLoginHistory, loginHistory, flagSuspiciousLogin } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('all'); // all, suspicious, safe
  const [dateRange, setDateRange] = useState('all'); // all, today, week, month
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { toast } = useToast();
  const itemsPerPage = 8;

  useEffect(() => {
    document.title = 'EvoCash | Login History';
    fetchData();
  }, []);

  // Function to fetch login history and process it
  const fetchData = async () => {
    setIsLoading(true);
    try {
      await getLoginHistory();
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching login history:", error);
      toast({
        title: "Failed to load login history",
        description: "Please try again later",
        variant: "destructive",
      });
      setIsLoading(false);
    }
  };

  // Process IP addresses to get location data
  useEffect(() => {
    const fetchLocationData = async () => {
      const uniqueIPs = [...new Set(
        loginHistory
          .filter(entry => entry.ip && !locationData[entry.ip])
          .map(entry => entry.ip)
      )];

      if (uniqueIPs.length === 0) return;

      const newLocationData = { ...locationData };
      
      for (const ip of uniqueIPs) {
        try {
          // Get location data using our utility function
          newLocationData[ip] = getLocationFromIP(ip);
          setLocationData({ ...newLocationData });
        } catch (error) {
          console.error(`Error processing IP ${ip}:`, error);
          newLocationData[ip] = { error: true, isLoading: false };
          setLocationData({ ...newLocationData });
        }
      }
    };

    if (loginHistory.length > 0) {
      fetchLocationData();
    }
  }, [loginHistory]);

  // Determine if a login is suspicious based on patterns
  const analyzeSecurity = (entry) => {
    // Get location data
    const ipLocation = locationData[entry.ip];
    
    // Detect unusual locations compared to common login locations
    let unusualLocation = entry.location !== entry.commonLocation;
    
    // If we have location data, enhance the unusual location detection
    if (ipLocation && !ipLocation.isLoading && !ipLocation.error) {
      // If the user has a common country and this login is from a different country
      if (entry.commonCountry && entry.commonCountry !== ipLocation.country) {
        unusualLocation = true;
      }
    }
    
    // Detect unusual time patterns (logins outside typical hours)
    const hour = new Date(entry.timestamp).getHours();
    const unusualTime = hour < 6 || hour > 22;
    
    // Detect unusual device or browser
    const unusualDevice = !entry.isKnownDevice;
    
    // Calculate risk score
    const riskFactors = [unusualLocation, unusualTime, unusualDevice];
    const riskScore = riskFactors.filter(Boolean).length;
    
    // Additional factor: IP is known for malicious activity
    const isKnownMaliciousIP = entry.isKnownMaliciousIP || false;
    
    return {
      isSuspicious: riskScore >= 2 || isKnownMaliciousIP,
      unusualLocation,
      unusualTime,
      unusualDevice,
      isKnownMaliciousIP,
      riskScore
    };
  };

  // Extract browser and OS from user agent
  const parseUserAgent = (userAgent) => {
    let browser = "Unknown";
    let os = "Unknown";
    let device = "Desktop";
    
    // Enhanced browser detection
    if (userAgent.includes('Chrome') && !userAgent.includes('Edg/')) browser = 'Chrome';
    else if (userAgent.includes('Firefox')) browser = 'Firefox';
    else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) browser = 'Safari';
    else if (userAgent.includes('Edg/')) browser = 'Edge';
    else if (userAgent.includes('Opera') || userAgent.includes('OPR/')) browser = 'Opera';
    else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) browser = 'Internet Explorer';
    
    // Enhanced OS detection
    if (userAgent.includes('Windows NT 10.0')) os = 'Windows 10';
    else if (userAgent.includes('Windows NT 6.3')) os = 'Windows 8.1';
    else if (userAgent.includes('Windows NT 6.2')) os = 'Windows 8';
    else if (userAgent.includes('Windows NT 6.1')) os = 'Windows 7';
    else if (userAgent.includes('Windows')) os = 'Windows';
    else if (userAgent.includes('Mac OS X')) os = 'macOS';
    else if (userAgent.includes('Linux')) os = 'Linux';
    else if (userAgent.includes('Android')) {
      os = 'Android';
      device = 'Mobile';
    }
    else if (userAgent.includes('iOS') || userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      os = 'iOS';
      device = userAgent.includes('iPad') ? 'Tablet' : 'Mobile';
    }
    
    return { browser, os, device };
  };

  // Format location from IP data
  const formatLocation = (locationInfo: any, ip: string) => {
    if (locationInfo.country === 'Unknown') {
      return `IP: ${ip}`;
    }
    return `${locationInfo.country}`;
  };

  // Apply filters for date range
  const filterByDate = (history) => {
    const now = new Date();
    
    if (dateRange === 'today') {
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
      return history.filter(entry => new Date(entry.timestamp).getTime() >= today);
    } 
    else if (dateRange === 'week') {
      const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).getTime();
      return history.filter(entry => new Date(entry.timestamp).getTime() >= weekAgo);
    }
    else if (dateRange === 'month') {
      const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).getTime();
      return history.filter(entry => new Date(entry.timestamp).getTime() >= monthAgo);
    }
    
    return history;
  };

  // Enhanced login history with security analysis and location data
  const enhancedHistory = loginHistory.map(entry => ({
    ...entry,
    security: analyzeSecurity(entry),
    deviceInfo: parseUserAgent(entry.userAgent),
    locationInfo: locationData[entry.ip]
  }));

  // Apply filters and sorting
  let filteredHistory = filterByDate(enhancedHistory);
  
  if (filter === 'suspicious') {
    filteredHistory = filteredHistory.filter(entry => entry.security.isSuspicious);
  } else if (filter === 'safe') {
    filteredHistory = filteredHistory.filter(entry => !entry.security.isSuspicious);
  }

  // Sort by timestamp (most recent first)
  const sortedHistory = [...filteredHistory].sort((a, b) =>
     new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const paginatedHistory = sortedHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Calculate statistics
  const totalLogins = loginHistory.length;
  const suspiciousLogins = enhancedHistory.filter(entry => entry.security.isSuspicious).length;
  const uniqueDevices = new Set(enhancedHistory.map(entry => entry.deviceInfo.browser + entry.deviceInfo.os)).size;
  const uniqueLocations = new Set(enhancedHistory
    .filter(entry => entry.locationInfo && !entry.locationInfo.isLoading && !entry.locationInfo.error)
    .map(entry => `${entry.locationInfo.city}-${entry.locationInfo.country}`)).size;

  const handleFlagSuspicious = (entryId) => {
    flagSuspiciousLogin(entryId);
    toast({
      title: "Login flagged as suspicious",
      description: "Our security team has been notified and will investigate.",
      variant: "default",
    });
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    fetchData().finally(() => {
      setIsRefreshing(false);
      toast({
        title: "Login history refreshed",
        description: "Your login history has been updated with the latest data.",
      });
    });
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6">
        <section className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">Login History</h1>
            <p className="text-muted-foreground mt-1">Track your account access history and security insights</p>
          </div>
          
          <div className="flex flex-wrap items-center gap-2 mt-4 md:mt-0">
            <Button
              variant="outline"
              size="sm"
              onClick={handleRefresh}
              disabled={isRefreshing}
              className="flex items-center gap-1"
            >
              <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              Refresh
            </Button>
            
            <div className="border-l h-6 mx-1 hidden md:block"></div>
            
            <Button
              variant={dateRange === 'all' ? "default" : "outline"}
              size="sm"
              onClick={() => setDateRange('all')}
              className="flex items-center gap-1"
            >
              <CalendarRange className="h-4 w-4" />
              All time
            </Button>
            <Button
              variant={dateRange === 'today' ? "default" : "outline"}
              size="sm"
              onClick={() => setDateRange('today')}
            >
              Today
            </Button>
            <Button
              variant={dateRange === 'week' ? "default" : "outline"}
              size="sm"
              onClick={() => setDateRange('week')}
            >
              This week
            </Button>
            <Button
              variant={dateRange === 'month' ? "default" : "outline"}
              size="sm"
              onClick={() => setDateRange('month')}
            >
              This month
            </Button>
          </div>
        </section>

        {/* Stats overview */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-muted-foreground">Total Logins</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{totalLogins}</div>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-muted-foreground">Suspicious Activities</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{suspiciousLogins}</div>
              <p className="text-xs text-muted-foreground mt-1">
                {suspiciousLogins > 0 ? 'Review and secure your account' : 'No suspicious activity detected'}
              </p>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-muted-foreground">Unique Devices</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{uniqueDevices}</div>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-muted-foreground">Login Locations</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{uniqueLocations}</div>
            </CardContent>
          </Card>
        </div>
        
        {/* Security Alert */}
        {suspiciousLogins > 0 && (
          <Alert variant="destructive" className="bg-red-50 text-red-900 border-red-200">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Security Alert</AlertTitle>
            <AlertDescription>
              We've detected {suspiciousLogins} suspicious login attempts. Please review your recent activity and secure your account if needed.
            </AlertDescription>
          </Alert>
        )}

        {/* Filter options */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Filter className="h-4 w-4 text-muted-foreground" />
            <span className="text-sm font-medium mr-2">Filter:</span>
            <div className="flex gap-2">
              <Button
                variant={filter === 'all' ? "default" : "outline"}
                size="sm"
                onClick={() => setFilter('all')}
              >
                All
              </Button>
              <Button
                variant={filter === 'suspicious' ? "default" : "outline"}
                size="sm"
                onClick={() => setFilter('suspicious')}
                className="flex items-center gap-1"
              >
                <AlertTriangle className="h-3 w-3" />
                Suspicious
              </Button>
              <Button
                variant={filter === 'safe' ? "default" : "outline"}
                size="sm"
                onClick={() => setFilter('safe')}
                className="flex items-center gap-1"
              >
                <Check className="h-3 w-3" />
                Safe
              </Button>
            </div>
          </div>
        </div>

        {/* Login entries */}
        <div className="space-y-4">
          {isLoading ? (
            <div className="space-y-4">
              {[...Array(3)].map((_, i) => (
                <Card key={i} className="overflow-hidden">
                  <CardContent className="p-4">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-4">
                        <Skeleton className="h-12 w-12 rounded-full" />
                        <div className="space-y-2">
                          <Skeleton className="h-4 w-48" />
                          <Skeleton className="h-3 w-64" />
                        </div>
                      </div>
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-32" />
                        <Skeleton className="h-4 w-40" />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          ) : paginatedHistory.length === 0 ? (
            <div className="text-center py-8 border rounded-lg bg-card">
              <p className="text-muted-foreground">No login history found for the selected filters.</p>
            </div>
          ) : (
            paginatedHistory.map((entry, index) => (
              <Card key={index} className={`overflow-hidden border-l-4 ${
                entry.security.isSuspicious ? 'border-l-red-500' : 'border-l-green-500'
              }`}>
                <CardContent className="p-0">
                  <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between p-4">
                    <div className="flex items-center space-x-4">
                      <div className={`p-3 rounded-full ${
                        entry.security.isSuspicious ? 'bg-red-100 text-red-600' : 'bg-green-100 text-green-600'
                      }`}>
                        {entry.security.isSuspicious ? (
                          <AlertTriangle className="h-5 w-5" />
                        ) : (
                          <Check className="h-5 w-5" />
                        )}
                      </div>
                      
                      <div className="space-y-1">
                        <div className="flex items-center gap-2 flex-wrap">
                          <p className="font-medium">
                            {entry.deviceInfo.browser} on {entry.deviceInfo.os}
                          </p>
                          <Badge variant={entry.isKnownDevice ? "outline" : "secondary"}>
                            {entry.isKnownDevice ? "Known Device" : "New Device"}
                          </Badge>
                          {entry.deviceInfo.device !== "Desktop" && (
                            <Badge variant="outline" className="bg-blue-50 border-blue-200">
                              {entry.deviceInfo.device}
                            </Badge>
                          )}
                        </div>
                        <p className="text-sm text-muted-foreground truncate max-w-[300px] sm:max-w-[400px]">
                          {entry.userAgent}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-6">
                      <div className="flex items-center space-x-2">
                        <Globe className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          {entry.locationInfo ? (
                            formatLocation(entry.locationInfo, entry.ip)
                          ) : (
                            <span className="text-muted-foreground">Location unavailable</span>
                          )}
                        </span>
                      </div>
                      
                      <div className="flex items-center space-x-2">
                        <MapPin className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">{entry.ip}</span>
                      </div>
                      
                      <div className="flex items-center space-x-2">
                        <Clock className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          {new Date(entry.timestamp).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  {entry.security.isSuspicious && (
                    <div className="bg-red-50 px-4 py-2 border-t">
                      <div className="flex items-center justify-between flex-wrap gap-2">
                        <div className="flex items-center space-x-2 flex-wrap gap-y-2">
                          <Shield className="h-4 w-4 text-red-600" />
                          <span className="text-sm font-medium text-red-700">Security concerns:</span>
                          <div className="flex gap-2 flex-wrap">
                            {entry.security.unusualLocation && (
                              <Badge variant="outline" className="bg-red-100 text-red-700 border-red-200">
                                Unusual location
                              </Badge>
                            )}
                            {entry.security.unusualTime && (
                              <Badge variant="outline" className="bg-red-100 text-red-700 border-red-200">
                                Unusual time
                              </Badge>
                            )}
                            {entry.security.unusualDevice && (
                              <Badge variant="outline" className="bg-red-100 text-red-700 border-red-200">
                                Unknown device
                              </Badge>
                            )}
                            {entry.security.isKnownMaliciousIP && (
                              <Badge variant="outline" className="bg-red-100 text-red-700 border-red-200">
                                Suspicious IP
                              </Badge>
                            )}
                          </div>
                        </div>
                        
                        <Button
                          variant="outline"
                          size="sm"
                          className="text-red-600 border-red-200 hover:bg-red-100"
                          onClick={() => handleFlagSuspicious(entry.id)}
                        >
                          Report suspicious
                        </Button>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            ))
          )}
        </div>

        {/* Pagination */}
        {!isLoading && sortedHistory.length > 0 && (
          <div className="flex justify-between items-center">
            <Button
              variant="outline"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              className="flex items-center gap-1"
            >
              <ChevronLeft className="h-4 w-4" />
              Previous
            </Button>
            
            <span className="text-sm">
              Page {currentPage} of {Math.ceil(sortedHistory.length / itemsPerPage)}
            </span>
            
            <Button
              variant="outline"
              disabled={currentPage * itemsPerPage >= sortedHistory.length}
              onClick={() => setCurrentPage(currentPage + 1)}
              className="flex items-center gap-1"
            >
              Next
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default LoginHistory;
