import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-pull-to-refresh';
import { 
  ArrowDown, 
  ArrowUp, 
  ArrowLeftRight, 
  ShoppingCart, 
  DollarSign, 
  RefreshCw,
  TrendingUp,
  ChevronRight,
  MoreHorizontal,
  ChevronDown,
  Info
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from '@/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useIsMobile } from '@/hooks/use-mobile';
import { Asset } from '@/components/wallet/WalletModels';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

interface AssetTableProps {
  assets: Asset[];
  isLoading?: boolean;
  onRefresh?: () => void;
}

const AssetTable = ({ assets, isLoading = false, onRefresh }: AssetTableProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [expandedAssetId, setExpandedAssetId] = useState<string | null>(null);

  const toggleExpand = (id: string) => {
    setExpandedAssetId(expandedAssetId === id ? null : id);
  };

  const handleDeposit = (asset: Asset) => {
    navigate(`/dashboard/wallet/deposit`, { state: { asset } });
  };

  const handleWithdraw = (asset: Asset) => {
    navigate(`/dashboard/wallet/withdraw`, { state: { asset } });
  };

  const handleTransfer = (asset: Asset) => {
    navigate(`/dashboard/wallet/transfer`, { state: { asset } });
  };

  const handleBuy = (asset: Asset) => {
    navigate(`/dashboard/wallet/payment`, { state: { asset } });
  };

  const handleSell = (asset: Asset) => {
    navigate(`/dashboard/wallet/sell`, { state: { asset } });
  };

  const handleRefresh = () => {
    setLastUpdated(new Date());
    onRefresh?.();
  };

  const handleViewDetails = (asset: Asset) => {
    navigate(`/markets`, { state: { asset } });
  };

  // Create a function for the asset color based on type
  const getAssetColor = (type: string) => {
    const typeColors: Record<string, string> = {
      'crypto': 'bg-blue-100 text-blue-700',
      'fiat': 'bg-green-100 text-green-700',
      'stablecoin': 'bg-yellow-100 text-yellow-700',
      'tokenized_real_estate': 'bg-purple-100 text-purple-700',
      'tokenized_etf': 'bg-orange-100 text-orange-700',
    };
    return typeColors[type.toLowerCase()] || 'bg-gray-100 text-gray-700';
  };

  if (isLoading) {
    return <AssetTableSkeleton />;
  }

  if (assets.length === 0) {
    return (
      <div className="bg-muted/20 rounded-lg p-6 text-center">
        <div className="mb-4">
          <div className="h-12 w-12 mx-auto rounded-full bg-primary/10 flex items-center justify-center">
            <ShoppingCart className="h-6 w-6 text-primary" />
          </div>
        </div>
        <h3 className="text-lg font-medium mb-2">No assets found</h3>
        <p className="text-muted-foreground mb-6">
          You don't have any assets matching your search criteria.
        </p>
        <Button onClick={() => navigate('/dashboard/wallet/payment')}>
          Buy Your First Asset
        </Button>
      </div>
    );
  }

  // Modified mobile card view
  if (isMobile) {
    return (
      <PullToRefresh
        onRefresh={() => new Promise<void>((resolve) => {
          handleRefresh();
          resolve();
        })}
        //options={{ pullDownThreshold: 80 }}
        className="pb-4"
      >
        <div className="space-y-3">
          {assets.map((asset) => (
            <div 
              key={asset.id}
              className="rounded-xl border bg-card overflow-hidden shadow-sm"
            >
              <div 
                className="p-4 flex items-center justify-between active:bg-muted/30 touch-manipulation"
                onClick={() => toggleExpand(asset.id)}
              >
                <div className="flex items-center gap-3">
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center text-lg">
                    {asset.logo || <span className="font-semibold text-primary">{asset.symbol[0]}</span>}
                  </div>
                  <div>
                    <div className="font-medium">{asset.assetName}</div>
                    <div className="text-xs text-muted-foreground flex items-center gap-1">
                      {asset.symbol}
                      <Badge 
                        variant="outline" 
                        className={`h-4 text-[10px] ml-1 px-1 py-0 font-normal ${getAssetColor(asset.type)}`}
                      >
                        {asset.type}
                      </Badge>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <div className="text-base font-medium">
                    ${(asset.availableBalance && asset.currentPrice) ? 
                      ((asset.availableBalance || 0) * (asset.currentPrice || 1.05)).toFixed(2) : 
                      "0.00"}
                  </div>
                  <div className="flex items-center text-xs text-success">
                    <TrendingUp className="h-3 w-3 mr-0.5" />
                    5%
                  </div>
                </div>
              </div>
              
              {/* Details section - expanded when clicked */}
              <div className={cn(
                "overflow-hidden transition-all duration-200",
                expandedAssetId === asset.id ? "max-h-[250px]" : "max-h-0"
              )}>
                <div className="px-4 pb-4 pt-0 border-t border-dashed">
                  {/* Content remains the same */}
                  
                  <div className="flex justify-between items-center py-2">
                    <span className="text-xs text-muted-foreground">Available</span>
                    <span className="text-sm">
                      {asset.format ? asset.format(asset.availableBalance || 0) : '0.00'} {asset.symbol}
                    </span>
                  </div>
                  
                  {(asset.pendingBalance || 0) > 0 && (
                    <div className="flex justify-between items-center py-2">
                      <span className="text-xs text-muted-foreground">Pending</span>
                      <span className="text-sm text-amber-600">
                        {asset.format ? asset.format(asset.pendingBalance || 0) : '0.00'} {asset.symbol}
                      </span>
                    </div>
                  )}
                  
                  <div className="mt-4 grid grid-cols-3 gap-2">
                    <Button 
                      size="sm" 
                      variant="default"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBuy(asset);
                      }}
                      className="h-10 px-2 bg-green-600 hover:bg-green-700 active:translate-y-0.5 transition-transform"
                    >
                      <ShoppingCart className="h-3.5 w-3.5 mb-0.5" />
                      <span className="ml-1">Buy</span>
                    </Button>
                    <Button 
                      size="sm" 
                      variant="outline"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSell(asset);
                      }}
                      className="h-10 px-2 border-red-200 text-red-600 hover:bg-red-50 active:translate-y-0.5 transition-transform"
                    >
                      <DollarSign className="h-3.5 w-3.5 mb-0.5" />
                      <span className="ml-1">Sell</span>
                    </Button>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button 
                          size="sm" 
                          variant="secondary"
                          className="h-10 px-2 active:translate-y-0.5 transition-transform"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <MoreHorizontal className="h-4 w-4 mr-1" />
                          <span className="text-xs">More</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end" className="w-48">
                        <DropdownMenuItem onClick={() => handleDeposit(asset)}>
                          <ArrowDown className="h-4 w-4 mr-2" />
                          Deposit
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleWithdraw(asset)}>
                          <ArrowUp className="h-4 w-4 mr-2" />
                          Withdraw
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleTransfer(asset)}>
                          <ArrowLeftRight className="h-4 w-4 mr-2" />
                          Transfer
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => handleViewDetails(asset)}>
                          <Info className="h-4 w-4 mr-2" />
                          Details
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              
              <div 
                className="flex items-center justify-center p-2.5 border-t cursor-pointer active:bg-muted/30 touch-manipulation"
                onClick={() => toggleExpand(asset.id)}
              >
                <ChevronDown className={cn(
                  "h-4 w-4 text-muted-foreground transition-transform duration-200", 
                  expandedAssetId === asset.id ? "rotate-180" : ""
                )} />
              </div>
            </div>
          ))}
          
          <div className="mt-6 flex items-center justify-between px-1">
            <span className="text-xs text-muted-foreground">
              {assets.length} {assets.length === 1 ? 'asset' : 'assets'}
            </span>
            
          </div>
        </div>
      </PullToRefresh>
    );
  }

  // Desktop table view
  return (
    <div className="rounded-xl border overflow-hidden bg-card">
      <Table>
        <TableHeader>
          <TableRow className="bg-muted/20 hover:bg-muted/20">
            <TableHead className="font-medium">Asset</TableHead>
            <TableHead className="hidden sm:table-cell font-medium">Type</TableHead>
            <TableHead className="text-right font-medium">Balance</TableHead>
            <TableHead className="w-[240px] font-medium">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {assets.map((asset) => (
            <TableRow 
              key={asset.id} 
              className="hover:bg-muted/30 transition-colors cursor-pointer"
              onClick={() => handleViewDetails(asset)}
            >
              <TableCell className="py-4" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center gap-3">
                  <div className="flex-shrink-0 w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center text-lg">
                    {asset.logo || <span className="font-semibold text-primary">{asset.symbol[0]}</span>}
                  </div>
                  <div>
                    <div className="font-medium">{asset.assetName}</div>
                    <div className="text-sm text-muted-foreground flex items-center gap-1">
                      <span>{asset.symbol}</span>
                      <Badge 
                        variant="secondary" 
                        className="h-5 text-xs hidden md:inline-flex ml-1 font-normal"
                      >
                        {asset.precision} decimals
                      </Badge>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="hidden sm:table-cell py-4" onClick={(e) => e.stopPropagation()}>
                <Badge variant="outline" className={`font-normal ${getAssetColor(asset.type)}`}>
                  {asset.type}
                </Badge>
              </TableCell>
              <TableCell className="text-right font-medium py-4" onClick={(e) => e.stopPropagation()}>
                <div className="flex flex-col items-end">
                  <span className="text-base">
                    ${(asset.availableBalance && asset.currentPrice) ? 
                       ((asset.availableBalance || 0) * (asset.currentPrice || 1.05)).toFixed(2) : 
                       "0.00"}
                  </span>
                  <span className="text-sm text-muted-foreground">
                    {asset.format ? asset.format(asset.availableBalance || 0) : '0.00'} {asset.symbol}
                  </span>
                  {(asset.pendingBalance || 0) > 0 && (
                    <span className="text-xs text-amber-600 mt-1">
                      {asset.format ? asset.format(asset.pendingBalance || 0) : '0.00'} pending
                    </span>
                  )}
                </div>
              </TableCell>
              <TableCell className="py-4" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center gap-2">
                  <Button 
                    size="sm" 
                    variant="default"
                    onClick={() => handleBuy(asset)}
                    className="h-9 px-3 bg-green-600 hover:bg-green-700"
                  >
                    <ShoppingCart className="h-3.5 w-3.5 mr-1" />
                    Buy
                  </Button>
                  <Button 
                    size="sm" 
                    variant="outline"
                    onClick={() => handleSell(asset)}
                    className="h-9 px-3 border-red-200 text-red-600 hover:bg-red-50"
                  >
                    <DollarSign className="h-3.5 w-3.5 mr-1" />
                    Sell
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button 
                        size="sm" 
                        variant="ghost"
                        className="h-9 px-2"
                      >
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-48">
                      <DropdownMenuItem onClick={() => handleDeposit(asset)}>
                        <ArrowDown className="h-4 w-4 mr-2" />
                        Deposit
                      </DropdownMenuItem>
                      <DropdownMenuItem disabled={true} onClick={() => handleWithdraw(asset)}>
                        <ArrowUp className="h-4 w-4 mr-2" />
                        Withdraw
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => handleTransfer(asset)}>
                        <ArrowLeftRight className="h-4 w-4 mr-2" />
                        Transfer
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => handleViewDetails(asset)}>
                        <ChevronRight className="h-4 w-4 mr-2" />
                        View Details
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={4} className="p-4">
              <div className="flex items-center justify-between">
                <span className="text-sm text-muted-foreground">
                  Showing {assets.length} {assets.length === 1 ? 'asset' : 'assets'}
                </span>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-8 text-xs gap-1"
                  onClick={handleRefresh}
                >
                  <RefreshCw className="h-3.5 w-3.5" />
                  Updated {lastUpdated.toLocaleTimeString()}
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

const AssetTableSkeleton = () => {
  const isMobile = useIsMobile();
  
  if (isMobile) {
    return (
      <div className="space-y-3">
        {[1, 2, 3].map((i) => (
          <div key={i} className="rounded-xl border bg-card p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Skeleton className="h-10 w-10 rounded-full" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-24" />
                  <Skeleton className="h-3 w-16" />
                </div>
              </div>
              <div className="flex flex-col items-end gap-1.5">
                <Skeleton className="h-5 w-16" />
                <Skeleton className="h-3 w-12" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="rounded-xl border overflow-hidden bg-card">
      <Table>
        <TableHeader>
          <TableRow className="bg-muted/20 hover:bg-muted/20">
            <TableHead className="font-medium">Asset</TableHead>
            <TableHead className="hidden sm:table-cell font-medium">Type</TableHead>
            <TableHead className="text-right font-medium">Balance</TableHead>
            <TableHead className="w-[240px] font-medium">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[1, 2, 3].map((i) => (
            <TableRow key={i}>
              <TableCell className="py-4">
                <div className="flex items-center gap-3">
                  <Skeleton className="h-12 w-12 rounded-full" />
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-28" />
                    <Skeleton className="h-3 w-16" />
                  </div>
                </div>
              </TableCell>
              <TableCell className="hidden sm:table-cell py-4">
                <Skeleton className="h-6 w-16" />
              </TableCell>
              <TableCell className="text-right py-4">
                <div className="flex flex-col items-end gap-1.5">
                  <Skeleton className="h-5 w-24" />
                  <Skeleton className="h-4 w-16" />
                </div>
              </TableCell>
              <TableCell className="py-4">
                <div className="flex items-center gap-2">
                  <Skeleton className="h-9 w-20" />
                  <Skeleton className="h-9 w-20" />
                  <Skeleton className="h-9 w-9" />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AssetTable;
