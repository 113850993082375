import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '@/components/layout/Dashboard';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { 
  ArrowRight, 
  Shield, 
  Coins, 
  Scale, 
  Globe, 
  Gem, 
  LineChart, 
  TrendingUp,
  LockKeyhole,
  History,
  BarChart4,
  Award,
  CreditCard
} from 'lucide-react';

const Index = () => {
  useEffect(() => {
    document.title = 'EvoCash | Home';
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FinancialProduct",
    "name": "EvoCash Digital Asset Platform",
    "description": "EvoCash specializes in tokenizing real-world assets on blockchain, making them accessible, tradeable, and secure.",
    "provider": {
      "@type": "Organization",
      "name": "EvoCash",
      "url": "https://evocash.org"
    },
    "offers": {
      "@type": "Offer",
      "itemOffered": {
        "@type": "CryptocurrencyService",
        "name": "AGC Token",
        "description": "A stable coin backed by physical gold"
      }
    }
  };

  return (
    <Dashboard>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <div className="flex flex-col space-y-16">
        {/* Hero Section with Enhanced Gradient Animation */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-10 md:p-16">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          
          {/* Floating elements for visual interest */}
          <div className="absolute top-20 right-10 w-24 h-24 bg-primary/10 rounded-full blur-xl animate-pulse" />
          <div className="absolute bottom-10 left-10 w-32 h-32 bg-primary/5 rounded-full blur-xl animate-pulse [animation-delay:2s]" />
          
          <div className="relative z-10 max-w-3xl">
            <h1 className="animate-fade-up text-4xl md:text-6xl font-bold tracking-tight mb-6">
              The Future of <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70">Asset Ownership</span>
            </h1>
            <p className="text-xl text-muted-foreground mb-8 animate-fade-up [--animation-delay:200ms] leading-relaxed">
            EvoCash specializes in tokenizing real-world assets on blockchain, making them accessible, tradeable, and secure. 
            Experience our first product - AGC a stable coin, backed by physical gold.
            </p>
            <div className="flex flex-wrap gap-4 animate-fade-up [--animation-delay:400ms]">
              <Button size="lg" className="group" asChild>
                <Link to="/Login">
                  Trade Digital Gold (AGC)
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </Button>
              <Button size="lg" variant="outline" className="group" asChild>
                <Link to="/about">
                  Our Vision 
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </Button>
            </div>
          </div>
        </section>

        {/* Key Metrics Bar */}
        <section className="grid grid-cols-2 md:grid-cols-4 gap-4 px-2">
          <MetricCard icon={<Scale className="h-10 w-10 text-primary/80" />} value="15+" label="KG Gold Reserves" />
          <MetricCard icon={<Coins className="h-10 w-10 text-primary/80" />} value="1.6M+" label="AGCs" />
          <MetricCard icon={<TrendingUp className="h-10 w-10 text-primary/80" />} value="24/7" label="Trading Access" />
          <MetricCard icon={<Shield className="h-10 w-10 text-primary/80" />} value="100%" label="Asset Backed" />
        </section>

        {/* Featured Product - Enhanced */}
        <section className="rounded-3xl overflow-hidden">
          <div className="bg-gradient-to-r from-primary/5 to-background p-10 border border-border">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-10 gap-6">
              <div>
                <h2 className="text-3xl font-bold mb-2">AGC</h2>
                <p className="text-muted-foreground max-w-xl">Digital gold ownership redefined: secure, divisible, and instantly transferable</p>
              </div>
              <Button size="lg" className="group whitespace-nowrap" variant="outline" asChild>
                <Link to="/markets">
                  View Markets
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </Button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <Card className="bg-background/60 border-primary/20 hover:border-primary/40 transition-colors">
                <CardContent className="pt-6">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <Scale className="h-5 w-5 text-primary" />
                    </div>
                    <h3 className="font-semibold text-lg">Gold-Backed Value</h3>
                  </div>
                  <p className="text-muted-foreground">Each AGC is backed by a certified 24k physical gold stored in secure vaults 1 to 1</p>
                </CardContent>
              </Card>
              
              <Card className="bg-background/60 border-primary/20 hover:border-primary/40 transition-colors">
                <CardContent className="pt-6">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <LockKeyhole className="h-5 w-5 text-primary" />
                    </div>
                    <h3 className="font-semibold text-lg">Secure Storage</h3>
                  </div>
                  <p className="text-muted-foreground">Physical gold is stored in fully regulated, insured vaults with regular third-party audits</p>
                </CardContent>
              </Card>
              
              <Card className="bg-background/60 border-primary/20 hover:border-primary/40 transition-colors">
                <CardContent className="pt-6">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <LineChart className="h-5 w-5 text-primary" />
                    </div>
                    <h3 className="font-semibold text-lg">Instant Transfers</h3>
                  </div>
                  <p className="text-muted-foreground">Transfer your digital gold holdings instantly to anyone, anywhere in the world with minimal fees</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* How It Works Section - New */}
        <section className="px-2">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-3">How EvoCash Works</h2>
            <p className="text-muted-foreground max-w-2xl mx-auto">Bridging traditional assets with digital innovation through our secure on chain platform</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 relative">
            {/* Connecting line between steps */}
            <div className="hidden md:block absolute top-16 left-[16.66%] right-[16.66%] h-0.5 bg-gradient-to-r from-transparent via-primary/30 to-transparent" />
            
            <ProcessStep 
              number="01" 
              title="Acquire Assets" 
              description="EvoCash purchases and secures physical assets" 
            />
            <ProcessStep 
              number="02" 
              title="Tokenize" 
              description="Assets are digitized as tokens on the blockchain and made tradeable on our platform" 
            />
            <ProcessStep 
              number="03" 
              title="Trade & Transfer" 
              description="Buy, sell, transfer and turn your tokenized assets 24/7, or load value onto our virtual card for everyday spending" 
            />
          </div>
        </section>

        {/* Core Features - Enhanced */}
        <section>
          <div className="text-center mb-10">
            <h2 className="text-3xl font-bold mb-3">Platform Features</h2>
            <p className="text-muted-foreground max-w-2xl mx-auto">Comprehensive tools for managing your tokenized asset portfolio</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <FeatureCard
              icon={<Gem className="size-8 text-primary" />}
              title="Asset Tokenization"
              description="Convert physical assets into tradeable digital tokens with instant liquidity"
            />
            <FeatureCard
              icon={<Shield className="size-8 text-primary" />}
              title="Bank-Grade Security"
              description="Multi-layer protection for your digital assets with advanced encryption"
            />
            <FeatureCard
              icon={<BarChart4 className="size-8 text-primary" />}
              title="Market Analytics"
              description="Real-time market data and insights to inform your investment decisions"
            />
            <FeatureCard
              icon={<Globe className="size-8 text-primary" />}
              title="Global Accessibility"
              description="Access your tokenized assets from anywhere in the world, anytime"
            />
            <FeatureCard
              icon={<Award className="size-8 text-primary" />}
              title="Regulatory Compliance"
              description="Operating within established legal frameworks to ensure legitimacy"
            />
            <FeatureCard
              icon={<CreditCard className="size-8 text-primary" />}
              title="Virtual Card"
              description="Load your tokenized assets onto a virtual card for everyday spending anywhere cards are accepted"
            />
          </div>
        </section>

        {/* CTA Section - New */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/30 via-primary/20 to-background p-10 md:p-12">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_80%)]" />
          
          <div className="relative z-10 flex flex-col md:flex-row justify-between items-center gap-8">
            <div className="max-w-2xl">
              <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
              <p className="text-lg text-muted-foreground mb-6">
                Join thousands of investors who are already benefiting from tokenized asset ownership on the EvoCash platform.
              </p>
            </div>
            <div className="flex flex-wrap gap-4">
              <Button size="lg" className="min-w-36" asChild>
                <Link to="/signup">Get Started</Link>
              </Button>
              <Button size="lg" variant="outline" className="min-w-36" asChild>
                <Link to="/faq">FAQ</Link>
              </Button>
            </div>
          </div>
        </section>
      </div>
    </Dashboard>
  );
};

const FeatureCard = ({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) => (
  <Card className="group hover:shadow-md transition-all duration-300 hover:translate-y-[-4px] border-muted/80 hover:border-primary/30">
    <CardContent className="pt-6">
      <div className="flex flex-col gap-3 mb-4">
        <div className="p-2 w-fit rounded-lg bg-primary/10 mb-2">
          {icon}
        </div>
        <h3 className="font-semibold text-lg">{title}</h3>
      </div>
      <p className="text-muted-foreground">{description}</p>
    </CardContent>
  </Card>
);

const MetricCard = ({ icon, value, label }: { icon: React.ReactNode; value: string; label: string }) => (
  <Card className="bg-background/50 border-primary/10">
    <CardContent className="flex items-center gap-4 p-6">
      {icon}
      <div>
        <div className="text-2xl font-bold">{value}</div>
        <div className="text-muted-foreground text-sm">{label}</div>
      </div>
    </CardContent>
  </Card>
);

const ProcessStep = ({ number, title, description }: { number: string; title: string; description: string }) => (
  <div className="flex flex-col items-center text-center">
    <div className="w-12 h-12 rounded-full bg-primary/10 border border-primary/20 flex items-center justify-center mb-4">
      <span className="text-lg font-bold text-primary">{number}</span>
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-muted-foreground max-w-xs">{description}</p>
  </div>
);

export default Index;
