
import axios, { AxiosInstance } from 'axios';

// Determine the base URL based on environment
const getBaseUrl = () => {
  if (import.meta.env.PROD) {
    // Production environment - direct to server
    return 'https://web2.evocash.org/api/v1'; // Replace with your actual production API URL
  } else {
    // Development environment - use proxy
    return 'https://web2.evocash.org/api/v1'; // Replace with your actual production API URL
    //return '/api/v1'; // This will use the Vite dev server proxy
  }
};

// Create an axios instance with environment-specific configuration
const apiClient: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  // Add withCredentials to handle cookies properly for CORS
  withCredentials: true
});

// Add request interceptor to include auth token for authenticated requests
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Handle multipart/form-data properly
    if (config.data instanceof FormData) {
      // Remove Content-Type for FormData to ensure boundary is set correctly
      delete config.headers['Content-Type'];
    }
    
    // Log outgoing requests
    console.log(`API Request to: ${config.url}`);
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor for better error handling
apiClient.interceptors.response.use(
  (response) => {
    // Log successful responses
    console.log(`API Response for ${response.config.url}:`, response.data);
    return response;
  },
  (error) => {
    // Enhanced error handling for CORS and other API errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // outside of the range of 2xx
      console.error('API Error:', error.response.status, error.response.data);
    } else if (error.request) {
      // The request was made but no response was received - likely CORS/network
      console.error('Network Error:', error.message);
      console.error('Request details:', {
        url: error.config?.url,
        method: error.config?.method
      });
    } else {
      // Something happened in setting up the request
      console.error('Request Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default apiClient;
