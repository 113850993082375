import { z } from "zod"; // Recommended for runtime type validation

// Asset models
export const ApiAssetSchema = z.object({
  _id: z.string().optional(),
  assetName: z.string().optional(),
  symbol: z.string().optional(),
  type: z.string().optional(),
  balance: z.number().optional(),
  pendingBalance: z.number().optional(),
  currentPrice: z.number().optional(),
});

export type ApiAsset = z.infer<typeof ApiAssetSchema>;

export enum AssetType {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
  STABLECOIN = 'stablecoin',
  TOKENIZED_REAL_ESTATE = 'tokenized_real_estate',
  TOKENIZED_ETF = 'tokenized_etf'
}

export class Asset {
  id: string;
  assetName: string;
  symbol: string;
  type: AssetType;
  availableBalance: number;
  pendingBalance: number;
  logo: string;
  precision: number;
  currentPrice: number;

  constructor(init: ApiAsset) {
    this.id = init._id || '';
    this.assetName = init.assetName || 'Unknown';
    this.symbol = init.symbol || 'N/A';
    this.type = this.mapAssetType(init.type);
    this.availableBalance = init.balance ?? 0;
    this.pendingBalance = init.pendingBalance ?? 0;
    this.logo = this.getAssetLogo(init.symbol);
    this.precision = this.getAssetPrecision(init.symbol);
    this.currentPrice = 0;
  }

  private mapAssetType(type?: string): AssetType {
    if (!type) return AssetType.CRYPTO;
    
    // Special case for AGC - always treat as stablecoin
    if (this.symbol === 'AGC') return AssetType.STABLECOIN;
    
    const typeMap: Record<string, AssetType> = {
      'crypto': AssetType.CRYPTO,
      'fiat': AssetType.FIAT,
      'stablecoin': AssetType.STABLECOIN,
      'tokenized_real_estate': AssetType.TOKENIZED_REAL_ESTATE,
      'tokenized_etf': AssetType.TOKENIZED_ETF,
    };
    
    return typeMap[type.toLowerCase()] || AssetType.CRYPTO;
  }

  private getAssetLogo(symbol?: string): string {
    if (!symbol) return '🔷';
    
    const logoMap: Record<string, string> = {
      'AGC': '💠', // Gold-backed stablecoin
      'BTC': '₿',
      'ETH': '◊',
      'USDT': '💵',
      'USDC': '💵',
    };
    
    return logoMap[symbol] || '🔷';
  }

  private getAssetPrecision(symbol?: string): number {
    if (!symbol) return 2;
    
    const precisionMap: Record<string, number> = {
      'AGC': 2,
      'BTC': 8,
      'ETH': 6,
      'USDT': 2,
      'USDC': 2,
    };
    
    return precisionMap[symbol] || 2;
  }

  get totalBalance(): number {
    return this.availableBalance + this.pendingBalance;
  }

  format(amount?: number): string {
    const value = amount ?? this.availableBalance;
    return value.toFixed(this.precision);
  }

  formatWithSymbol(amount?: number): string {
    return `${this.format(amount)} ${this.symbol}`;
  }

  static fromApiResponse(apiResponse: ApiAsset[]): Asset[] {
    return apiResponse.map(asset => new Asset(asset));
  }
}

// User Balance models
export const ApiUserBalanceSchema = z.object({
  Asset: z.string(),
  Balance: z.number(),
});

export type ApiUserBalance = z.infer<typeof ApiUserBalanceSchema>;

export class UserBalance implements ApiUserBalance {
  Asset: string;
  Balance: number;

  constructor(Asset: string = 'Unknown', Balance: number = 0) {
    this.Asset = Asset;
    this.Balance = Balance;
  }

  format(precision: number = 2): string {
    return this.Balance.toFixed(precision);
  }

  formatWithSymbol(precision: number = 2): string {
    return `${this.format(precision)} ${this.Asset}`;
  }
}

// Deposit Crypto models
export const ApiDepositCryptoSchema = z.object({
  depositAddress: z.string(),
  network: z.string().optional(),
  link: z.string().optional(),
});

export type ApiDepositCrypto = z.infer<typeof ApiDepositCryptoSchema>;

export enum NetworkType {
  BSC = "Binance Smart Chain (BSC)",
  ETH = "Ethereum",
  POLYGON = "Polygon",
  SOLANA = "Solana",
}

export class DepositCrypto implements ApiDepositCrypto {
  depositAddress: string;
  network: string;
  link: string;
  qrCodeData: string;
  validatedAt: Date | null;

  constructor(data: Partial<ApiDepositCrypto> = {}) {
    this.depositAddress = data.depositAddress || '0x0000000000000000000000000000000000000000';
    this.network = data.network || NetworkType.BSC;
    this.link = data.link || this.generateExplorerLink(this.depositAddress, this.network);
    this.qrCodeData = this.generateQRCodeData();
    this.validatedAt = data.depositAddress ? new Date() : null;
  }

  private generateExplorerLink(address: string, network: string): string {
    const explorerMap: Record<string, string> = {
      [NetworkType.BSC]: `https://testnet.bscscan.com/address/${address}`,
      [NetworkType.ETH]: `https://etherscan.io/address/${address}`,
      [NetworkType.POLYGON]: `https://polygonscan.com/address/${address}`,
      [NetworkType.SOLANA]: `https://explorer.solana.com/address/${address}`,
    };
    
    return explorerMap[network] || `https://testnet.bscscan.com/address/${address}`;
  }

  private generateQRCodeData(): string {
    return `ethereum:${this.depositAddress}?network=${encodeURIComponent(this.network)}`;
  }

  getShortAddress(): string {
    if (this.depositAddress.length <= 12) return this.depositAddress;
    return `${this.depositAddress.slice(0, 6)}...${this.depositAddress.slice(-4)}`;
  }

  isValid(): boolean {
    // Basic validation check for Ethereum-like addresses
    const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    return ethAddressRegex.test(this.depositAddress);
  }
}
