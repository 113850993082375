import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { User, Mail } from 'lucide-react';
import { SignUpFormData } from './SignUpForm';

interface PersonalInfoFieldsProps {
  formData: SignUpFormData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PersonalInfoFields = ({ formData, handleChange }: PersonalInfoFieldsProps) => {
  const inputVariants = {
    focus: { 
      scale: 1.01,
      boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.3)",
      transition: { 
        type: "spring", 
        stiffness: 700, 
        damping: 30 
      }
    },
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="grid grid-cols-2 gap-3">
          <div className="space-y-1">
            <label htmlFor="firstName" className="text-sm font-medium text-gray-300">
              First Name
            </label>
            <motion.div
              className="relative"
              whileFocus="focus"
            >
              <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                <User className="h-4 w-4" />
              </span>
              <motion.input
                id="firstName"
                name="firstName"
                type="text"
                required
                value={formData.firstName}
                onChange={handleChange}
                className={cn(
                  "w-full px-10 py-3 rounded-xl",
                  "bg-black/40 backdrop-blur-sm",
                  "border border-gray-700/50",
                  "text-white placeholder:text-gray-500",
                  "focus:outline-none focus:ring-0",
                  "transition-all duration-200"
                )}
                placeholder="John"
                variants={inputVariants}
              />
            </motion.div>
          </div>
          
          <div className="space-y-1">
            <label htmlFor="lastName" className="text-sm font-medium text-gray-300">
              Last Name
            </label>
            <motion.div
              className="relative"
              whileFocus="focus"
            >
              <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                <User className="h-4 w-4" />
              </span>
              <motion.input
                id="lastName"
                name="lastName"
                type="text"
                required
                value={formData.lastName}
                onChange={handleChange}
                className={cn(
                  "w-full px-10 py-3 rounded-xl",
                  "bg-black/40 backdrop-blur-sm",
                  "border border-gray-700/50",
                  "text-white placeholder:text-gray-500",
                  "focus:outline-none focus:ring-0",
                  "transition-all duration-200"
                )}
                placeholder="Doe"
                variants={inputVariants}
              />
            </motion.div>
          </div>
        </div>
      </div>
      
      <div className="space-y-1">
        <label htmlFor="email" className="text-sm font-medium text-gray-300">
          Email Address
        </label>
        <motion.div
          className="relative"
          whileFocus="focus"
        >
          <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
            <Mail className="h-4 w-4" />
          </span>
          <motion.input
            id="email"
            name="email"
            type="email"
            required
            value={formData.email}
            onChange={handleChange}
            className={cn(
              "w-full px-10 py-3 rounded-xl",
              "bg-black/40 backdrop-blur-sm",
              "border border-gray-700/50",
              "text-white placeholder:text-gray-500",
              "focus:outline-none focus:ring-0",
              "transition-all duration-200"
            )}
            placeholder="john.doe@example.com"
            variants={inputVariants}
          />
        </motion.div>
      </div>
    </div>
  );
};