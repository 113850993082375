import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import authService from '@/services/authService';
import { PersonalInfoFields } from './PersonalInfoFields';
import { PasswordFields } from './PasswordFields';
import VerifyOTPModal from '@/components/profile/VerifyOTPModal';
import { AxiosError } from 'axios';
import { cn } from '@/lib/utils';

export interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const SignUpForm = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCountryChange = (selectedCountry: { name: string; dial_code: string }) => {
    setFormData(prev => ({
      ...prev,
      country: selectedCountry.name,
      dialCode: selectedCountry.dial_code
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Basic validation
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    
    setIsLoading(true);
    
    try {
      await authService.register({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password
      });
      
      toast.success('Registration successful! Please verify your email.');
      setShowOTPModal(true);
    } catch (error) {
      console.error('Registration error:', error);
      const axiosError = error as AxiosError<{ message: string }>;
      toast.error(axiosError.response?.data?.message || 'Failed to register');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle successful verification
  const handleVerificationSuccess = () => {
    setShowOTPModal(false);
    navigate('/login');
    toast.success('Email verified successfully! You can now log in.');
  };

  // Animation variants
  const formSectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: { 
        delay: 0.2 * custom, 
        duration: 0.5,
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    })
  };

  const buttonVariants = {
    idle: { scale: 1 },
    hover: { 
      scale: 1.03,
      transition: { 
        type: "spring", 
        stiffness: 400, 
        damping: 10 
      }
    },
    tap: { scale: 0.97 }
  };

  return (
    <>
      <motion.form 
        onSubmit={handleSubmit}
        className="space-y-6"
        initial="hidden"
        animate="visible"
      >
        {/* Personal Info Section */}
        <motion.div 
          variants={formSectionVariants}
          custom={1}
          className="space-y-4"
        >
          <PersonalInfoFields 
            formData={formData} 
            handleChange={handleChange} 
          />
        </motion.div>
        
        {/* Password Section */}
        <motion.div 
          variants={formSectionVariants}
          custom={2}
          className="space-y-4"
        >
          <PasswordFields 
            formData={formData} 
            handleChange={handleChange} 
          />
        </motion.div>
        
        {/* Submit Button */}
        <motion.div
          variants={formSectionVariants}
          custom={3}
        >
          <motion.div
            variants={buttonVariants}
            initial="idle"
            whileHover="hover"
            whileTap="tap"
          >
            <Button 
              type="submit" 
              className={cn(
                "w-full py-6 rounded-xl font-medium",
                "bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400",
                "hover:shadow-lg hover:shadow-blue-500/20",
                "transition-all duration-300"
              )}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
              {isLoading ? 'Creating account...' : 'Create Account'}
            </Button>
          </motion.div>
        </motion.div>
      </motion.form>

      {/* OTP Verification Modal */}
      <VerifyOTPModal 
        isOpen={showOTPModal}
        onClose={() => setShowOTPModal(false)}
        email={formData.email}
        onVerificationSuccess={handleVerificationSuccess}
      />
    </>
  );
};