import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useWallet } from '@/contexts/WalletContext';
import { Asset } from '@/components/wallet/WalletModels';
import { toast } from 'sonner';

const TransferForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transferCrypto } = useWallet();
  const asset: Asset = location.state.asset;
  const [amount, setAmount] = useState<string>('');
  const [recipientIdentifier, setRecipientIdentifier] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [recipientError, setRecipientError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleTransfer = async () => {
    const amountNumber = Number(amount);

    if (!amount) {
      setAmountError('Amount is required');
      return;
    }else{
      setAmountError(null);
    }

    if (amountNumber <= 0) {
      setError('Cannot transfer 0 ' + asset.symbol + ' or less. Please change the amount');
      return;
    } 
    // else if (asset.availableBalance < amountNumber) {
    //     setError('Insufficient balance for transfer. You only have ' + asset.availableBalance + ' ' + asset.symbol + ' left');
    //   return;
    // } 
    else{
        setError(null);
    }

    if (!recipientIdentifier) {
        setRecipientError('Recipient identifier is required');
        return;
      }else{
          setRecipientError(null);
      }

    setLoading(true);

    try {
      const success = await transferCrypto(amountNumber, recipientIdentifier);
      if (success) {
        navigate('/dashboard/wallet');
      }
    } catch (err) {
      setError('An error occurred during the transfer. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleTransfer} className="space-y-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="amount" className="text-base">Amount in {asset.symbol}</Label>
          <div className="relative mt-1.5">
            <Input
              id="amount"
              type="number"
              placeholder="0.00"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="pl-3 pr-16 py-6 text-lg"
            />
            <div className="absolute inset-y-0 right-3 flex items-center">
              <span className="text-muted-foreground font-medium">{asset.symbol}</span>
            </div>
          </div>
          {amountError && (
            <p className="text-destructive text-sm mt-2">{amountError}</p>
          )}
        </div>

        <div>
          <Label htmlFor="recipient" className="text-base">Recipient</Label>
          <Input
            id="recipient"
            type="text"
            placeholder="Email or phone number"
            value={recipientIdentifier}
            onChange={(e) => setRecipientIdentifier(e.target.value)}
            className="mt-1.5"
          />
        </div>
      </div>

      {error && (
        <div className="p-3 rounded-lg bg-destructive/10 text-destructive text-sm">
          {error}
        </div>
      )}

      <Button 
        type="submit" 
        className="w-full py-6 text-lg"
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Send Transfer'}
      </Button>
    </form>
  );
};

export default TransferForm;
