import { useEffect, useState } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import {
  Wallet, ArrowUpRight, ArrowDownRight, LineChart, ArrowRight,
  TrendingUp, Clock, Shield, AlertCircle, Loader2, Activity,
  PieChart, Zap, Bell, Settings, RefreshCw, ShoppingCart, DollarSign, ArrowDown, ArrowLeftRight
} from 'lucide-react';
import MetricCard from '@/components/ui-components/MetricCard';
import { useUser } from '@/contexts/UserContext';
import { useWallet } from '@/contexts/WalletContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card';
import { formatCurrency, formatNumber } from '@/lib/utils';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Progress } from '@/components/ui/progress';
import { Badge } from '@/components/ui/badge';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';
import { useTransaction } from '@/contexts/TransactionContext';

interface ApiError {
  status?: boolean;
  code?: number;
  message?: string;
  response?: {
    data?: {
      status?: boolean;
      code?: number;
      message?: string;
    };
  };
}

const Dashboard = () => {
  const { user, isLoading } = useUser();
  const { assets, fetchAssets, loading: isLoadingWallet, error: walletError } = useWallet();
  const { transactions,   error: transactionError, fetchTransactionHistory } = useTransaction();
  const [needsVerification, setNeedsVerification] = useState(false);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'EvoCash | Dashboard';

    // Safely check kycLevel
    const kycLevel = user?.kycLevel ?? 0;
    console.log('KYC Level:', kycLevel);

    const loadAssets = async () => {
      try {
        await fetchAssets();

      } catch (error: unknown) {
        console.log("Error caught:", error);
      }
    };

    const loadTransactions = async () => {
      try {
        setIsLoadingTransactions(true);
        await fetchTransactionHistory({ page: 1, limit: 3});
      } catch (error) {
        console.error('Error loading transactions:', error);
      } finally {
        setIsLoadingTransactions(false);
      }
    };

    loadAssets();
    loadTransactions();
  }, []);



  // Use a separate useEffect to check the error from context
  useEffect(() => {
    if (walletError) {
      const isVerificationError =
        typeof walletError === 'object' &&
        'code' in walletError &&
        walletError.code === 403 &&
        walletError.message?.includes("country, date of birth and phone number");

      if (isVerificationError || (user?.kycLevel ?? 0) === 0) {
        setNeedsVerification(true);
      }
    }
  }, [walletError, user?.kycLevel]);

  // Navigate to profile for verification
  const handleVerificationRedirect = () => {
    navigate('/dashboard/profile');
  };

  // Simple derived state variable
  const shouldShowVerificationCard = needsVerification || (
    walletError &&
    typeof walletError === 'object' &&
    ((walletError as ApiError).code === 403 ||
      (walletError as ApiError).status === false && (walletError as ApiError).code === 403 ||
      (walletError as ApiError).message?.includes("country, date of birth and phone number") ||
      (walletError as ApiError).response?.data?.code === 403 ||
      (walletError as ApiError).response?.data?.message?.includes("country, date of birth and phone number"))
  );

  // Calculate total portfolio value
  const totalPortfolioValue = assets.reduce((sum, asset) => sum + parseFloat(String(asset.availableBalance)), 0).toFixed(2);

  // Calculate pending transactions value
  const pendingTransactionsValue = assets.reduce((sum, asset) => sum + parseFloat(String(asset.pendingBalance)), 0).toFixed(2);

  const handleRefresh = async () => {
    try {
      await fetchAssets();
    } catch (error) {
      console.error("Error refreshing assets:", error);
    }
  };

  const getAssetColor = (type?: string) => {
    if (!type) return 'bg-gray-100 text-gray-700';
    
    const typeColors: Record<string, string> = {
      'crypto': 'bg-blue-100 text-blue-700',
      'fiat': 'bg-green-100 text-green-700',
      'token': 'bg-purple-100 text-purple-700',
      'nft': 'bg-amber-100 text-amber-700',
    };
    return typeColors[type.toLowerCase()] || 'bg-gray-100 text-gray-700';
  };

  if (isLoadingWallet || isLoading) {
    return (
      <DashboardLayout>
        <div className="flex flex-col items-center justify-center h-[60vh]">
          <Loader2 className="h-12 w-12 animate-spin text-primary mb-4" />
          <p className="text-muted-foreground">Loading your dashboard...</p>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6">
        {/* Hero section with glass morphism */}
        <section>
          <div className="rounded-xl bg-gradient-to-r from-primary/15 via-primary/10 to-background p-4 sm:p-8 backdrop-blur-sm border shadow-sm">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 sm:gap-6">
              <div className="space-y-3 sm:space-y-4">
                <div className="flex items-center gap-3">
                  <div className="h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-primary/20 flex items-center justify-center">
                    <span className="text-primary text-base sm:text-lg font-bold">
                      {user?.firstName?.[0] || user?.email?.[0]?.toUpperCase() || 'U'}
                    </span>
                  </div>
                  <div>
                    <h1 className="text-xl sm:text-3xl font-bold tracking-tight">
                      Welcome back, {user?.firstName || 'there'}!
                    </h1>
                    <p className="text-xs sm:text-sm text-muted-foreground">
                      <span className="inline-flex items-center gap-1">
                        <Clock className="h-3 w-3" />
                        Last login: {new Date().toLocaleDateString()}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 md:gap-3 self-end sm:self-auto">
                <Button size="sm" variant="ghost" className="h-10 w-10 rounded-full" onClick={() => navigate('/dashboard/notifications')}>
                  <Bell className="h-5 w-5" />
                </Button>
                <Button size="sm" variant="ghost" className="h-10 w-10 rounded-full" onClick={() => navigate('/dashboard/settings')}>
                  <Settings className="h-5 w-5" />
                </Button>
                <Button
                  onClick={() => navigate('/dashboard/profile')}
                  className="hidden md:flex"
                >
                  View Profile
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Verification alert */}
        {shouldShowVerificationCard && (
          <Card className="p-6 border-amber-500 dark:border-amber-400 border-2 shadow-sm">
            <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
              <div className="flex items-center justify-center p-3 rounded-full bg-amber-100 dark:bg-amber-900">
                <AlertCircle className="h-6 w-6 text-amber-500 dark:text-amber-400" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-1">Verification Required</h3>
                <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                  Complete your profile by setting your country, date of birth, and phone number to access all wallet features.
                </p>
                <div className="flex gap-2">
                  <Button
                    onClick={handleVerificationRedirect}
                    className="bg-amber-500 hover:bg-amber-600 text-white dark:bg-amber-600 dark:hover:bg-amber-700"
                  >
                    Complete Verification
                  </Button>
                  <Button variant="outline">Learn More</Button>
                </div>
              </div>
            </div>
          </Card>
        )}

        {/* Quick actions row */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 md:gap-4">
          {quickActions.map((action, index) => (
            <Card
              key={index}
              className="border border-muted hover:border-primary/30 hover:bg-primary/5 transition-all cursor-pointer group"
              onClick={() => navigate(action.path)}
            >
              <CardContent className="p-3 sm:p-4 flex flex-col items-center justify-center text-center min-h-[80px] sm:min-h-0">
                <div className="h-9 w-9 sm:h-10 sm:w-10 rounded-full bg-primary/10 flex items-center justify-center mb-2 sm:mb-3 group-hover:bg-primary/20 transition-colors">
                  {action.icon}
                </div>
                <h3 className="font-medium text-sm sm:text-base">{action.label}</h3>
              </CardContent>
            </Card>
          ))}
        </div>

        {/* Main content tabs */}
        <Tabs defaultValue="overview" className="w-full" onValueChange={setActiveTab}>
          <TabsList className="grid grid-cols-3 mb-4 sm:mb-6">
            <TabsTrigger value="overview" className="py-2 sm:py-3">Overview</TabsTrigger>
            <TabsTrigger value="assets" className="py-2 sm:py-3">Assets</TabsTrigger>
            <TabsTrigger value="activity" className="py-2 sm:py-3">Activity</TabsTrigger>
          </TabsList>

          {/* Overview Tab */}
          <TabsContent value="overview" className="space-y-6">
            {/* Metrics Cards Grid */}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2.5 sm:gap-4 -mx-1 px-1">
              <MetricCard
                title="Total Portfolio"
                value={`$${totalPortfolioValue}`}
                icon={<Wallet className="h-5 w-5" />}
                description="Across all assets"
                variant="default"
                className="min-h-[100px] touch-manipulation"
              />
              <MetricCard
                title="24h Change"
                value="+2.5%"
                icon={<TrendingUp className="h-5 w-5" />}
                description="Since yesterday"
                variant="success"
                className="min-h-[100px] touch-manipulation"
              />
              <MetricCard
                title="Pending"
                value={`$${pendingTransactionsValue}`}
                icon={<Clock className="h-5 w-5" />}
                description="Processing"
                variant="warning"
                className="min-h-[100px] touch-manipulation"
              />
              <MetricCard
                title="KYC Level"
                value={`Level ${user?.kycLevel || 0}`}
                icon={<Shield className="h-5 w-5" />}
                description={user?.kycLevel === 2 ? "Verified" : "Upgrade"}
                variant={user?.kycLevel === 2 ? "success" : "default"}
                className="min-h-[100px] touch-manipulation"
              />
            </div>

            {/* Portfolio summary card */}
            <Card>
              <CardHeader className="pb-3">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-lg">Portfolio Summary</CardTitle>
                  <Button variant="ghost" size="sm" className="text-primary" onClick={() => navigate('/dashboard/portfolio')}>
                    View Details <ArrowRight className="ml-1 h-4 w-4" />
                  </Button>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {/* Portfolio Distribution */}
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="col-span-2 md:col-span-1">
                      <h4 className="text-sm font-medium mb-2">Distribution</h4>
                      <div className="h-40 flex items-center justify-center bg-muted/30 rounded-md">
                        {/* Placeholder for chart */}
                        <p className="text-sm text-muted-foreground">Portfolio distribution chart</p>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <h4 className="text-sm font-medium mb-2">Performance</h4>
                      <div className="h-40 flex items-center justify-center bg-muted/30 rounded-md">
                        {/* Placeholder for chart */}
                        <p className="text-sm text-muted-foreground">Performance chart</p>
                      </div>
                    </div>
                  </div>

                  {/* Top Assets */}
                  <div>
                    <h4 className="text-sm font-medium mb-2">Top Assets</h4>
                    <div className="space-y-3">
                      {assets.length > 0 ? assets.slice(0, 3).map((asset, index) => (
                        <div key={index} className="flex items-center justify-between p-3 rounded-lg hover:bg-muted/50 transition-colors">
                          <div className="flex items-center gap-3">
                            <div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center">
                              <span className="font-medium text-primary">{asset.symbol?.[0] || 'A'}</span>
                            </div>
                            <div>
                              <p className="font-medium">{asset.symbol || 'Asset'}</p>
                              <p className="text-sm text-muted-foreground">{asset.availableBalance || '0'} units</p>
                            </div>
                          </div>
                          <div className="text-right">
                            <p className="font-medium">${parseFloat(String(asset.availableBalance)).toFixed(2)}</p>
                            <p className="text-sm text-green-500">+1.2%</p>
                          </div>
                        </div>
                      )) : (
                        <p className="text-sm text-muted-foreground text-center py-4">No assets available</p>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
              <CardFooter className="pt-0">
                <Button variant="outline" className="w-full" onClick={() => navigate('/dashboard/wallet')}>
                  Manage All Assets
                </Button>
              </CardFooter>
            </Card>

            {/* Recent Activity */}
            <Card>
              <CardHeader className="pb-3">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-lg">Recent Activity</CardTitle>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-primary"
                    onClick={() => navigate('/dashboard/transactions')}
                  >
                    View All <ArrowRight className="ml-1 h-4 w-4" />
                  </Button>
                </div>
              </CardHeader>
              <CardContent>
                {isLoadingTransactions ? (
                  <div className="space-y-4">
                    {[1, 2, 3].map((i) => (
                      <div key={i} className="animate-pulse flex items-center gap-4 p-4">
                        <div className="h-12 w-12 rounded-full bg-muted"></div>
                        <div className="flex-1 space-y-2">
                          <div className="h-4 bg-muted rounded w-1/4"></div>
                          <div className="h-4 bg-muted rounded w-1/2"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : transactionError ? (
                  <div className="text-center py-4 text-muted-foreground">
                    Failed to load recent activity
                  </div>
                ) : transactions?.length === 0 ? (
                  <div className="text-center py-4 text-muted-foreground">
                    No recent activity
                  </div>
                ) : (
                  <div className="space-y-4">
                    {transactions?.map((transaction) => (
                      <TransactionItem
                        key={transaction.transactionId}
                        type={transaction.transactionType}
                        symbol={transaction.asset}
                        amount={transaction.amount}
                        value={transaction.amount}
                        time={transaction.createdAt}
                        status={transaction.status}
                      />
                    ))}
                  </div>
                )}
              </CardContent>
              <CardFooter>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => navigate('/dashboard/transactions')}
                >
                  View All Transactions
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>

          {/* Assets Tab */}
          <TabsContent value="assets" className="space-y-4">
            <div className="flex justify-between items-center px-1 mb-2">
              <div className="flex items-center gap-2">
                <h2 className="text-lg font-medium">Your Assets</h2>
                <Badge variant="secondary" className="bg-primary/5 text-primary">
                  {assets.length}
                </Badge>
              </div>
              <div className="flex items-center gap-2">
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="h-8 w-8 p-0" 
                  onClick={handleRefresh}
                >
                  <RefreshCw className="h-3.5 w-3.5" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-8 w-8 p-0 md:hidden"
                  onClick={() => navigate('/dashboard/wallet/deposit')}
                >
                  <ArrowUpRight className="h-3.5 w-3.5" />
                </Button>
              </div>
            </div>

            <div className="px-1 mb-4 md:hidden">
              <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide -mx-1 px-1">
                <Button 
                  variant="default"
                  size="sm"
                  className="h-10 whitespace-nowrap shadow-sm px-3"
                  onClick={() => navigate('/dashboard/wallet/payment')}
                >
                  <ShoppingCart className="h-3.5 w-3.5 mr-2" />
                  Buy
                </Button>
                <Button 
                  variant="outline"
                  size="sm"
                  className="h-10 whitespace-nowrap px-3"
                  onClick={() => navigate('/dashboard/wallet/sell')}
                >
                  <DollarSign className="h-3.5 w-3.5 mr-2" />
                  Sell
                </Button>
                <Button 
                  variant="outline"
                  size="sm"
                  className="h-10 whitespace-nowrap px-3"
                  onClick={() => navigate('/dashboard/wallet/deposit')}
                >
                  <ArrowDown className="h-3.5 w-3.5 mr-2" />
                  Deposit
                </Button>
                <Button 
                  variant="outline"
                  size="sm"
                  className="h-10 whitespace-nowrap px-3"
                  onClick={() => navigate('/dashboard/wallet/transfer')}
                >
                  <ArrowLeftRight className="h-3.5 w-3.5 mr-2" />
                  Transfer
                </Button>
              </div>
            </div>

            {assets.length > 0 ? (
              <div className="space-y-3">
                {assets.map((asset, index) => (
                  <div 
                    key={index} 
                    className="p-3 sm:p-4 rounded-xl border hover:bg-muted/30 transition-colors cursor-pointer"
                    onClick={() => navigate(`/markets`, { state: { asset } })}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2 sm:gap-3">
                        <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center text-lg">
                          {asset.logo || <span className="font-semibold text-primary">{asset.symbol?.[0] || 'A'}</span>}
                        </div>
                        <div>
                          <p className="font-medium text-sm sm:text-base">{asset.assetName || 'Unknown Asset'}</p>
                          <div className="flex items-center gap-2 flex-wrap">
                            <p className="text-xs text-muted-foreground">
                              {asset.format ? asset.format(asset.availableBalance || 0) : (asset.availableBalance || 0).toFixed(2)} {asset.symbol}
                            </p>
                            {(asset.pendingBalance || 0) > 0 && (
                              <Badge variant="outline" className="text-xs h-5 bg-amber-50 text-amber-700 border-amber-200">
                                {asset.format ? asset.format(asset.pendingBalance || 0) : (asset.pendingBalance || 0).toFixed(2)} pending
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="font-medium text-sm sm:text-base">${(asset.availableBalance && asset.currentPrice) ? 
                          ((asset.availableBalance || 0) * (asset.currentPrice || 1)).toFixed(2) : 
                          "0.00"}
                        </p>
                        <div className="flex items-center justify-end gap-1 mt-1">
                          <span className="text-xs text-success">
                            <TrendingUp className="h-3 w-3 inline mr-1" />
                            5%
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-3 flex justify-between gap-2">
                      <div className="hidden sm:block flex-1">
                        <div className="h-1.5 w-full bg-muted rounded overflow-hidden">
                          <div className="h-full bg-primary" style={{ width: `${Math.min(Math.random() * 100, 100)}%` }}></div>
                        </div>
                        <div className="flex justify-between text-xs text-muted-foreground mt-1">
                          <span>24h Low</span>
                          <span>24h High</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 ml-auto">
                        <Button 
                          variant="default"
                          size="sm"
                          className="h-9 sm:h-8 bg-green-600 hover:bg-green-700 text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate('/dashboard/wallet/payment', { state: { asset } });
                          }}
                        >
                          <ShoppingCart className="h-3 w-3 mr-1" />
                          <span className="inline text-xs sm:text-sm">Buy</span>
                        </Button>
                        <Button 
                          variant="outline"
                          size="sm"
                          className="h-9 sm:h-8 border-red-200 text-red-600 hover:bg-red-50"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate('/dashboard/wallet/sell', { state: { asset } });
                          }}
                        >
                          <DollarSign className="h-3 w-3 mr-1" />
                          <span className="inline text-xs sm:text-sm">Sell</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
                
                <div className="flex justify-center mt-4">
                  <Button 
                    variant="outline" 
                    className="w-full sm:w-auto"
                    onClick={() => navigate('/dashboard/wallet')}
                  >
                    <Wallet className="h-4 w-4 mr-2" />
                    View All Assets
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-12 px-4 text-center bg-card rounded-xl border">
                <div className="relative mb-5">
                  <div className="absolute -inset-4 bg-primary/10 rounded-full blur-sm"></div>
                  <div className="relative bg-primary/5 p-4 rounded-full">
                    <Wallet className="h-8 w-8 text-primary/70" />
                  </div>
                </div>
                <h3 className="text-lg font-medium mb-2">No assets found</h3>
                <p className="text-muted-foreground max-w-md mb-5 text-sm">
                  Get started by depositing your first asset or purchasing AGC tokens
                </p>
                <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
                  <Button 
                    onClick={() => navigate('/dashboard/wallet/deposit')} 
                    className="bg-primary shadow-sm font-medium w-full sm:w-auto"
                  >
                    <ArrowUpRight className="h-4 w-4 mr-2" />
                    Deposit
                  </Button>
                  <Button 
                    onClick={() => navigate('/dashboard/wallet/payment')}
                    variant="outline"
                    className="border-primary/20 text-primary shadow-sm font-medium w-full sm:w-auto"
                  >
                    <ShoppingCart className="h-4 w-4 mr-2" />
                    Buy AGC
                  </Button>
                </div>
              </div>
            )}
          </TabsContent>

          {/* Activity Tab */}
          <TabsContent value="activity" className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle>Transaction History</CardTitle>
              </CardHeader>
              <CardContent>
                {isLoadingTransactions ? (
                  <div className="space-y-4">
                    {[1, 2, 3].map((i) => (
                      <div key={i} className="animate-pulse flex items-center gap-4 p-4">
                        <div className="h-12 w-12 rounded-full bg-muted"></div>
                        <div className="flex-1 space-y-2">
                          <div className="h-4 bg-muted rounded w-1/4"></div>
                          <div className="h-4 bg-muted rounded w-1/2"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : transactionError ? (
                  <div className="text-center py-4 text-muted-foreground">
                    Failed to load recent activity
                  </div>
                ) : transactions?.length === 0 ? (
                  <div className="text-center py-4 text-muted-foreground">
                    No recent activity
                  </div>
                ) : (
                  <div className="space-y-4">
                    {transactions?.map((transaction) => (
                      <TransactionItem
                        key={transaction.transactionId}
                        type={transaction.transactionType}
                        symbol={transaction.asset}
                        amount={transaction.amount}
                        value={transaction.amount}
                        time={transaction.createdAt}
                        status={transaction.status}
                      />
                    ))}
                  </div>
                )}
              </CardContent>
              <CardFooter>
                <Button variant="outline" className="w-full" onClick={() => navigate('/dashboard/transactions')}>
                  View All Transactions
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

const quickActions = [
  {
    label: 'Deposit',
    icon: <ArrowUpRight className="h-5 w-5 text-primary" />,
    path: '/dashboard/wallet'
  },
  {
    label: 'Withdraw',
    icon: <ArrowDownRight className="h-5 w-5 text-primary" />,
    path: '/dashboard/wallet'
  },
  {
    label: 'Trade',
    icon: <Activity className="h-5 w-5 text-primary" />,
    path: '/dashboard/trade'
  },
  { 
    label: 'Portfolio', 
    icon: <PieChart className="h-5 w-5 text-primary" />, // Changed from Wallet to PieChart
    path: '/dashboard/portfolio' // Changed from /dashboard/wallet to /dashboard/portfolio
  },
];

const TransactionItem = ({ 
  type = '', 
  symbol, 
  amount, 
  value, 
  time, 
  status
}) => {
  const getTypeStyles = () => {
    // Convert to string first and then lowercase to avoid undefined errors
    const lowerType = (typeof type === 'string' ? type : '').toLowerCase();
    
    switch(lowerType) {
      case 'buy':
      case 'deposit':
        return {
          bgColor: 'bg-green-500/10',
          textColor: 'text-green-500',
          icon: <ArrowUpRight className="h-4 w-4" />
        };
      case 'sell':
      case 'withdrawal':
        return {
          bgColor: 'bg-red-500/10',
          textColor: 'text-red-500',
          icon: <ArrowDownRight className="h-4 w-4" />
        };
      default:
        return {
          bgColor: 'bg-blue-500/10',
          textColor: 'text-blue-500',
          icon: <ArrowRight className="h-4 w-4" />
        };
    }
  };

  const styles = getTypeStyles();
  const formattedTime = typeof time === 'string' ? new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : time;
  
  // Safely determine badge variant
  const getBadgeVariant = () => {
    if (!status) return 'outline';
    const statusLower = String(status).toLowerCase();
    return statusLower === 'completed' ? 'success' : 'outline';
  };

  return (
    <div className="flex items-center justify-between p-3 sm:p-4 rounded-lg hover:bg-muted/50 transition-colors">
      <div className="flex items-center gap-3 sm:gap-4">
        <div className={`h-9 w-9 sm:h-10 sm:w-10 rounded-full ${styles.bgColor} ${styles.textColor} flex items-center justify-center`}>
          {styles.icon}
        </div>
        <div>
          <div className="flex items-center gap-1 sm:gap-2">
            <p className="font-medium text-sm sm:text-base">{type}</p>
            {status && (
              <Badge variant={getBadgeVariant()} className="text-xs py-0">
                {status}
              </Badge>
            )}
          </div>
          <p className="text-xs sm:text-sm text-muted-foreground">
            {amount} {symbol}
          </p>
        </div>
      </div>
      <div className="text-right">
        <p className="font-medium text-sm sm:text-base">${parseFloat(String(value || 0)).toFixed(2)}</p>
        <p className="text-xs sm:text-sm text-muted-foreground">{formattedTime}</p>
      </div>
    </div>
  );
};

export default Dashboard;
