import React, { createContext, useContext, useState } from 'react';
import transactionService from '@/services/transactionService';
import { toast } from 'sonner';
import { Transaction, Pagination } from '@/components/transaction/TransactionModels';

interface TransactionHistoryResponse {
  transactions: Transaction[];
  pagination: Pagination;
}

interface TransactionContextType {
  transactions: Transaction[];
  pagination: Pagination | null;
  loading: boolean;
  error: string | null;
  fetchTransactionHistory: (params: TransactionHistoryParams) => Promise<void>;
}

interface TransactionHistoryParams {
  status?: string;
  transactionType?: string;
  fromDate?: string;
  toDate?: string;
  totalAmount?: number;
  recipient?: string;
  assetName?: string;
  page?: number;
  limit?: number;
}

const TransactionContext = createContext<TransactionContextType | undefined>(undefined);

export const TransactionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchTransactionHistory = async (params: TransactionHistoryParams) => {
    setLoading(true);
    setError(null);
    try {
      const response = await transactionService.getTransactionHistory(params);
      if (response.status) {
        //toast.success(response.message);
        setTransactions(response.data.serializedTransactions);
        setPagination(response.data.pagination);
      } else {
        setError('Error fetching transaction history');
        // Handle unauthorized access
        if (response.code === 401) {
          // Clear invalid auth state
          localStorage.removeItem('authToken');
          // Redirect to login
          window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname);
        }
        toast.error(response.message);
      }
    } catch (error: any) {
      console.error('Error fetching transaction history:', error);
      if (error.response?.status === 401) {
        window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname);
      }
      setError('Error fetching transaction history');
      toast.error(error.response?.data?.message || 'Failed to fetch transaction history');
    } finally {
      setLoading(false);
    }
  };

  return (
    <TransactionContext.Provider value={{
      transactions,
      pagination,
      loading,
      error,
      fetchTransactionHistory,
    }}>
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  const context = useContext(TransactionContext);
  if (!context) {
    throw new Error('useTransaction must be used within a TransactionProvider');
  }
  return context;
};
