import axios, { AxiosError } from 'axios';
import { toast } from 'sonner';

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  timeout: 30000, // Add timeout
});

// Request interceptor
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    // Handle network errors
    if (!error.response) {
      toast.error('Network error. Please check your connection.');
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 401:
        // Clear auth state
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        
        // Redirect to login only if not already on login page
        if (!window.location.pathname.includes('/login')) {
          // Use window.location.replace for a clean redirect
          window.location.replace('/login?redirect=' + encodeURIComponent(window.location.pathname));
        }
        break;

      case 403:
        toast.error('You do not have permission to perform this action');
        break;

      case 429:
        toast.error('Too many requests. Please try again later');
        break;

      case 500:
        toast.error('Server error. Please try again later');
        break;
    }

    return Promise.reject(error);
  }
);

export default apiClient;
