import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { 
  TrendingUp, 
  TrendingDown, 
  AlertCircle, 
  RefreshCw, 
  Filter, 
  Newspaper, 
  Star, 
  StarOff, 
  Calendar, 
  Search, 
  Timer,
  SlidersHorizontal,
  ChevronUp,
  ChevronDown 
} from 'lucide-react';
import { motion } from 'framer-motion';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { Separator } from '@/components/ui/separator';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

interface CryptoNewsProps {
  searchQuery?: string;
  category?: string;
}

// Updated interfaces to match the CoinDesk API response structure
interface NewsItem {
  id: number;
  title: string;
  url: string;
  sourceId: number;
  sourceName: string;  
  publishedOn: number;
  body: string;
  imageUrl?: string;
  categories: Array<{ ID: number; NAME: string }>;
  sentiment?: string;
  authors?: string;
  guid: string;
  bookmarked?: boolean;
}

interface SourceData {
  ID: number;
  SOURCE_KEY: string;
  NAME: string;
  IMAGE_URL: string;
  URL: string;
}

interface CategoryData {
  ID: number;
  NAME: string;
}

interface CoinDeskArticleResponse {
  Data: Array<{
    TYPE: string;
    ID: number;
    GUID: string;
    TITLE: string;
    URL: string;
    SOURCE_ID: number;
    PUBLISHED_ON: number;
    BODY: string;
    IMAGE_URL: string;
    CATEGORY_DATA: CategoryData[];
    SENTIMENT: string;
    AUTHORS: string;
    SOURCE_DATA: SourceData;
  }>;
  Err: any;
}

// IMPORTANT: In a production environment, API calls should be made from your backend
// to avoid CORS issues and to keep your API key secure
const REFRESH_INTERVAL = 300000; // 5 minutes
const API_KEY_PLACEHOLDER = "YOUR_API_KEY"; // Replace with environment variable in production

// Helper to proxy images through Cloudinary or use allowed sources
const getProxiedImageUrl = (url: string): string => {
  if (!url) return '/api/placeholder/400/200?text=No+Image';
  
  // Don't proxy already allowed domains
  if (url.startsWith('/') || 
      url.startsWith('data:') || 
      url.startsWith('blob:') ||
      url.includes('res.cloudinary.com') ||
      url.includes('1000logos.net')) {
    return url;
  }
  
  try {
    // Encode the URL for Cloudinary fetch
    const encodedUrl = encodeURIComponent(url);
    // Replace with your Cloudinary cloud name
    return `https://res.cloudinary.com/your-cloud-name/image/fetch/f_auto,q_auto/${encodedUrl}`;
  } catch (error) {
    console.error('Error proxying image:', error);
    return '/api/placeholder/400/200?text=Error+Loading+Image';
  }
}

export const CryptoNews: React.FC<CryptoNewsProps> = ({ searchQuery, category }) => {
  const [newsError, setNewsError] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(['Bitcoin', 'Ethereum']);
  const [excludedCategories, setExcludedCategories] = useState<string[]>([]);
  const [availableCategories, setAvailableCategories] = useState<{ID: number, NAME: string}[]>([]);
  const [isUsingFallback, setIsUsingFallback] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [newsSearch, setNewsSearch] = useState('');
  const [sortBy, setSortBy] = useState('newest');
  const [showImagesOnly, setShowImagesOnly] = useState(false);
  const [bookmarkedNews, setBookmarkedNews] = useState<string[]>([]);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [isPrioritySentiment, setIsPrioritySentiment] = useState('all');

  // Predefined categories for fallback
  const predefinedCategories = [
    { ID: 1, NAME: 'Bitcoin' },
    { ID: 2, NAME: 'Ethereum' },
    { ID: 3, NAME: 'DeFi' },
    { ID: 4, NAME: 'NFT' },
    { ID: 5, NAME: 'Regulation' },
    { ID: 6, NAME: 'Trading' },
    { ID: 7, NAME: 'Mining' },
    { ID: 8, NAME: 'Security' },
    { ID: 9, NAME: 'Technology' },
    { ID: 10, NAME: 'Business' }
  ];

  // Set available categories to predefined ones on mount
  useEffect(() => {
    setAvailableCategories(predefinedCategories);
  }, []);

  // Auto-refresh timer
  useEffect(() => {
    let refreshTimer: NodeJS.Timeout;
    
    if (autoRefresh) {
      refreshTimer = setInterval(() => {
        refetch();
        setLastUpdated(new Date());
      }, REFRESH_INTERVAL);
    }
    
    return () => {
      if (refreshTimer) clearInterval(refreshTimer);
    };
  }, [autoRefresh]);

  const fetchCryptoNews = useCallback(async (): Promise<NewsItem[]> => {
    try {
      console.log("Attempting to fetch news from CoinDesk API...");
      
      const baseUrl = import.meta.env.PROD 
        ? 'https://data-api.coindesk.com'
        : '/coindesk-api';
      
      const response = await axios.get(`${baseUrl}/news/v1/article/list`, {
        params: {
          lang: 'EN',
          limit: 15,
          categories: selectedCategories.length > 0 ? selectedCategories.join(',') : undefined,
          exclude_categories: excludedCategories.length > 0 ? excludedCategories.join(',') : undefined,
        },
        headers: {
          'X-API-Key': import.meta.env.VITE_COINDESK_API_KEY
        }
      });

      if (!response.data?.Data) {
        throw new Error('Invalid response format from CoinDesk API');
      }

      return response.data.Data.map((item: any) => ({
        id: item.ID,
        title: item.TITLE,
        url: item.URL,
        sourceId: item.SOURCE_ID,
        sourceName: item.SOURCE_DATA?.NAME || 'Unknown Source',
        publishedOn: item.PUBLISHED_ON,
        body: item.BODY?.length > 160 ? item.BODY.substring(0, 160) + '...' : (item.BODY || 'No description available'),
        imageUrl: item.IMAGE_URL,
        categories: item.CATEGORY_DATA || [],
        sentiment: item.SENTIMENT,
        authors: item.AUTHORS,
        guid: item.GUID,
        bookmarked: bookmarkedNews.includes(item.GUID)
      }));
      
    } catch (error) {
      console.error('CoinDesk API failed:', error);
      setIsUsingFallback(true);
      throw new Error("Unable to fetch news data. Using demo data for preview purposes.");
    }
  }, [selectedCategories, excludedCategories, bookmarkedNews]);

  // Query for crypto news
  const { 
    data: cryptoNews, 
    isLoading: isNewsLoading,
    refetch,
    dataUpdatedAt
  } = useQuery({
    queryKey: ['cryptoNews', selectedCategories, excludedCategories],
    queryFn: fetchCryptoNews,
    refetchInterval: REFRESH_INTERVAL,
    refetchIntervalInBackground: autoRefresh,
    retry: 1,
    staleTime: 2400000, // 40 minutes
    onError: (error: Error) => {
      setNewsError(error.message);
    },
    onSuccess: () => {
      setLastUpdated(new Date());
    }
  });

  // Category management functions
  const toggleCategory = (categoryName: string) => {
    if (selectedCategories.includes(categoryName)) {
      setSelectedCategories(selectedCategories.filter(c => c !== categoryName));
    } else {
      setSelectedCategories([...selectedCategories, categoryName]);
    }
  };

  // Bookmark management
  const toggleBookmark = (newsId: string) => {
    if (bookmarkedNews.includes(newsId)) {
      setBookmarkedNews(bookmarkedNews.filter(id => id !== newsId));
    } else {
      setBookmarkedNews([...bookmarkedNews, newsId]);
    }
  };

  // Generate mock news data based on selected categories
  const generateMockNewsData = useCallback((): NewsItem[] => {
    // Generate mock timestamps for the past few days
    const now = Math.floor(Date.now() / 1000);
    const yesterday = now - 86400;
    const twoDaysAgo = now - 172800;
    const threeDaysAgo = now - 259200;
    
    // Create mock news data with different sentiments and sources
    const mockNews: NewsItem[] = [
      {
        id: 901,
        title: "Bitcoin Breaks $80,000 Resistance Level, Analysts Predict Further Gains",
        url: "https://www.example.com/bitcoin-rally",
        sourceId: 1,
        sourceName: "CryptoTrader Daily",
        publishedOn: now - 3600, // 1 hour ago
        body: "Bitcoin has surpassed the crucial $80,000 resistance level, showing strong momentum as institutional buying continues to increase. Technical analysts suggest this could lead to a new all-time high in the coming weeks.",
        categories: [{ ID: 1, NAME: "Bitcoin" }, { ID: 6, NAME: "Trading" }],
        imageUrl: "/api/placeholder/400/200",
        sentiment: "POSITIVE",
        authors: "Michael Santelli",
        guid: "mock-btc-1",
        bookmarked: bookmarkedNews.includes("mock-btc-1")
      },
      
    ];

    // Filter by selected categories if any are selected
    if (selectedCategories.length > 0) {
      return mockNews.filter(news => 
        news.categories.some(cat => selectedCategories.includes(cat.NAME))
      );
    }
    
    return mockNews;
  }, [selectedCategories, bookmarkedNews]);

  // Process and filter news data
  const processedNewsData = useMemo(() => {
    let newsData = cryptoNews || generateMockNewsData();

    // Filter by search term
    if (newsSearch) {
      const searchTerm = newsSearch.toLowerCase();
      newsData = newsData.filter(news => 
        news.title.toLowerCase().includes(searchTerm) || 
        news.body.toLowerCase().includes(searchTerm)
      );
    }

    // Filter by tab
    if (activeTab === 'bookmarked') {
      newsData = newsData.filter(news => bookmarkedNews.includes(news.guid));
    } else if (activeTab === 'positive') {
      newsData = newsData.filter(news => news.sentiment === 'POSITIVE');
    } else if (activeTab === 'negative') {
      newsData = newsData.filter(news => news.sentiment === 'NEGATIVE');
    } else if (activeTab === 'neutral') {
      newsData = newsData.filter(news => news.sentiment === 'NEUTRAL');
    }

    // Filter by image availability if showImagesOnly is true
    if (showImagesOnly) {
      newsData = newsData.filter(news => news.imageUrl && news.imageUrl !== '/api/placeholder/400/200');
    }

    // Priority sentiment filter (this overrides the tab filter for sentiment)
    if (isPrioritySentiment !== 'all') {
      newsData = newsData.filter(news => news.sentiment === isPrioritySentiment.toUpperCase());
    }

    // Sort by selected sort option
    if (sortBy === 'newest') {
      newsData = [...newsData].sort((a, b) => b.publishedOn - a.publishedOn);
    } else if (sortBy === 'oldest') {
      newsData = [...newsData].sort((a, b) => a.publishedOn - b.publishedOn);
    } else if (sortBy === 'sentiment') {
      // Sort by sentiment (POSITIVE first, then NEUTRAL, then NEGATIVE)
      newsData = [...newsData].sort((a, b) => {
        const sentimentValue = {
          'POSITIVE': 3,
          'NEUTRAL': 2,
          'NEGATIVE': 1,
          undefined: 0
        };
        return (sentimentValue[b.sentiment as keyof typeof sentimentValue] || 0) - 
               (sentimentValue[a.sentiment as keyof typeof sentimentValue] || 0);
      });
    }

    return newsData;
  }, [cryptoNews, generateMockNewsData, newsSearch, activeTab, showImagesOnly, sortBy, bookmarkedNews, isPrioritySentiment]);

  // Format relative time
  const formatRelativeTime = (timestamp: number): string => {
    const now = Math.floor(Date.now() / 1000);
    const diff = now - timestamp;
    
    if (diff < 60) return `${diff}s ago`;
    if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
    
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  // Count news by sentiment
  const sentimentCounts = useMemo(() => {
    const allNews = cryptoNews || generateMockNewsData();
    return {
      positive: allNews.filter(n => n.sentiment === 'POSITIVE').length,
      negative: allNews.filter(n => n.sentiment === 'NEGATIVE').length,
      neutral: allNews.filter(n => n.sentiment === 'NEUTRAL').length,
      bookmarked: allNews.filter(n => bookmarkedNews.includes(n.guid)).length
    };
  }, [cryptoNews, generateMockNewsData, bookmarkedNews]);

  // Manual refresh handler
  const handleRefresh = () => {
    refetch();
    setLastUpdated(new Date());
  };

  return (
    <div className="space-y-4">
      {/* Market Sentiment Indicator */}
      <div className="mb-4">
        <Card className="border-none shadow-sm bg-gradient-to-r from-slate-50 to-slate-100 dark:from-slate-900 dark:to-slate-800">
          <CardContent className="p-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="flex items-center gap-4">
                <div className="flex flex-col">
                  <h2 className="text-xl sm:text-2xl font-bold tracking-tight">Market Pulse</h2>
                  <p className="text-xs sm:text-sm text-muted-foreground">
                    Last updated: {lastUpdated.toLocaleTimeString()}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between sm:justify-end gap-4">
                <div className="flex items-center gap-2">
                  <div className="text-xs sm:text-sm font-medium">Auto-refresh</div>
                  <Switch 
                    checked={autoRefresh}
                    onCheckedChange={setAutoRefresh}
                  />
                </div>
                <Button
                  onClick={handleRefresh}
                  size="sm"
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  <RefreshCw className="h-4 w-4" />
                  <span className="hidden sm:inline">Refresh</span>
                </Button>
              </div>
            </div>
            
            {/* Sentiment Summary */}
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 mt-4">
              <Card className="bg-green-50 dark:bg-green-900/20 border-green-100 dark:border-green-800">
                <CardContent className="p-3 flex justify-between items-center">
                  <div>
                    <p className="text-xs text-green-600 dark:text-green-400 font-medium">POSITIVE</p>
                    <h3 className="text-2xl font-bold text-green-700 dark:text-green-300 flex items-center gap-1">
                      {sentimentCounts.positive} <TrendingUp className="h-4 w-4" />
                    </h3>
                  </div>
                  <div className="w-8 h-8 rounded-full bg-green-100 dark:bg-green-800 flex items-center justify-center">
                    <ChevronUp className="h-5 w-5 text-green-600 dark:text-green-400" />
                  </div>
                </CardContent>
              </Card>
              
              <Card className="bg-red-50 dark:bg-red-900/20 border-red-100 dark:border-red-800">
                <CardContent className="p-3 flex justify-between items-center">
                  <div>
                    <p className="text-xs text-red-600 dark:text-red-400 font-medium">NEGATIVE</p>
                    <h3 className="text-2xl font-bold text-red-700 dark:text-red-300 flex items-center gap-1">
                      {sentimentCounts.negative} <TrendingDown className="h-4 w-4" />
                    </h3>
                  </div>
                  <div className="w-8 h-8 rounded-full bg-red-100 dark:bg-red-800 flex items-center justify-center">
                    <ChevronDown className="h-5 w-5 text-red-600 dark:text-red-400" />
                  </div>
                </CardContent>
              </Card>
              
              <Card className="bg-blue-50 dark:bg-blue-900/20 border-blue-100 dark:border-blue-800">
                <CardContent className="p-3 flex justify-between items-center">
                  <div>
                    <p className="text-xs text-blue-600 dark:text-blue-400 font-medium">NEUTRAL</p>
                    <h3 className="text-2xl font-bold text-blue-700 dark:text-blue-300 flex items-center gap-1">
                      {sentimentCounts.neutral} <Newspaper className="h-4 w-4" />
                    </h3>
                  </div>
                  <div className="w-8 h-8 rounded-full bg-blue-100 dark:bg-blue-800 flex items-center justify-center">
                    <AlertCircle className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                  </div>
                </CardContent>
              </Card>
              
              <Card className="bg-amber-50 dark:bg-amber-900/20 border-amber-100 dark:border-amber-800">
                <CardContent className="p-3 flex justify-between items-center">
                  <div>
                    <p className="text-xs text-amber-600 dark:text-amber-400 font-medium">BOOKMARKED</p>
                    <h3 className="text-2xl font-bold text-amber-700 dark:text-amber-300 flex items-center gap-1">
                      {sentimentCounts.bookmarked} <Star className="h-4 w-4" />
                    </h3>
                  </div>
                  <div className="w-8 h-8 rounded-full bg-amber-100 dark:bg-amber-800 flex items-center justify-center">
                    <Star className="h-5 w-5 text-amber-600 dark:text-amber-400" />
                  </div>
                </CardContent>
              </Card>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Filter and Search Controls */}
      <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 md:gap-4 mb-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="text"
              placeholder="Search news..."
              className="pl-9 bg-background"
              value={newsSearch}
              onChange={(e) => setNewsSearch(e.target.value)}
            />
          </div>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                <Filter className="h-4 w-4" />
                <span className="hidden sm:inline">Filters</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
              <div className="grid gap-4">
                <div className="space-y-2">
                  <h4 className="font-medium leading-none">Categories</h4>
                  <p className="text-xs text-muted-foreground">Select categories to display</p>
                  <div className="grid grid-cols-2 gap-2">
                    {availableCategories.map((category) => (
                      <Badge
                        key={category.ID}
                        variant={selectedCategories.includes(category.NAME) ? "default" : "outline"}
                        className="cursor-pointer transition-all hover:scale-105 justify-center"
                        onClick={() => toggleCategory(category.NAME)}
                      >
                        {category.NAME}
                      </Badge>
                    ))}
                  </div>
                </div>
                <Separator />
                <div className="space-y-2">
                  <h4 className="font-medium leading-none">Priority Sentiment</h4>
                  <Select 
                    value={isPrioritySentiment}
                    onValueChange={setIsPrioritySentiment}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Filter by sentiment" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Sentiments</SelectItem>
                      <SelectItem value="positive">Positive Only</SelectItem>
                      <SelectItem value="negative">Negative Only</SelectItem>
                      <SelectItem value="neutral">Neutral Only</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <Separator />
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="images-only"
                      checked={showImagesOnly}
                      onCheckedChange={setShowImagesOnly}
                    />
                    <label htmlFor="images-only" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Show articles with images only
                    </label>
                  </div>
                </div>
              </div>
            </PopoverContent>
          </Popover>
          
          <Select
            value={sortBy}
            onValueChange={setSortBy}
          >
            <SelectTrigger className="w-[90px] sm:w-36">
              <SelectValue placeholder="Sort" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="newest">
                <div className="flex items-center gap-2">
                  <Timer className="h-4 w-4" />
                  Newest
                </div>
              </SelectItem>
              <SelectItem value="oldest">
                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4" />
                  Oldest
                </div>
              </SelectItem>
              <SelectItem value="sentiment">
                <div className="flex items-center gap-2">
                  <TrendingUp className="h-4 w-4" />
                  Sentiment
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
          
          <Select 
            value={viewMode}
            onValueChange={(val) => setViewMode(val as 'grid' | 'list')}
          >
            <SelectTrigger className="w-[70px] sm:w-32">
              <SelectValue placeholder="View" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="grid">Grid View</SelectItem>
              <SelectItem value="list">List View</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* API Notice */}
      {newsError && (
        <div className="rounded-lg border border-amber-200 bg-amber-50 p-4">
          <div className="flex items-center gap-3">
            <AlertCircle className="h-5 w-5 text-amber-600" />
            <div>
              <h4 className="font-medium text-amber-900">API Connection Notice</h4>
              <p className="text-sm text-amber-700">{newsError}</p>
            </div>
          </div>
        </div>
      )}

      {/* Tabs and News Content */}
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="mb-4 bg-muted/50">
          <TabsTrigger value="all" className="flex items-center gap-1 px-3 py-1.5">
            <Newspaper className="h-4 w-4" />
            All News
          </TabsTrigger>
          <TabsTrigger value="bookmarked" className="flex items-center gap-1 px-3 py-1.5">
            <Star className="h-4 w-4" />
            Bookmarked ({sentimentCounts.bookmarked})
          </TabsTrigger>
          <TabsTrigger value="positive" className="flex items-center gap-1 px-3 py-1.5">
            <TrendingUp className="h-4 w-4" />
            Positive ({sentimentCounts.positive})
          </TabsTrigger>
          <TabsTrigger value="negative" className="flex items-center gap-1 px-3 py-1.5">
            <TrendingDown className="h-4 w-4" />
            Negative ({sentimentCounts.negative})
          </TabsTrigger>
          <TabsTrigger value="neutral" className="flex items-center gap-1 px-3 py-1.5">
            <AlertCircle className="h-4 w-4" />
            Neutral ({sentimentCounts.neutral})
          </TabsTrigger>
        </TabsList>

        {isNewsLoading ? (
          <div className="flex justify-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : (
          <>
            {processedNewsData.length === 0 ? (
              <div className="text-center p-8 border rounded-lg bg-muted/20">
                <Newspaper className="h-12 w-12 mx-auto text-muted-foreground mb-2" />
                <h3 className="font-medium text-lg mb-1">No news found</h3>
                <p className="text-muted-foreground text-sm">
                  Try adjusting your filters or search criteria
                </p>
              </div>
            ) : (
              <div className={viewMode === 'grid' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4' : 'space-y-4'}>
                {processedNewsData.map((news) => (
                  <motion.div 
                    key={news.guid}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Card 
                      className="h-full overflow-hidden hover:shadow-md transition-shadow cursor-pointer" 
                      onClick={() => window.open(news.url, '_blank', 'noopener,noreferrer')}
                    >
                      {news.imageUrl && (
                        <div className="relative h-48 w-full overflow-hidden bg-muted/30">
                          {/* Skeleton loader */}
                          <div className="absolute inset-0 animate-pulse bg-muted/50"></div>
                          
                          <img 
                            src={getProxiedImageUrl(news.imageUrl)}
                            alt={news.title || 'Crypto news image'}
                            className="object-cover w-full h-full transition-opacity duration-300 relative z-10"
                            loading="lazy"
                            onLoad={(e) => {
                              e.currentTarget.style.opacity = '1';
                              const parent = e.currentTarget.parentElement;
                              if (parent) {
                                const skeleton = parent.querySelector('div');
                                if (skeleton) skeleton.style.display = 'none';
                              }
                            }}
                            onError={(e) => {
                              e.currentTarget.src = '/api/placeholder/400/200?text=Crypto+News';
                              e.currentTarget.style.opacity = '1';
                              const parent = e.currentTarget.parentElement;
                              if (parent) {
                                const skeleton = parent.querySelector('div');
                                if (skeleton) skeleton.style.display = 'none';
                              }
                            }}
                            style={{ opacity: 0 }}
                          />
                          
                          {/* Overlay gradient for better text contrast */}
                          <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-black/60 to-transparent z-20"></div>
                        </div>
                      )}
                      <CardHeader className="py-2 px-3 sm:py-3 sm:px-4">
                        <div className="flex justify-between items-start">
                          <CardTitle className="text-base sm:text-lg font-bold mb-1 sm:mb-2 line-clamp-2">
                            {news.title}
                          </CardTitle>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-7 w-7 sm:h-8 sm:w-8 -mr-2 -mt-1 flex-shrink-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleBookmark(news.guid);
                            }}
                          >
                            {news.bookmarked ? 
                              <Star className="h-4 w-4 text-amber-500 fill-amber-500" /> : 
                              <StarOff className="h-4 w-4 text-muted-foreground" />
                            }
                          </Button>
                        </div>
                        <div className="flex items-center gap-2 text-xs text-muted-foreground">
                          <span>{news.sourceName}</span>
                          <span>•</span>
                          <span>{formatRelativeTime(news.publishedOn)}</span>
                        </div>
                      </CardHeader>
                      <CardContent className="py-1 px-3 sm:py-2 sm:px-4">
                        <p className="text-xs sm:text-sm line-clamp-3">{news.body}</p>
                      </CardContent>
                      <CardFooter className="py-2 px-3 sm:py-3 sm:px-4 flex flex-wrap justify-between items-center gap-2">
                        <div className="flex flex-wrap gap-1">
                          {news.categories.slice(0, 2).map((cat) => (
                            <Badge key={cat.ID} variant="outline" className="text-xs px-1 py-0 h-5">
                              {cat.NAME}
                            </Badge>
                          ))}
                        </div>
                        <div className="flex items-center gap-1 sm:gap-2">
                          {news.sentiment && (
                            <Badge
                              variant="outline"
                              className={`text-xs px-1 py-0 h-5 ${
                                news.sentiment === 'POSITIVE'
                                  ? 'bg-green-50 text-green-700 dark:bg-green-900/20 dark:text-green-400'
                                  : news.sentiment === 'NEGATIVE'
                                  ? 'bg-red-50 text-red-700 dark:bg-red-900/20 dark:text-red-400'
                                  : 'bg-blue-50 text-blue-700 dark:bg-blue-900/20 dark:text-blue-400'
                              }`}
                            >
                              {news.sentiment.toLowerCase()}
                            </Badge>
                          )}
                          <Button 
                            size="sm"
                            variant="outline"
                            className="h-7 text-xs px-2 ml-1 sm:ml-2"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              window.open(news.url, '_blank', 'noopener,noreferrer');
                            }}
                          >
                            Read
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </motion.div>
                ))}
              </div>
            )}
          </>
        )}
      </Tabs>
    </div>
  );
};
