import { useMemo } from 'react';
import { Search, User, Settings, LogOut, Crown, Shield, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '@/contexts/PageTitleContext';
import { useUser } from '@/contexts/UserContext';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Badge } from '@/components/ui/badge';
import ThemeToggle from '../layout/navbar/ThemeToggle';
import NotificationsPopover from './NotificationsPopover';
import { motion } from 'framer-motion';

interface DashboardNavbarProps {
  onOpenSearch: () => void;
}

const DashboardNavbar = ({ onOpenSearch }: DashboardNavbarProps) => {
  const navigate = useNavigate();
  const { pageTitle } = usePageTitle();
  const { user } = useUser();

  const userInitials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
    }
    return (user?.firstName?.[0] || 'A').toUpperCase();
  }, [user]);

  const displayName = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user?.firstName || 'Account';
  }, [user]);

  const getRoleBadge = () => {
    const roleConfig = {
      admin: {
        icon: Shield,
        className: 'text-purple-500 bg-purple-500/10 border border-purple-500/20',
        label: 'Admin'
      },
      premium: {
        icon: Crown,
        className: 'text-amber-500 bg-amber-500/10 border border-amber-500/20',
        label: 'Premium'
      },
      default: {
        icon: User,
        className: 'text-slate-500 bg-slate-500/10 border border-slate-500/20',
        label: 'User'
      }
    };

    const config = roleConfig[user?.role?.toLowerCase() || 'default'] || roleConfig.default;
    const Icon = config.icon;

    return (
      <div className={`flex items-center gap-1.5 px-2 py-0.5 rounded-full ${config.className}`}>
        <Icon className="w-3 h-3" />
        <span className="text-xs font-medium">{config.label}</span>
      </div>
    );
  };

  return (
    <motion.header
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60"
    >
      <div className="container flex h-16 items-center gap-4">
        {/* Left Section */}
        <div className="flex items-center gap-3">
          <h1 className="text-xl font-semibold tracking-tight bg-gradient-to-r from-foreground to-foreground/70 bg-clip-text text-transparent">
            {pageTitle}
          </h1>
          {user?.role === 'admin' && (
            <Badge variant="outline" className="rounded-full px-2 py-0.5 border-primary/20 text-primary bg-primary/10">
              Admin Dashboard
            </Badge>
          )}
        </div>

        {/* Right Section */}
        <div className="flex flex-1 items-center justify-end gap-4">
          {/* Search */}
          <div className="relative group">
            <Button
              variant="outline"
              className="flex items-center gap-2 h-9 px-4 transition-all duration-200 group-hover:border-primary/30 group-hover:bg-accent"
              onClick={onOpenSearch}
            >
              <Search className="h-4 w-4 text-muted-foreground group-hover:text-foreground transition-colors" />
              <span className="text-sm text-muted-foreground group-hover:text-foreground transition-colors">Quick search...</span>
              <kbd className="hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
                <span className="text-xs">⌘</span>K
              </kbd>
            </Button>
          </div>

          {/* Actions */}
          <div className="flex items-center gap-2">
          {location.pathname !== "/dashboard/notifications" && (
            user && <NotificationsPopover />
          )}
            <ThemeToggle />
            
            {/* User Menu */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="group relative h-9 px-2 hover:bg-accent/50 transition-colors rounded-full"
                >
                  <div className="flex items-center gap-2">
                    <Avatar className="h-7 w-7 transition-transform group-hover:scale-105">
                      <AvatarImage 
                        src={user?.avatar} 
                        className="object-cover"
                      />
                      <AvatarFallback className="bg-primary/10 text-primary text-xs font-medium">
                        {userInitials}
                      </AvatarFallback>
                    </Avatar>
                    <div className="hidden md:flex items-center gap-2">
                      <div className="flex flex-col items-start">
                        <span className="text-sm font-medium leading-none">{displayName}</span>
                        <span className="text-xs text-muted-foreground">{user?.email}</span>
                      </div>
                      <ChevronDown className="h-4 w-4 text-muted-foreground transition-transform group-data-[state=open]:rotate-180" />
                    </div>
                  </div>
                </Button>
              </DropdownMenuTrigger>
              
              <DropdownMenuContent align="end" className="w-[280px] mt-2">
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col gap-3 p-2">
                    <div className="flex items-center gap-3">
                      <Avatar className="h-12 w-12 border-2 border-primary/20">
                        <AvatarImage src={user?.avatar} />
                        <AvatarFallback className="bg-primary/10 text-primary">
                          {userInitials}
                        </AvatarFallback>
                      </Avatar>
                      <div className="space-y-1">
                        <p className="text-sm font-medium leading-none">{displayName}</p>
                        <p className="text-xs text-muted-foreground">{user?.email}</p>
                        {getRoleBadge()}
                      </div>
                    </div>
                  </div>
                </DropdownMenuLabel>
                
                <DropdownMenuSeparator />
                
                <div className="p-2 space-y-1">
                  <DropdownMenuItem 
                    onClick={() => navigate('/dashboard/profile')}
                    className="flex items-center gap-2 p-2 cursor-pointer rounded-md hover:bg-accent focus:bg-accent"
                  >
                    <User className="h-4 w-4" />
                    <div className="flex flex-col">
                      <span className="text-sm">Profile</span>
                      <span className="text-xs text-muted-foreground">Manage your account</span>
                    </div>
                  </DropdownMenuItem>
                  
                  <DropdownMenuItem 
                    onClick={() => navigate('/dashboard/settings')}
                    className="flex items-center gap-2 p-2 cursor-pointer rounded-md hover:bg-accent focus:bg-accent"
                  >
                    <Settings className="h-4 w-4" />
                    <div className="flex flex-col">
                      <span className="text-sm">Settings</span>
                      <span className="text-xs text-muted-foreground">Customize your experience</span>
                    </div>
                  </DropdownMenuItem>
                </div>
                
                <DropdownMenuSeparator />
                
                
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default DashboardNavbar;
