import { useEffect, useState } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Card, CardHeader } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import AssetTable from '@/components/wallet/AssetTable';
import WalletSearch from '@/components/wallet/WalletSearch';
import { useWallet } from '@/contexts/WalletContext';
import { Button } from '@/components/ui/button';
import { 
  WalletIcon, 
  ArrowDownUp, 
  AlertCircle, 
  RefreshCw,
  Shield,
  Plus,
  Filter,
  ChevronRight,
  Loader2,
  TrendingUp,
  Clock,
  ArrowUp
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';
import TransactionList from '@/components/transaction/TransactionList';
import { Skeleton } from '@/components/ui/skeleton';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface ApiError {
  status?: boolean;
  code?: number;
  message?: string;
  response?: {
    data?: {
      status?: boolean;
      code?: number;
      message?: string;
    };
  };
}

const Wallet = () => {
  const { assets, fetchAssets, loading, error } = useWallet();
  const [searchTerm, setSearchTerm] = useState('');
  const [assetType, setAssetType] = useState('All');
  const [needsVerification, setNeedsVerification] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('assets');
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Calculate total balance and 24h change with safeguards against NaN values
  const totalBalance = assets.reduce((sum, asset) => {
    const balance = asset.availableBalance || 0;
    const price = asset.currentPrice || 0;
    return sum + (balance * price);
  }, 0);
  
  const total24hChange = assets.reduce((sum, asset) => {
    const balance = asset.availableBalance || 0;
    const price = asset.currentPrice || 0;
    const priceChange = asset.priceChange24h || 0;
    const assetChange = (balance * price * priceChange) / 100;
    return sum + assetChange;
  }, 0);
  
  const change24hPercentage = totalBalance > 0 
    ? ((total24hChange / (totalBalance - total24hChange)) * 100).toFixed(2)
    : "0.00";

  // Calculate pending transactions with safeguards
  const pendingAmount = assets.reduce((sum, asset) => {
    const pendingBalance = asset.pendingBalance || 0;
    const price = asset.currentPrice || 0;
    return sum + (pendingBalance * price);
  }, 0);

  // Format currency consistently
  const formatCurrency = (amount: number) => {
    // Ensure the amount is a valid number
    const validAmount = isNaN(amount) ? 0 : amount;
    
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(validAmount);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAssets();
      } catch (error) {
        console.log("Error caught:", error);
      }
    };

    fetchData();
    document.title = 'EvoCash | Wallet';
  }, []);

  useEffect(() => {
    // Add null check for user
    if (error && user?.kycLevel === 0) {
      setNeedsVerification(true);
    }
  }, [error, user?.kycLevel]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await fetchAssets();
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleVerificationRedirect = () => {
    navigate('/dashboard/profile');
  };

  const filteredAssets = assets.filter(asset => {
    const matchesSearch = asset.assetName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          asset.symbol.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = assetType === 'All' || asset.type === assetType;
    return matchesSearch && matchesType;
  });

  const VerificationCard = () => (
    <div className="relative overflow-hidden rounded-xl bg-gradient-to-r from-amber-50 to-amber-100 dark:from-amber-900/30 dark:to-amber-900/20 border border-amber-200 dark:border-amber-800 mb-6">
      <div className="absolute -right-10 -top-10 w-32 h-32 rounded-full bg-amber-200/50 dark:bg-amber-800/20"></div>
      <div className="relative px-6 py-5 flex flex-col md:flex-row items-start md:items-center gap-4 z-10">
        <div className="flex items-center justify-center p-3 rounded-full bg-amber-100 dark:bg-amber-800/50">
          <AlertCircle className="h-6 w-6 text-amber-600 dark:text-amber-400" />
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-semibold mb-1 text-amber-900 dark:text-amber-100">Verification Required</h3>
          <p className="text-sm text-amber-800/90 dark:text-amber-200/80 mb-4">
            Complete your profile verification to access all wallet features.
          </p>
          <Button 
            onClick={handleVerificationRedirect}
            className="bg-amber-600 hover:bg-amber-700 text-white dark:bg-amber-500 dark:hover:bg-amber-600 shadow-sm"
          >
            Complete Verification
            <ChevronRight className="h-4 w-4 ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );

  const shouldShowVerificationCard = needsVerification || (
    error && 
    typeof error === 'object' && 
    ((error as ApiError).code === 403 || 
     (error as ApiError).status === false && (error as ApiError).code === 403 ||
     (error as ApiError).message?.includes("country, date of birth and phone number") ||
     (error as ApiError).response?.data?.code === 403 ||
     (error as ApiError).response?.data?.message?.includes("country, date of birth and phone number"))
  );

  const LoadingSkeleton = () => (
    <div className="p-6 space-y-4">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="flex justify-between items-center p-4 rounded-lg bg-muted/30">
          <div className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-32" />
              <Skeleton className="h-3 w-24" />
            </div>
          </div>
          <Skeleton className="h-6 w-28" />
        </div>
      ))}
    </div>
  );

  const NoAssetsPlaceholder = () => (
    <div className="flex flex-col items-center justify-center py-16 px-4 text-center">
      <div className="relative mb-6">
        <div className="absolute -inset-4 bg-primary/10 rounded-full blur-sm"></div>
        <div className="relative bg-muted p-5 rounded-full">
          <WalletIcon className="h-10 w-10 text-muted-foreground" />
        </div>
      </div>
      <h3 className="text-xl font-medium mb-2">No assets found</h3>
      <p className="text-muted-foreground max-w-md mb-6">
        {searchTerm ? 'No assets match your search criteria' : 'Get started by depositing your first asset'}
      </p>
      {!searchTerm && (
        <Button 
          onClick={() => navigate('/dashboard/wallet/deposit')} 
          className="bg-primary shadow-sm"
        >
          <Plus className="h-4 w-4 mr-2" />
          Deposit Funds
        </Button>
      )}
    </div>
  );

  const ErrorState = () => (
    <div className="flex flex-col items-center justify-center py-16 px-4 text-center">
      <div className="relative mb-6">
        <div className="absolute -inset-4 bg-red-500/10 rounded-full blur-sm"></div>
        <div className="relative bg-red-100 dark:bg-red-900/20 p-5 rounded-full">
          <AlertCircle className="h-10 w-10 text-red-500 dark:text-red-400" />
        </div>
      </div>
      <h3 className="text-xl font-medium mb-2">Unable to load assets</h3>
      <p className="text-muted-foreground max-w-md mb-6">
        {typeof error === 'string' ? error : 'There was a problem loading your wallet data'}
      </p>
      <Button 
        onClick={handleRefresh} 
        className="bg-primary shadow-sm"
        disabled={isRefreshing}
      >
        {isRefreshing ? (
          <>
            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            Refreshing...
          </>
        ) : (
          'Try Again'
        )}
      </Button>
    </div>
  );

  const QuickStats = () => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
      <Card className="bg-card/50 backdrop-blur-sm">
        <CardHeader className="p-4 sm:p-5">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-xs sm:text-sm text-muted-foreground">Total Balance</p>
              <h3 className="text-lg sm:text-2xl font-bold mt-1">
                {loading ? (
                  <Skeleton className="h-6 sm:h-8 w-24 sm:w-32" />
                ) : (
                  formatCurrency(totalBalance)
                )}
              </h3>
            </div>
            <div className="p-2 rounded-lg bg-primary/10">
              <WalletIcon className="h-4 w-4 sm:h-5 sm:w-5 text-primary" />
            </div>
          </div>
        </CardHeader>
      </Card>

      <Card className="bg-card/50 backdrop-blur-sm">
        <CardHeader className="p-4 sm:p-5">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-xs sm:text-sm text-muted-foreground">24h Change</p>
              <h3 className={cn(
                "text-lg sm:text-2xl font-bold mt-1",
                parseFloat(change24hPercentage) > 0 ? "text-emerald-500" : 
                parseFloat(change24hPercentage) < 0 ? "text-red-500" : "text-muted-foreground"
              )}>
                {loading ? (
                  <Skeleton className="h-6 sm:h-8 w-24 sm:w-32" />
                ) : (
                  `${parseFloat(change24hPercentage) > 0 ? '+' : ''}${change24hPercentage}%`
                )}
              </h3>
            </div>
            <div className={cn(
              "p-2 rounded-lg",
              parseFloat(change24hPercentage) > 0 ? "bg-emerald-500/10" : 
              parseFloat(change24hPercentage) < 0 ? "bg-red-500/10" : "bg-muted"
            )}>
              <TrendingUp className={cn(
                "h-4 w-4 sm:h-5 sm:w-5",
                parseFloat(change24hPercentage) > 0 ? "text-emerald-500" : 
                parseFloat(change24hPercentage) < 0 ? "text-red-500" : "text-muted-foreground"
              )} />
            </div>
          </div>
        </CardHeader>
      </Card>

      <Card className="bg-card/50 backdrop-blur-sm">
        <CardHeader className="p-4 sm:p-5">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-xs sm:text-sm text-muted-foreground">Pending</p>
              <h3 className="text-lg sm:text-2xl font-bold mt-1">
                {loading ? (
                  <Skeleton className="h-6 sm:h-8 w-24 sm:w-32" />
                ) : (
                  formatCurrency(pendingAmount)
                )}
              </h3>
            </div>
            <div className="p-2 rounded-lg bg-amber-500/10">
              <Clock className="h-4 w-4 sm:h-5 sm:w-5 text-amber-500" />
            </div>
          </div>
        </CardHeader>
      </Card>

      <Card className="bg-card/50 backdrop-blur-sm">
        <CardHeader className="p-4 sm:p-5">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-xs sm:text-sm text-muted-foreground">KYC Level</p>
              <h3 className="text-lg sm:text-2xl font-bold mt-1">
                {loading ? (
                  <Skeleton className="h-6 sm:h-8 w-24 sm:w-32" />
                ) : (
                  `Level ${user?.kycLevel ?? 0}`
                )}
              </h3>
              {(user?.kycLevel ?? 0) < 2 && (
                <Badge 
                  variant="secondary" 
                  className="mt-1 sm:mt-2 text-xs bg-blue-500/10 text-blue-500 hover:bg-blue-500/20 cursor-pointer"
                  onClick={() => navigate('/dashboard/profile')}
                >
                  Upgrade
                </Badge>
              )}
            </div>
            <div className="p-2 rounded-lg bg-blue-500/10">
              <Shield className="h-4 w-4 sm:h-5 sm:w-5 text-blue-500" />
            </div>
          </div>
        </CardHeader>
      </Card>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6">
        {/* Enhanced Header Section */}
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-xl bg-primary/10 backdrop-blur-sm">
                <WalletIcon className="h-7 w-7 text-primary" />
              </div>
              <div>
                <h1 className="text-2xl font-bold tracking-tight">Wallet</h1>
                <p className="text-muted-foreground text-sm">Manage your digital assets</p>
              </div>
            </div>
            
            <div className="flex flex-wrap items-center gap-2 w-full sm:w-auto mt-2 sm:mt-0">
              {activeTab === 'assets' && (
                <>
                  <Button 
                    variant="default" 
                    size="default"
                    onClick={() => navigate('/dashboard/wallet/deposit')}
                    className="w-[calc(50%-4px)] sm:w-auto shadow-sm bg-primary/90 hover:bg-primary py-5 sm:py-2"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Deposit
                  </Button>
                  
                  <Button
                    variant="outline"
                    size="default"
                    onClick={() => {
                      // Only navigate if there are assets, using the first non-zero balance asset if possible
                      if (filteredAssets.length > 0) {
                        // Find an asset with a balance if possible
                        const assetWithBalance = filteredAssets.find(a => (a.availableBalance || 0) > 0) || filteredAssets[0];
                        navigate('/dashboard/wallet/withdraw', { state: { asset: assetWithBalance } });
                      } else {
                        // If no assets, just navigate to withdraw page
                        navigate('/dashboard/wallet/withdraw');
                      }
                    }}
                    className="w-[calc(50%-4px)] sm:w-auto py-5 sm:py-2"
                  >
                    <ArrowUp className="h-4 w-4 mr-2" />
                    Withdraw
                  </Button>
                  
                  <div className="w-full mt-2 sm:mt-0 sm:w-auto">
                    <WalletSearch 
                      searchTerm={searchTerm} 
                      setSearchTerm={setSearchTerm} 
                      setAssetType={setAssetType} 
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <QuickStats />
        </div>

        {shouldShowVerificationCard && <VerificationCard />}

        <Tabs defaultValue="assets" className="w-full" onValueChange={setActiveTab}>
          <div className="border-b">
            <TabsList className="w-full bg-transparent p-0 gap-1 overflow-x-auto flex">
              <TabsTrigger 
                value="assets" 
                className={cn(
                  "relative px-4 sm:px-6 py-3 gap-1 sm:gap-2 rounded-none transition-all flex-1 sm:flex-none",
                  "data-[state=active]:bg-transparent data-[state=active]:shadow-none",
                  "data-[state=active]:border-b-2 data-[state=active]:border-primary",
                  "hover:bg-muted/50"
                )}
              >
                <WalletIcon className="h-4 w-4" />
                Assets
                {filteredAssets.length > 0 && (
                  <Badge variant="secondary" className="ml-1 sm:ml-2 px-1.5 py-0.5 text-xs bg-primary/10 text-primary">
                    {filteredAssets.length}
                  </Badge>
                )}
              </TabsTrigger>
              <TabsTrigger 
                value="transactions" 
                className={cn(
                  "relative px-4 sm:px-6 py-3 gap-1 sm:gap-2 rounded-none transition-all flex-1 sm:flex-none",
                  "data-[state=active]:bg-transparent data-[state=active]:shadow-none",
                  "data-[state=active]:border-b-2 data-[state=active]:border-primary",
                  "hover:bg-muted/50"
                )}
              >
                <ArrowDownUp className="h-4 w-4" />
                Transactions
              </TabsTrigger>
            </TabsList>
          </div>
          
          <TabsContent value="assets" className="mt-6">
            <Card className="overflow-hidden border shadow-lg bg-card/50 backdrop-blur-sm">
              <CardHeader className="bg-background/50 px-6 py-5 flex flex-row items-center justify-between border-b">
                <div>
                  <h2 className="text-xl font-semibold">Your Assets</h2>
                  <p className="text-sm text-muted-foreground mt-1">
                    {filteredAssets.length} asset{filteredAssets.length !== 1 ? 's' : ''} found
                  </p>
                </div>
                <Button 
                  variant="ghost" 
                  size="icon"
                  onClick={handleRefresh}
                  className="h-9 w-9 rounded-full hover:bg-muted transition-colors"
                  disabled={isRefreshing}
                >
                  <RefreshCw className={cn("h-4 w-4", isRefreshing && "animate-spin")} />
                </Button>
              </CardHeader>
              <div className="overflow-x-auto">
                {loading ? (
                  <LoadingSkeleton />
                ) : error && !needsVerification ? (
                  <ErrorState />
                ) : filteredAssets.length === 0 && !needsVerification ? (
                  <NoAssetsPlaceholder />
                ) : !needsVerification ? (
                  <AssetTable assets={filteredAssets} />
                ) : (
                  <div className="flex flex-col items-center justify-center p-12 text-center">
                    <div className="relative mb-6">
                      <div className="absolute -inset-4 bg-primary/10 rounded-full blur-sm"></div>
                      <div className="relative p-4 rounded-full bg-primary/5">
                        <Shield className="h-12 w-12 text-primary/80" />
                      </div>
                    </div>
                    <h3 className="text-xl font-medium mb-2">Verification Required</h3>
                    <p className="text-muted-foreground max-w-md mb-6">
                      Complete your profile verification to view and manage your assets
                    </p>
                    <Button 
                      variant="default" 
                      className="bg-primary/90 hover:bg-primary shadow-sm"
                      onClick={() => navigate('/dashboard/verification')}
                    >
                      Complete Verification
                      <ChevronRight className="h-4 w-4 ml-2" />
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </TabsContent>
          
          <TabsContent value="transactions" className="mt-0">
            <Card className="overflow-hidden border rounded-xl shadow-sm">
              <CardHeader className="bg-muted/40 px-6 py-4 border-b flex flex-row items-center justify-between">
                <div>
                  <h2 className="text-lg font-medium">Transaction History</h2>
                  <p className="text-sm text-muted-foreground">
                    All your wallet transactions
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Button 
                    variant="ghost" 
                    size="icon"
                    className="h-8 w-8"
                  >
                    <Filter className="h-4 w-4" />
                  </Button>
                </div>
              </CardHeader>
              <div className="overflow-x-auto">
                {shouldShowVerificationCard ? (
                  <div className="flex flex-col items-center justify-center p-12 text-center">
                    <Shield className="h-12 w-12 text-muted-foreground mb-4" />
                    <span className="text-muted-foreground">
                      Complete verification to view your transactions
                    </span>
                    <Button 
                      variant="default" 
                      className="mt-4 shadow-sm"
                      onClick={() => navigate('/dashboard/verification')}
                    >
                      Complete Verification
                    </Button>
                  </div>
                ) : (
                  <TransactionList />
                )}
              </div>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default Wallet;
