import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { useWallet } from '@/contexts/WalletContext';
import { 
  Loader2, PieChart, BarChart, ArrowRight, TrendingUp, 
  AlertCircle, Wallet, ExternalLink
} from 'lucide-react';
import apiClient from '@/lib/apiClient';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';
import { cn } from '@/lib/utils';

// Types remain the same
interface PortfolioData {
  totalValue: number;
  performance: {
    daily: number;
    weekly: number;
    monthly: number;
    quarterly: number;
    yearly: number;
  };
  distribution: Array<{
    asset: string;
    value: number;
    percentage: number;
  }>;
  analytics?: {
    volatility: number;
    sharpeRatio: number;
  };
}

interface HistoricalData {
  timestamp: number;
  value: number;
}

const Portfolio = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const { assets, loading: isLoadingWallet } = useWallet();

  // Query hooks remain the same
  const { data: portfolioData, isLoading: isLoadingPortfolio } = useQuery<PortfolioData>({
    queryKey: ['portfolio-data'],
    queryFn: async () => {
      const response = await apiClient.get('/portfolio/summary');
      return response.data;
    },
    staleTime: 60000,
  });

  const { data: historicalData, isLoading: isLoadingHistory } = useQuery<HistoricalData[]>({
    queryKey: ['portfolio-history'],
    queryFn: async () => {
      const response = await apiClient.get('/portfolio/history');
      return response.data;
    },
    staleTime: 300000,
  });

  const totalPortfolioValue = assets?.reduce((sum, asset) => 
    sum + parseFloat(String(asset.availableBalance)), 0
  )?.toFixed(2) || '0.00';

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  if (isLoadingWallet || isLoadingPortfolio || isLoadingHistory) {
    return (
      <DashboardLayout>
        <div className="flex flex-col items-center justify-center h-[40vh] gap-3">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <p className="text-muted-foreground text-sm">Loading portfolio data...</p>
        </div>
      </DashboardLayout>
    );
  }

  const performance = portfolioData?.performance || {
    daily: 0,
    weekly: 0,
    monthly: 0,
    quarterly: 0,
    yearly: 0
  };

  const distribution = portfolioData?.distribution || [];
  const analytics = portfolioData?.analytics || { volatility: 0, sharpeRatio: 0 };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-4 sm:space-y-6">
        {/* Mobile-optimized hero section */}
        <section>
          <div className="rounded-xl bg-gradient-to-r from-primary/15 via-primary/10 to-background p-4 sm:p-8 backdrop-blur-sm border shadow-sm">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-6">
              <div className="space-y-1 sm:space-y-2">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                    <PieChart className="h-4 w-4 text-primary" />
                  </div>
                  <h1 className="text-xl sm:text-2xl font-bold tracking-tight">Portfolio</h1>
                </div>
                <p className="text-xs sm:text-sm text-muted-foreground">
                  Track and analyze your investment performance
                </p>
              </div>
              <Button 
                onClick={() => navigate('/dashboard/wallet')}
                className="w-full sm:w-auto py-2 h-10 touch-manipulation"
              >
                View Wallet <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        </section>

        {/* Mobile-friendly tabs */}
        <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className="space-y-4">
          <div className="border-b overflow-x-auto scrollbar-hide">
            <TabsList className="w-full bg-transparent space-x-2 px-0.5 py-1">
              <TabsTrigger 
                value="overview" 
                className="flex-1 sm:flex-none py-2 px-3 sm:px-4 data-[state=active]:border-b-2 data-[state=active]:border-primary rounded-none data-[state=active]:shadow-none"
              >
                Overview
              </TabsTrigger>
              <TabsTrigger 
                value="performance" 
                className="flex-1 sm:flex-none py-2 px-3 sm:px-4 data-[state=active]:border-b-2 data-[state=active]:border-primary rounded-none data-[state=active]:shadow-none"
              >
                Performance
              </TabsTrigger>
              <TabsTrigger 
                value="analytics" 
                className="flex-1 sm:flex-none py-2 px-3 sm:px-4 data-[state=active]:border-b-2 data-[state=active]:border-primary rounded-none data-[state=active]:shadow-none"
              >
                Analytics
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value="overview" className="space-y-4 mt-0">
            {/* Mobile optimized portfolio metrics */}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4">
              {/* Total Value Card */}
              <Card className="touch-manipulation">
                <CardContent className="pt-4 sm:pt-6 px-3 sm:px-6 pb-3 sm:pb-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <p className="text-xs sm:text-sm text-muted-foreground">Total Value</p>
                      <p className="text-lg sm:text-2xl font-bold">${totalPortfolioValue}</p>
                    </div>
                    <div className="p-2 bg-primary/10 rounded-full">
                      <Wallet className="h-4 w-4 text-primary" />
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* 24h Change Card */}
              <Card className="touch-manipulation">
                <CardContent className="pt-4 sm:pt-6 px-3 sm:px-6 pb-3 sm:pb-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <p className="text-xs sm:text-sm text-muted-foreground">24h Change</p>
                      <p className={`text-lg sm:text-2xl font-bold ${performance.daily >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                        {performance.daily >= 0 ? '+' : ''}{performance.daily.toFixed(2)}%
                      </p>
                    </div>
                    <div className={`p-2 rounded-full ${performance.daily >= 0 ? 'bg-green-500/10' : 'bg-red-500/10'}`}>
                      <TrendingUp className={`h-4 w-4 ${performance.daily >= 0 ? 'text-green-500' : 'text-red-500'}`} />
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Assets Count Card */}
              <Card className="touch-manipulation">
                <CardContent className="pt-4 sm:pt-6 px-3 sm:px-6 pb-3 sm:pb-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <p className="text-xs sm:text-sm text-muted-foreground">Assets</p>
                      <p className="text-lg sm:text-2xl font-bold">{assets?.length || 0}</p>
                    </div>
                    <div className="p-2 bg-blue-500/10 rounded-full">
                      <BarChart className="h-4 w-4 text-blue-500" />
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Risk Level Card */}
              <Card className="touch-manipulation">
                <CardContent className="pt-4 sm:pt-6 px-3 sm:px-6 pb-3 sm:pb-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <p className="text-xs sm:text-sm text-muted-foreground">Risk Level</p>
                      <p className="text-lg sm:text-2xl font-bold">Moderate</p>
                    </div>
                    <div className="p-2 bg-blue-500/10 rounded-full">
                      <AlertCircle className="h-4 w-4 text-blue-500" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Asset Distribution - Mobile optimized pie chart */}
            {distribution.length > 0 && (
              <Card>
                <CardHeader className="py-3 sm:py-4 px-3 sm:px-6">
                  <CardTitle className="text-base sm:text-lg">Asset Distribution</CardTitle>
                </CardHeader>
                <CardContent className="px-1 sm:px-6 pb-3 sm:pb-6">
                  <div className="h-[280px] sm:h-[300px] -mx-2 px-2">
                    <ResponsiveContainer width="100%" height="100%">
                      <RechartsPieChart>
                        <Pie
                          data={distribution}
                          dataKey="value"
                          nameKey="asset"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          innerRadius={40}
                          labelLine={false}
                          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                        >
                          {distribution.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value) => [`$${value.toFixed(2)}`, 'Value']} />
                        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                      </RechartsPieChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            )}

            {/* Asset List - Mobile optimized */}
            {assets && assets.length > 0 && (
              <div className="space-y-2 sm:space-y-3 mt-4 sm:mt-6">
                <h3 className="text-sm sm:text-base font-medium px-1">Your Assets</h3>
                {assets.map((asset) => (
                  <div 
                    key={asset.symbol} 
                    className="flex items-center justify-between p-3 sm:p-4 border rounded-lg touch-manipulation hover:bg-muted/50 transition-colors"
                    onClick={() => navigate(`/dashboard/asset/${asset.symbol}`)}
                  >
                    <div className="flex items-center gap-3 sm:gap-4">
                      <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-primary/10 flex items-center justify-center">
                        <img 
                          src={asset.icon} 
                          alt={asset.symbol}
                          className="h-5 w-5 sm:h-6 sm:w-6"
                          onError={(e) => {
                            e.currentTarget.src = '/fallback-icon.png';
                          }}
                        />
                      </div>
                      <div>
                        <p className="font-medium text-sm sm:text-base">{asset.name}</p>
                        <p className="text-xs sm:text-sm text-muted-foreground">
                          {asset.availableBalance} {asset.symbol}
                        </p>
                      </div>
                    </div>
                    <div className="text-right flex items-center gap-2">
                      <div>
                        <p className="font-medium text-sm sm:text-base">
                          ${parseFloat(String(asset.availableBalance)).toFixed(2)}
                        </p>
                        <p className={cn(
                          "text-xs flex items-center justify-end",
                          parseFloat(asset.change24h || "0") > 0 ? "text-green-500" : "text-red-500"
                        )}>
                          <TrendingUp className="h-3 w-3 mr-1" />
                          {parseFloat(asset.change24h || "0") > 0 ? "+" : ""}
                          {asset.change24h || "0.00"}%
                        </p>
                      </div>
                      <ExternalLink className="h-4 w-4 text-muted-foreground opacity-50" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </TabsContent>

          {/* Performance Tab Content - Mobile optimized */}
          <TabsContent value="performance" className="space-y-4 mt-0">
            {/* Time Range Selector (Mobile-friendly) */}
            <div className="flex overflow-x-auto scrollbar-hide -mx-1 px-1 pb-2 gap-2">
              {["1D", "1W", "1M", "3M", "1Y", "ALL"].map((range) => (
                <Button
                  key={range}
                  variant={range === "1M" ? "default" : "outline"}
                  size="sm"
                  className="min-w-[60px] h-8 text-xs font-medium"
                >
                  {range}
                </Button>
              ))}
            </div>
            
            {historicalData && Array.isArray(historicalData) && historicalData.length > 0 ? (
              <Card>
                <CardHeader className="py-3 sm:py-4 px-3 sm:px-6">
                  <CardTitle className="text-base sm:text-lg">Performance History</CardTitle>
                </CardHeader>
                <CardContent className="px-1 sm:px-6 pb-3 sm:pb-6">
                  <div className="h-[300px] sm:h-[400px] -mx-2 px-2">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={historicalData}
                        margin={{ top: 5, right: 10, left: 5, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#888" opacity={0.1} />
                        <XAxis
                          dataKey="timestamp"
                          tickFormatter={(timestamp) => {
                            // Simplified date format for mobile
                            const date = new Date(timestamp);
                            return `${date.getDate()}/${date.getMonth() + 1}`;
                          }}
                          tick={{ fontSize: 12 }}
                          axisLine={{ stroke: '#888', opacity: 0.1 }}
                          tickLine={{ stroke: '#888', opacity: 0.1 }}
                        />
                        <YAxis
                          tick={{ fontSize: 12 }}
                          axisLine={{ stroke: '#888', opacity: 0.1 }}
                          tickLine={{ stroke: '#888', opacity: 0.1 }}
                          tickFormatter={(value) => `$${value.toLocaleString()}`}
                        />
                        <Tooltip
                          labelFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                          formatter={(value) => [`$${parseFloat(String(value)).toLocaleString()}`, 'Value']}
                          contentStyle={{ fontSize: '12px', borderRadius: '8px', border: '1px solid #eee' }}
                        />
                        <Line
                          type="monotone"
                          dataKey="value"
                          stroke="#8884d8"
                          strokeWidth={2}
                          dot={false}
                          activeDot={{ r: 6 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card>
                <CardContent className="py-6 sm:py-8 flex flex-col items-center justify-center text-center gap-2">
                  <AlertCircle className="h-6 w-6 sm:h-8 sm:w-8 text-muted-foreground" />
                  <p className="text-sm text-muted-foreground">No historical data available</p>
                </CardContent>
              </Card>
            )}
            
            {/* Key Performance Metrics */}
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4">
              {[
                { label: 'Monthly', value: `${performance.monthly.toFixed(2)}%` },
                { label: 'Quarterly', value: `${performance.quarterly.toFixed(2)}%` },
                { label: 'Half-yearly', value: `${(performance.quarterly * 2).toFixed(2)}%` },
                { label: 'Yearly', value: `${performance.yearly.toFixed(2)}%` }
              ].map((metric, i) => (
                <Card key={i} className="touch-manipulation">
                  <CardContent className="pt-3 sm:pt-4 px-3 sm:px-4 pb-3 sm:pb-4">
                    <p className="text-xs sm:text-sm text-muted-foreground">{metric.label}</p>
                    <p className={cn(
                      "text-lg sm:text-xl font-bold mt-1",
                      parseFloat(metric.value) >= 0 ? "text-green-500" : "text-red-500"
                    )}>
                      {parseFloat(metric.value) >= 0 ? "+" : ""}{metric.value}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </TabsContent>

          {/* Analytics Tab Content - Mobile optimized */}
          <TabsContent value="analytics" className="space-y-4 mt-0">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
              {/* Risk Metrics - Mobile friendly cards */}
              <div className="grid grid-cols-2 gap-3">
                <Card className="touch-manipulation">
                  <CardContent className="pt-3 sm:pt-4 px-3 sm:px-4 pb-3 sm:pb-4">
                    <p className="text-xs sm:text-sm text-muted-foreground">Volatility</p>
                    <p className="text-lg sm:text-xl font-bold mt-1">
                      {analytics.volatility.toFixed(2)}%
                    </p>
                    <p className="text-xs text-muted-foreground mt-1">30-day</p>
                  </CardContent>
                </Card>
                
                <Card className="touch-manipulation">
                  <CardContent className="pt-3 sm:pt-4 px-3 sm:px-4 pb-3 sm:pb-4">
                    <p className="text-xs sm:text-sm text-muted-foreground">Sharpe Ratio</p>
                    <p className="text-lg sm:text-xl font-bold mt-1">
                      {analytics.sharpeRatio.toFixed(2)}
                    </p>
                    <p className="text-xs text-muted-foreground mt-1">Risk-adjusted return</p>
                  </CardContent>
                </Card>
                
                <Card className="touch-manipulation">
                  <CardContent className="pt-3 sm:pt-4 px-3 sm:px-4 pb-3 sm:pb-4">
                    <p className="text-xs sm:text-sm text-muted-foreground">Max Drawdown</p>
                    <p className="text-lg sm:text-xl font-bold mt-1 text-red-500">
                      -6.28%
                    </p>
                    <p className="text-xs text-muted-foreground mt-1">Last 30 days</p>
                  </CardContent>
                </Card>
                
                <Card className="touch-manipulation">
                  <CardContent className="pt-3 sm:pt-4 px-3 sm:px-4 pb-3 sm:pb-4">
                    <p className="text-xs sm:text-sm text-muted-foreground">Beta</p>
                    <p className="text-lg sm:text-xl font-bold mt-1">
                      0.85
                    </p>
                    <p className="text-xs text-muted-foreground mt-1">vs. Market</p>
                  </CardContent>
                </Card>
              </div>

              {/* Risk Assessment Card */}
              <Card>
                <CardHeader className="py-3 sm:py-4 px-3 sm:px-6">
                  <CardTitle className="text-base sm:text-lg">Risk Assessment</CardTitle>
                </CardHeader>
                <CardContent className="px-3 sm:px-6 pb-3 sm:pb-6">
                  <div className="space-y-4">
                    <div>
                      <div className="flex justify-between items-center mb-1">
                        <p className="text-xs sm:text-sm">Overall Risk</p>
                        <p className="text-xs font-medium text-amber-500">Moderate</p>
                      </div>
                      <div className="h-2 bg-muted rounded-full overflow-hidden">
                        <div className="h-full bg-amber-500" style={{ width: "55%" }}></div>
                      </div>
                    </div>
                    
                    <div>
                      <div className="flex justify-between items-center mb-1">
                        <p className="text-xs sm:text-sm">Diversity Score</p>
                        <p className="text-xs font-medium text-blue-500">Good</p>
                      </div>
                      <div className="h-2 bg-muted rounded-full overflow-hidden">
                        <div className="h-full bg-blue-500" style={{ width: "70%" }}></div>
                      </div>
                    </div>
                    
                    <div>
                      <div className="flex justify-between items-center mb-1">
                        <p className="text-xs sm:text-sm">Market Exposure</p>
                        <p className="text-xs font-medium text-green-500">Low</p>
                      </div>
                      <div className="h-2 bg-muted rounded-full overflow-hidden">
                        <div className="h-full bg-green-500" style={{ width: "30%" }}></div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default Portfolio;
