import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { CryptoNews } from '@/components/dashboard/CryptoNews';

const NewsPage = () => {
  return (
    <DashboardLayout>
      <div className="container px-3 py-4 sm:px-4 sm:py-6 space-y-4 sm:space-y-6">
        <div className="flex flex-col gap-1 mb-1 sm:mb-2">
          <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Crypto News</h1>
          <p className="text-sm text-muted-foreground">
            Stay updated with the latest news and sentiment from the crypto world
          </p>
        </div>
        <CryptoNews />
      </div>
    </DashboardLayout>
  );
};

export default NewsPage;