import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Separator } from '@/components/ui/separator';
import { AlertCircle, ArrowLeft, Camera, Check, ExternalLink, Loader2, Upload } from 'lucide-react';
import { toast } from 'sonner';
import { useUser } from '@/contexts/UserContext';
import { KYCLevel } from './KYCVerification';
import { CountrySelect } from '@/components/ui/country-select';

// Types for the KYC forms
interface KYCFormsProps {
  isOpen: boolean;
  onClose: () => void;
  currentLevel: KYCLevel;
  onVerificationComplete: () => void;
}

// Form schemas
const personalInfoSchema = z.object({
  legalName: z.string().min(2, "Full name must be at least 2 characters"),
  dateOfBirth: z.string().min(1, "Date of birth is required"),
  phoneNumber: z.string().min(5, "Phone number is required"),
  country: z.string().min(1, "Country is required"),
});

const otpVerificationSchema = z.object({
  otp: z.string().length(6, "OTP must be 6 digits"),
});

const identityVerificationSchema = z.object({
  documentType: z.enum(["passport", "idCard"]),
});

const addressVerificationSchema = z.object({
  documentType: z.string().min(1, "Document type is required"),
});

// OTP Verification Form Component
const OTPVerification = ({
  phoneNumber,
  onVerify,
  onBack
}: {
  phoneNumber: string;
  onVerify: (otp: string) => void;
  onBack: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { kycResendPhoneOTP } = useUser();
  const [remainingTime, setRemainingTime] = useState(0);
  const [retryCount, setRetryCount] = useState(0);

  // Fix the type error by specifying the correct type for otp
  const form = useForm<{ otp: string }>({
    resolver: zodResolver(otpVerificationSchema),
    defaultValues: {
      otp: '',
    },
  });

  const onSubmit = async (values: { otp: string }) => {
    setIsLoading(true);
    try {
      await onVerify(values.otp);
    } catch (error) {
      console.error('OTP verification failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (retryCount >= 5) {
      toast.error('Maximum retry attempts reached. Please try again later.');
      return;
    }

    setTimeout(async () => {
      setRemainingTime(30);
      setRetryCount(prev => prev + 1);

      // Start countdown
      const interval = setInterval(() => {
        setRemainingTime(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);


      setIsLoading(true);
      try {
        const isVerified = await kycResendPhoneOTP();
        if (isVerified) {
        }
      } catch (error) {
        console.error('Failed to verify phone number:', error);
      } finally {
        setIsLoading(false);
        setRetryCount((prev) => prev + 1);
      }
    }, 1000);




  };

  return (
    <div className="space-y-4">
      <Button
        variant="ghost"
        className="p-0 mb-2"
        onClick={onBack}
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back
      </Button>

      <div className="text-center mb-4">
        <h3 className="text-lg font-medium">Verify Your Phone Number</h3>
        <p className="text-sm text-muted-foreground">
          We've sent a 6-digit code to {phoneNumber}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Enter 6-digit code</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Enter verification code"
                    maxLength={6}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-col space-y-2">
            <Button
              type="submit"
              disabled={isLoading || !form.formState.isValid}
            >
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Verify Code
            </Button>

            <div className="text-center mt-2">
              <Button
                type="button"
                variant="link"
                onClick={handleResendOTP}
                disabled={isLoading || remainingTime > 0 || retryCount >= 5}
              >
                {remainingTime > 0 ? (
                  `Resend code in ${remainingTime}s`
                ) : retryCount >= 5 ? (
                  "Maximum retry attempts reached"
                ) : (
                  "Resend code"
                )}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

// Main KYC Forms Component
const KYCForms = ({ isOpen, onClose, currentLevel, onVerificationComplete }: KYCFormsProps) => {
  const { user, kycLevel2, updateUserKYC, kycPhoneVerify, updateUserKycLevel2 } = useUser();
  const [isLoadingLevel2, setIsLoadingLevel2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState<'form' | 'otp'>('form');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState('');
  const [identityTab, setIdentityTab] = useState<'passport' | 'idCard'>('passport');
  const [uploadedFiles, setUploadedFiles] = useState<{
    passport?: File | null;
    idCardFront?: File | null;
    idCardBack?: File | null;
    addressDocument?: File | null;
  }>({});


  useEffect(() => {
    const fetchKycLevel2 = async () => {
      setIsLoadingLevel2(true);
      try {
        const response = await updateUserKycLevel2();
        if (response) {
          console.debug('KYC Level 2 response:', kycLevel2?.url);
        }
      } catch (error) {
        console.error('Error fetching KYC Level 2:', error);
      } finally {
        setIsLoadingLevel2(false);
      }
    };

     // Only fetch KYC Level 2 if the form is open and the user is on Level 1
  if (isOpen && currentLevel === 1) {
    fetchKycLevel2();
  }
}, [isOpen, currentLevel]);

  // Form for Level 0 - Personal Information
  const personalInfoForm = useForm<z.infer<typeof personalInfoSchema>>({
    resolver: zodResolver(personalInfoSchema),
    defaultValues: {
      legalName: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '',
      dateOfBirth: '',
      phoneNumber: user?.phoneNumber ? user.phoneNumber.replace(/^\+\d+/, '') : '', // Remove dial code if exists
      country: user?.country || '',
    },
  });

  // Form for Level 1 - Identity Verification
  const identityForm = useForm<z.infer<typeof identityVerificationSchema>>({
    resolver: zodResolver(identityVerificationSchema),
    defaultValues: {
      documentType: 'passport',
    },
  });

  // Form for Level 2 - Address Verification
  const addressForm = useForm<z.infer<typeof addressVerificationSchema>>({
    resolver: zodResolver(addressVerificationSchema),
    defaultValues: {
      documentType: '',
    },
  });

  // Handle OTP Verification
  const handleOTPVerification = async (otp: string) => {
    setIsSubmitting(true);

    try {
      const isVerified = await kycPhoneVerify({ otp });
      if (isVerified) {
        onVerificationComplete();
        onClose();
      }
    } catch (error) {
      console.error('Failed to verify phone number:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle country selection
  const handleCountryChange = (selectedCountry: { name: string; dial_code: string }) => {
    personalInfoForm.setValue('country', selectedCountry.name);
    setDialCode(selectedCountry.dial_code);
  };

  // Handle Personal Info Submission
  const onPersonalInfoSubmit = async (values: z.infer<typeof personalInfoSchema>) => {
    setIsSubmitting(true);

    try {
      // Format phone number with dial code
      const formattedPhoneNumber = `${dialCode}${values.phoneNumber.replace(/^0+/, '')}`;

      // Call the KYC step1 endpoint
      const updated = await updateUserKYC({
        country: values.country,
        phoneNumber: formattedPhoneNumber,
        dateOfBirth: values.dateOfBirth,
      });

      if (updated) {
        // Move to OTP verification step
        setPhoneNumber(formattedPhoneNumber);
        setCurrentStep('otp');
        toast.success('Basic information updated successfully');
      }

    } catch (error) {
      console.error('Failed to submit personal information:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle Identity Verification Submission
  const onIdentityVerificationSubmit = async (values: z.infer<typeof identityVerificationSchema>) => {
    setIsSubmitting(true);

    try {
      // Check if required files are uploaded
      if (values.documentType === 'passport' && !uploadedFiles.passport) {
        toast.error('Please upload your passport');
        setIsSubmitting(false);
        return;
      }

      if (values.documentType === 'idCard' && (!uploadedFiles.idCardFront || !uploadedFiles.idCardBack)) {
        toast.error('Please upload both front and back of your ID card');
        setIsSubmitting(false);
        return;
      }

      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Update user KYC level

      toast.success('Identity documents submitted successfully');
      onVerificationComplete();
      onClose();
    } catch (error) {
      console.error('Failed to submit identity verification:', error);
      toast.error('Failed to submit identity verification. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle Address Verification Submission
  const onAddressVerificationSubmit = async (values: z.infer<typeof addressVerificationSchema>) => {
    setIsSubmitting(true);

    try {
      // Check if address document is uploaded
      if (!uploadedFiles.addressDocument) {
        toast.error('Please upload your address document');
        setIsSubmitting(false);
        return;
      }

      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Update user KYC level

      toast.success('Address verification submitted successfully');
      onVerificationComplete();
      onClose();
    } catch (error) {
      console.error('Failed to submit address verification:', error);
      toast.error('Failed to submit address verification. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle file upload
  const handleFileUpload = (fileType: keyof typeof uploadedFiles, file: File) => {
    setUploadedFiles(prev => ({
      ...prev,
      [fileType]: file,
    }));
  };

  // Reset state when dialog closes
  const handleDialogClose = () => {
    setCurrentStep('form');
    setIdentityTab('passport');
    setUploadedFiles({});
    personalInfoForm.reset();
    identityForm.reset();
    addressForm.reset();
    onClose();
  };

  // File upload component
  const FileUpload = ({
    fileType,
    label
  }: {
    fileType: keyof typeof uploadedFiles;
    label: string;
  }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFileUpload(fileType, e.dataTransfer.files[0]);
      }
    };

    return (
      <div className="space-y-2">
        <p className="text-sm font-medium">{label}</p>
        <div
          className={`border-2 border-dashed rounded-lg p-6 text-center hover:bg-muted/30 transition-colors cursor-pointer ${isDragging ? 'border-primary bg-muted/50' : 'border-muted'
            } ${uploadedFiles[fileType] ? 'bg-muted/20' : ''}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById(`${fileType}-upload`)?.click()}
        >
          <input
            id={`${fileType}-upload`}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                handleFileUpload(fileType, e.target.files[0]);
              }
            }}
          />

          {uploadedFiles[fileType] ? (
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="w-full h-32 relative">
                <img
                  src={URL.createObjectURL(uploadedFiles[fileType] as File)}
                  alt="Uploaded document"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex items-center text-sm text-muted-foreground">
                <Check className="mr-1 h-4 w-4 text-green-500" />
                {uploadedFiles[fileType]?.name}
              </div>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setUploadedFiles(prev => ({
                    ...prev,
                    [fileType]: null,
                  }));
                }}
              >
                Replace
              </Button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-2">
              <Upload className="h-10 w-10 text-muted-foreground mb-2" />
              <p className="text-sm font-medium">
                Drag & drop or click to upload
              </p>
              <p className="text-xs text-muted-foreground">
                Supported formats: JPG, PNG, PDF
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Render the appropriate form based on the current KYC level
  const renderForm = () => {
    // OTP Verification Screen
    if (currentStep === 'otp'|| (user.phoneNumber?.length > 0 && user.kycLevel === 0)) {
      return (
        <OTPVerification
          phoneNumber={phoneNumber}
          onVerify={handleOTPVerification}
          onBack={() => setCurrentStep('form')}
        />
      );
    }

    // Level 0 - Personal Information Form
    if (currentLevel === 0) {
      return (
        <Form {...personalInfoForm}>
          <form onSubmit={personalInfoForm.handleSubmit(onPersonalInfoSubmit)} className="space-y-4">
            <FormField
              control={personalInfoForm.control}
              name="legalName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Legal Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter your full legal name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={personalInfoForm.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Country</FormLabel>
                  <FormControl>
                    <CountrySelect
                      id="country"
                      value={field.value}
                      onChange={handleCountryChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={personalInfoForm.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <div className="flex items-center space-x-2">
                    <div className="rounded-md border border-input bg-background px-3 py-2 text-sm w-auto h-10 flex items-center">
                      {dialCode || '+XX'}
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        type="tel"
                        placeholder="Enter number without leading zeros"
                        className="flex-1"
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={personalInfoForm.control}
              name="dateOfBirth"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date of Birth</FormLabel>
                  <FormControl>
                    <Input {...field} type="date" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full"
              disabled={isSubmitting || !personalInfoForm.formState.isValid}
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Submitting...
                </>
              ) : (
                'Continue'
              )}
            </Button>
          </form>
        </Form>
      );
    }

    // Level 1 - Identity Verification Form
    if (currentLevel === 1) {
      return (
        <div className="max-w-md mx-auto border border-border rounded-2xl shadow p-6 bg-background">
          <h2 className="text-xl font-semibold mb-4 text-center">KYC Level 2</h2>
    
          {isLoadingLevel2 ? (
            <div className="flex flex-col items-center space-y-2">
              <Loader2 className="h-6 w-6 animate-spin text-primary" />
              <p className="text-sm text-muted-foreground">Loading verification, please wait...</p>
            </div>
          ) : kycLevel2?.url ? (
            <div className="text-center space-y-4">
              <p className="text-muted-foreground">
                To complete your identity verification, click the link below:
              </p>
              <a
                href={kycLevel2.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                Click here to start the verification
              </a>
            </div>
          ) : (
            <Form {...identityForm}>
              <form onSubmit={identityForm.handleSubmit(onIdentityVerificationSubmit)} className="space-y-4">
                <FormField
                  control={identityForm.control}
                  name="documentType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Document Type</FormLabel>
                      <FormControl>
                        <Tabs
                          defaultValue="passport"
                          className="w-full"
                          value={field.value}
                          onValueChange={(value) => {
                            field.onChange(value);
                            setIdentityTab(value as 'passport' | 'idCard');
                          }}
                        >
                          <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger value="passport">Passport</TabsTrigger>
                            <TabsTrigger value="idCard">ID Card</TabsTrigger>
                          </TabsList>
    
                          <TabsContent value="passport" className="pt-4">
                            <FileUpload
                              fileType="passport"
                              label="Upload Passport Photo Page"
                            />
                          </TabsContent>
    
                          <TabsContent value="idCard" className="pt-4 space-y-4">
                            <FileUpload
                              fileType="idCardFront"
                              label="Upload ID Card (Front)"
                            />
                            <FileUpload
                              fileType="idCardBack"
                              label="Upload ID Card (Back)"
                            />
                          </TabsContent>
                        </Tabs>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
    
                <div className="pt-4">
                  <Button
                    type="submit"
                    className="w-full"
                    disabled={
                      isSubmitting ||
                      (identityTab === 'passport' && !uploadedFiles.passport) ||
                      (identityTab === 'idCard' && (!uploadedFiles.idCardFront || !uploadedFiles.idCardBack))
                    }
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Submitting...
                      </>
                    ) : (
                      'Submit Documents'
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </div>
      );
    }
    

    // Level 2 - Address Verification Form
    if (currentLevel === 2) {
      return (
        <Form {...addressForm}>
          <form onSubmit={addressForm.handleSubmit(onAddressVerificationSubmit)} className="space-y-4">
            <div className="rounded-md bg-muted p-4 mb-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-muted-foreground mr-2" />
                <div className="text-sm text-muted-foreground">
                  <p className="font-medium">Address Verification Documents</p>
                  <p className="mt-1">
                    Please upload a document that clearly shows your name and current residential address.
                    The document must be less than 3 months old.
                  </p>
                </div>
              </div>
            </div>

            <FormField
              control={addressForm.control}
              name="documentType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Document Type</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select document type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="utilityBill">Utility Bill (Electric, Water, Gas)</SelectItem>
                      <SelectItem value="bankStatement">Bank Statement</SelectItem>
                      <SelectItem value="taxDocument">Tax Document</SelectItem>
                      <SelectItem value="rentalAgreement">Rental Agreement</SelectItem>
                      <SelectItem value="insuranceDocument">Insurance Document</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="pt-2">
              <FileUpload
                fileType="addressDocument"
                label="Upload Address Document"
              />
            </div>

            <div className="pt-4">
              <Button
                type="submit"
                className="w-full"
                disabled={
                  isSubmitting ||
                  !addressForm.formState.isValid ||
                  !uploadedFiles.addressDocument
                }
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Submitting...
                  </>
                ) : (
                  'Submit Document'
                )}
              </Button>
            </div>
          </form>
        </Form>
      );
    }

    return null;
  };

  // Dialog title based on current KYC level
  const getDialogTitle = () => {
    if (currentStep === 'otp') {
      return 'Verify Phone Number';
    }

    switch (currentLevel) {
      case 0: return 'Basic Information Verification';
      case 1: return 'Identity Verification';
      case 2: return 'Address Verification';
      default: return 'KYC Verification';
    }
  };

  // Dialog description based on current KYC level
  const getDialogDescription = () => {
    if (currentStep === 'otp') {
      return 'Enter the verification code sent to your phone';
    }

    switch (currentLevel) {
      case 0: return 'Please provide your basic information to start the verification process';
      case 1: return 'Please upload a valid government-issued ID to verify your identity';
      case 2: return 'Please upload a document to verify your residential address';
      default: return '';
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>{getDialogTitle()}</DialogTitle>
          <DialogDescription>{getDialogDescription()}</DialogDescription>
        </DialogHeader>
        <Separator />
        <div className="py-2">
          {renderForm()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default KYCForms;