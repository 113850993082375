// Notifications.tsx
import { useEffect, useState, useRef, useCallback } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { useNotification } from '@/contexts/NotificationContext';
import { useUser } from '@/contexts/UserContext';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { 
  Bell, 
  CheckCircle, 
  Trash2, 
  AlertTriangle, 
  Info,
  Clock,
  ChevronDown,
  BellOff
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

const Notifications = () => {
  const { 
    notifications, 
    loading, 
    error, 
    fetchNotifications, 
    markAsRead, 
    deleteNotification, 
    deleteAllNotifications 
  } = useNotification();
  const { user } = useUser();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isFetching = useRef(false);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?._id || error != null || isFetching.current) return;
      
      isFetching.current = true;
      try {
        const newNotifications = await fetchNotifications(page);
        if (newNotifications.length === 0) {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        isFetching.current = false;
      }
    };

    fetchData();
  }, [user, page]);

  const lastNotificationElementRef = useCallback(
    (node) => {
      if (!node || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      observer.current.observe(node);
    },
    [hasMore, loading]
  );

  const getNotificationIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'warning':
        return <AlertTriangle className="h-5 w-5 text-warning" />;
      case 'error':
        return <AlertTriangle className="h-5 w-5 text-destructive" />;
      case 'info':
        return <Info className="h-5 w-5 text-info" />;
      default:
        return <Bell className="h-5 w-5 text-primary" />;
    }
  };

  const getNotificationStyle = (type: string) => {
    switch (type.toLowerCase()) {
      case 'warning':
        return 'border-warning/20 bg-warning/5 hover:bg-warning/10';
      case 'error':
        return 'border-destructive/20 bg-destructive/5 hover:bg-destructive/10';
      case 'info':
        return 'border-info/20 bg-info/5 hover:bg-info/10';
      default:
        return 'border-primary/20 bg-primary/5 hover:bg-primary/10';
    }
  };

  return (
    <DashboardLayout>
      <div className="container max-w-4xl py-6 space-y-6">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <div className="space-y-1">
            <h1 className="text-3xl font-bold tracking-tight">
              Notifications
              {notifications.length > 0 && (
                <Badge variant="secondary" className="ml-2 -translate-y-1">
                  {notifications.length}
                </Badge>
              )}
            </h1>
            <p className="text-muted-foreground">
              Stay updated with your latest activities
            </p>
          </div>
          {notifications.length > 0 && (
            <Button
              variant="destructive"
              size="sm"
              onClick={deleteAllNotifications}
              className="group hover:scale-105 transition-transform duration-200"
            >
              <Trash2 className="h-4 w-4 mr-2 group-hover:animate-shake" />
              Clear All
            </Button>
          )}
        </div>

        <Card className="border-2">
          <CardHeader className="pb-3">
            <CardTitle className="flex items-center gap-2">
              <Bell className="h-5 w-5 text-primary" />
              Recent Notifications
            </CardTitle>
            <Separator className="mt-2" />
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[600px] pr-4">
              <AnimatePresence mode="popLayout">
                {notifications.length > 0 ? (
                  <div className="space-y-4">
                    {notifications.map((notification, index) => (
                      <motion.div
                        key={notification._id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.2 }}
                        ref={index === notifications.length - 1 ? lastNotificationElementRef : null}
                        className={cn(
                          "rounded-lg border-2 p-4 transition-all duration-200",
                          getNotificationStyle(notification.type),
                          !notification.read && "border-l-4 shadow-md"
                        )}
                      >
                        <div className="flex items-start justify-between gap-4">
                          <div className="flex items-start gap-4">
                            <div className="mt-1">
                              {getNotificationIcon(notification.type)}
                            </div>
                            <div className="space-y-2 flex-1">
                              <div className="flex flex-wrap items-center gap-2">
                                <Badge 
                                  variant="secondary" 
                                  className={cn(
                                    "font-medium",
                                    notification.type.toLowerCase() === 'warning' && "bg-warning/10 text-warning border-warning/20",
                                    notification.type.toLowerCase() === 'error' && "bg-destructive/10 text-destructive border-destructive/20",
                                    notification.type.toLowerCase() === 'info' && "bg-info/10 text-info border-info/20"
                                  )}
                                >
                                  {notification.type}
                                </Badge>
                                {!notification.read && (
                                  <Badge variant="default" className="bg-primary animate-pulse">
                                    New
                                  </Badge>
                                )}
                              </div>
                              <p className="text-sm leading-relaxed">
                                {notification.message}
                              </p>
                              <div className="flex items-center gap-2 text-xs text-muted-foreground">
                                <Clock className="h-3 w-3" />
                                {new Date(notification.createdAt).toLocaleString()}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            {!notification.read && (
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => markAsRead(notification._id)}
                                className="hover:scale-110 transition-transform duration-200"
                              >
                                <CheckCircle className="h-4 w-4 text-primary" />
                              </Button>
                            )}
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => deleteNotification(notification._id)}
                              className="hover:scale-110 transition-transform duration-200"
                            >
                              <Trash2 className="h-4 w-4 text-destructive" />
                            </Button>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                    
                    {loading && (
                      <div className="flex justify-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
                      </div>
                    )}
                    
                    {!hasMore && !loading && (
                      <p className="text-center text-sm text-muted-foreground py-6">
                        You've reached the end
                      </p>
                    )}
                  </div>
                ) : (
                  <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="flex flex-col items-center justify-center py-16 text-center"
                  >
                    <div className="rounded-full bg-muted p-4 mb-4">
                      <BellOff className="h-8 w-8 text-muted-foreground" />
                    </div>
                    <h3 className="text-lg font-medium mb-2">No Notifications</h3>
                    <p className="text-sm text-muted-foreground">
                      You're all caught up! Check back later for new updates.
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </DashboardLayout>
  );
}

export default Notifications;
