import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import userService from '@/services/userService';
import authService from '@/services/authService';
import { toast } from 'sonner';
import User  from '@/components/profile/UserModel';
import { LoginHistory } from '@/components/profile/LoginHistoryModel';
import { KYCLevel2Response } from '@/components/profile/UserModel';

interface UserContextType {
  user: User | null;
  kycLevel2: KYCLevel2Response | null;
  loginHistory: LoginHistory[];
  isLoading: boolean;
  isTokenExpired: () => Promise<boolean>;
  fetchUserProfile: () => Promise<void>;
  updateUserProfile: (profileData: { lastName: string; firstName: string, avatar: File, deleteAvatar: boolean }) => Promise<void>;
  updateUserEmail: (emailData: { oldEmail: string; email: string }) => Promise<void>;
  updateUserKYC: (kycData: { country: string; phoneNumber: string; dateOfBirth: string }) => Promise<boolean>;
  updateUserKycLevel2: () => Promise<boolean>;
  kycPhoneVerify: (phoneVerifyData:{otp: string}) => Promise<boolean>;
  kycResendPhoneOTP: () => Promise<boolean>;
  
  getLoginHistory: () => Promise<void>;
  clearUser: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [kycLevel2, setKycLevel2] = useState<KYCLevel2Response | null>(null);
  const [loginHistory, setLoginHistory] = useState<LoginHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load user from localStorage on initial render
    const loadUser = () => {
      try {
        if (isTokenExpired()) return;
        const currentUser = authService.getCurrentUser();
        setUser(currentUser);
      } catch (error) {
        console.error('Error loading user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUser();
  }, []);

  useEffect(() => {
    // Check if user is logged in (token exists)
    const authToken = localStorage.getItem('authToken');
    
    if (authToken) {
      // Fetch fresh user data on application startup
      fetchUserProfile();
    }
  }, []); // Empty dependency array means this runs once on mount

  const fetchUserProfile = async () => {
    try {
      const response = await userService.getProfile();
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error('Failed to fetch user profile');
    } 
  };

  const updateUserProfile = async (profileData: { lastName: string; firstName: string, avatar: File, deleteAvatar: boolean }) => {
    try {
      const response = await userService.updateProfile(profileData);
      if (response.status) { // No need for response.data.status
        toast.success(response.message);
        //setUser(response); // If needed
      } else {
          toast.error(response.message); // Access response.message directly
      }
    } catch (error: any) {
        console.error('Error updating user names:', error);

        // Handling backend error response properly
        if (error.response && error.response.data) {
            toast.error(error.response.data.message || 'Failed to update user names');
        } else {
            toast.error('Failed to update user names');
        }
    } 
  };

  const updateUserEmail = async (emailData: { oldEmail: string; email: string }) => {
    try {
      const response = await userService.updateEmail(emailData);
      if (response.status) { // No need for response.data.status
        toast.success(response.message);
        // setUser(response); // If needed
      } else {
        toast.error(response.message); // Access response.message directly
      }
    } catch (error: any) {
        console.error('Error updating user Email:', error);

        // Handling backend error response properly
        if (error.response && error.response.data) {
            toast.error(error.response.data.message || 'Failed to update user Email');
        } else {
            toast.error('Failed to update user Email');
        }
    } 
  };

  const updateUserKYC = async (kycData: { country: string; phoneNumber: string; dateOfBirth: string }) => {
    
    try {
        const response = await userService.userKYC(kycData); // response is already response.data

        if (response.status) { // No need for response.data.status
            toast.success(response.message);
        } else {
            toast.error(response.message); // Access response.message directly
        }
        return response.status;
    } catch (error: any) {
        console.error('Error updating user KYC:', error);
        // Handling backend error response properly
        if (error.response && error.response.data) {
            toast.error(error.response.data.message || 'Failed to update user KYC');
        } else {
            toast.error('Failed to update user KYC');
        }
    }
};

const updateUserKycLevel2 = async () => {
  try {
    const response = await userService.userKycLevel2();
    if (response.status) {
      toast.success(response.message);
      setKycLevel2(response.data); // Assuming response.data contains the KYC Level 2 data
    } else {
      toast.error(response.message);
    }
    return response.status;
  } catch (error: any) {
    console.error('Error starting KYC Level 2:', error);
    toast.error(error.response?.data?.message || 'Failed to start KYC Level 2');
    return false;
  }
};

const kycPhoneVerify = async (phoneVerifyData:{otp: string})  => {
  try {
    const response = await userService.userKycPhoneVefity(phoneVerifyData);
    if (response.status) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    return response.status;
  } catch (error: any) {
    console.error('Error verifying phone number:', error);
    toast.error(error.response?.data?.message || 'Failed to verify phone number');
    return false;
  }
};

const kycResendPhoneOTP = async () => {
  try {
    const response = await userService.userKycResentPhoneOTP();
    if (response.status) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    return response.status;
  } catch (error: any) {
    console.error('Error resending phone OTP:', error);
    toast.error(error.response?.data?.message || 'Failed to resend phone OTP');
    return false;
  }
};

const getLoginHistory = async () => {
    
  try {
      const response = await userService.loginHistory(); 

      if (response.status) { 
          //toast.success(response.message);
          setLoginHistory(response.data);
      } else {
          toast.error(response.message); // Access response.message directly
      }
    } catch (error: any) {
      console.error('Error fetching login history:', error);
      
      // Handling backend error response properly
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Failed to fetch login history');
      } else {
        toast.error('Failed to fetch login history');
      }
    }
  };




  const clearUser = () => {
    setUser(null);
    // Clear from localStorage is handled by authService.logout
  };

  const isTokenExpired = async () => {
    const token = localStorage.getItem('authToken');
    console.log('Token:', token); // Log the token for debugging
    if (!token){
      console.log('Token exists:', !!token); // Log whether the token exists
      return true; // No token found so treat it as expired
    } 

    try {
        // Split the token and decode the payload
        const payload = JSON.parse(atob(token.split('.')[1]));
        
        // Display the decoded values in the console
        console.log('Decoded JWT Payload:', payload);

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        // Check if the token is expired
        if (payload.exp && payload.exp < currentTime) {
            console.warn('Token expired, clearing authToken and user...');
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            setUser(null);
            return true; // Token is expired
        }
        return false; // Token is valid
    } catch (error) {
        console.error('Failed to decode token:', error);
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        setUser(null);
        return true; // Treat decoding failure as token expired
    }
};

  

  return (
    <UserContext.Provider value={{ 
      user, 
      kycLevel2,
      loginHistory,
      isLoading, 
      isTokenExpired, 
      fetchUserProfile,
      updateUserProfile,
      updateUserEmail,
      updateUserKYC,
      updateUserKycLevel2,
      kycPhoneVerify,
      kycResendPhoneOTP,
      getLoginHistory,
      clearUser 
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};