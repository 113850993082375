// Enhanced IP range to country mapping with binary search for better performance
interface IPRange {
  start: string;
  end: string;
  country: string;
  region: string;
  state?: string;  // Added state field
  city?: string;
  isp?: string;
  timezone?: string;
}

// Pre-computed numeric values for faster lookups
interface NumericIPRange extends IPRange {
  startNum: number;
  endNum: number;
}

// Expanded IP ranges with more countries and better organization
const IP_RANGES: IPRange[] = [
  // Asia Pacific
  { start: '1.0.0.0', end: '1.255.255.255', country: 'Australia', region: 'APNIC' },
  { start: '14.0.0.0', end: '14.255.255.255', country: 'Asia Pacific', region: 'APNIC' },
  { start: '27.0.0.0', end: '27.255.255.255', country: 'China', region: 'East Asia' },
  { start: '36.0.0.0', end: '36.255.255.255', country: 'China', region: 'East Asia' },
  { start: '39.0.0.0', end: '39.255.255.255', country: 'China', region: 'East Asia' },
  { start: '42.0.0.0', end: '42.255.255.255', country: 'China', region: 'East Asia' },
  { start: '43.0.0.0', end: '43.255.255.255', country: 'Japan', region: 'East Asia' },
  { start: '49.0.0.0', end: '49.255.255.255', country: 'Asia Pacific', region: 'APNIC' },
  { start: '58.0.0.0', end: '58.255.255.255', country: 'Japan', region: 'East Asia' },
  { start: '59.0.0.0', end: '59.255.255.255', country: 'Korea', region: 'East Asia' },
  { start: '60.0.0.0', end: '60.255.255.255', country: 'Japan', region: 'East Asia' },
  { start: '61.0.0.0', end: '61.255.255.255', country: 'China', region: 'East Asia' },

  // Europe
  { start: '2.0.0.0', end: '2.255.255.255', country: 'France', region: 'Western Europe' },
  { start: '5.0.0.0', end: '5.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '31.0.0.0', end: '31.255.255.255', country: 'United Kingdom', region: 'Western Europe' },
  { start: '46.0.0.0', end: '46.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '77.0.0.0', end: '77.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '78.0.0.0', end: '78.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '79.0.0.0', end: '79.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '81.0.0.0', end: '81.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '82.0.0.0', end: '82.255.255.255', country: 'European Union', region: 'RIPE NCC' },
  { start: '83.0.0.0', end: '83.255.255.255', country: 'European Union', region: 'RIPE NCC' },

  // North America
  { 
    start: '3.0.0.0', 
    end: '3.255.255.255', 
    country: 'United States', 
    region: 'AWS', 
    state: 'Virginia',
    city: 'Ashburn',
    isp: 'Amazon Web Services'
  },
  { 
    start: '4.0.0.0', 
    end: '4.255.255.255', 
    country: 'United States', 
    region: 'Level 3',
    state: 'Colorado',
    city: 'Denver',
    isp: 'Level 3 Communications'
  },
  {
    start: '8.0.0.0',
    end: '8.255.255.255',
    country: 'United States',
    region: 'Level 3',
    state: 'California',
    city: 'Los Angeles',
    isp: 'Level 3 Communications'
  },
  { start: '9.0.0.0', end: '9.255.255.255', country: 'United States', region: 'IBM' },
  { start: '11.0.0.0', end: '11.255.255.255', country: 'United States', region: 'DoD' },
  { start: '12.0.0.0', end: '12.255.255.255', country: 'United States', region: 'AT&T' },
  { start: '13.0.0.0', end: '13.255.255.255', country: 'United States', region: 'Xerox' },
  { start: '15.0.0.0', end: '15.255.255.255', country: 'United States', region: 'HP' },
  { start: '16.0.0.0', end: '16.255.255.255', country: 'United States', region: 'DEC' },
  { start: '17.0.0.0', end: '17.255.255.255', country: 'United States', region: 'Apple' },
  { start: '18.0.0.0', end: '18.255.255.255', country: 'United States', region: 'MIT' },
  { start: '64.0.0.0', end: '64.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '65.0.0.0', end: '65.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '66.0.0.0', end: '66.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '67.0.0.0', end: '67.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '68.0.0.0', end: '68.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '96.0.0.0', end: '96.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '97.0.0.0', end: '97.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '98.0.0.0', end: '98.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '99.0.0.0', end: '99.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '100.0.0.0', end: '100.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '104.0.0.0', end: '104.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '107.0.0.0', end: '107.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '108.0.0.0', end: '108.255.255.255', country: 'United States', region: 'ARIN' },
  { start: '128.0.0.0', end: '128.255.255.255', country: 'United States', region: 'Various Universities' },
  { start: '129.0.0.0', end: '129.255.255.255', country: 'United States', region: 'Various Universities' },
  { start: '130.0.0.0', end: '130.255.255.255', country: 'United States', region: 'Various Universities' },

  // Latin America and Caribbean
  { start: '138.0.0.0', end: '138.255.255.255', country: 'Latin America', region: 'LACNIC' },
  { start: '139.0.0.0', end: '139.255.255.255', country: 'Latin America', region: 'LACNIC' },
  { start: '167.0.0.0', end: '167.255.255.255', country: 'Brazil', region: 'South America' },
  { start: '168.0.0.0', end: '168.255.255.255', country: 'Latin America', region: 'LACNIC' },
  { start: '177.0.0.0', end: '177.255.255.255', country: 'Brazil', region: 'South America' },
  { start: '179.0.0.0', end: '179.255.255.255', country: 'Brazil', region: 'South America' },
  { start: '181.0.0.0', end: '181.255.255.255', country: 'Latin America', region: 'LACNIC' },
  { start: '186.0.0.0', end: '186.255.255.255', country: 'Latin America', region: 'LACNIC' },
  { start: '187.0.0.0', end: '187.255.255.255', country: 'Brazil', region: 'South America' },
  { start: '189.0.0.0', end: '189.255.255.255', country: 'Brazil', region: 'South America' },
  { start: '190.0.0.0', end: '190.255.255.255', country: 'Latin America', region: 'LACNIC' },
  { start: '191.0.0.0', end: '191.255.255.255', country: 'Brazil', region: 'South America' },

  // Africa
  { start: '41.0.0.0', end: '41.255.255.255', country: 'Africa Region', region: 'AfriNIC' },
  { start: '102.0.0.0', end: '102.255.255.255', country: 'Africa Region', region: 'AfriNIC' },
  { start: '105.0.0.0', end: '105.255.255.255', country: 'Africa Region', region: 'AfriNIC' },
  { start: '154.0.0.0', end: '154.255.255.255', country: 'Africa Region', region: 'AfriNIC' },
  { start: '196.0.0.0', end: '196.255.255.255', country: 'Africa Region', region: 'AfriNIC' },
  { start: '197.0.0.0', end: '197.255.255.255', country: 'Africa Region', region: 'AfriNIC' },

  // Special Purpose
  { start: '0.0.0.0', end: '0.255.255.255', country: 'Reserved', region: 'Special Purpose' },
  { start: '10.0.0.0', end: '10.255.255.255', country: 'Local Network', region: 'Private' },
  { start: '100.64.0.0', end: '100.127.255.255', country: 'Shared Address', region: 'CGN' },
  { start: '127.0.0.0', end: '127.255.255.255', country: 'Localhost', region: 'Local' },
  { start: '169.254.0.0', end: '169.254.255.255', country: 'Link Local', region: 'Local' },
  { start: '172.16.0.0', end: '172.31.255.255', country: 'Local Network', region: 'Private' },
  { start: '192.0.0.0', end: '192.0.0.255', country: 'Reserved', region: 'IETF Protocol' },
  { start: '192.0.2.0', end: '192.0.2.255', country: 'Reserved', region: 'TEST-NET-1' },
  { start: '192.88.99.0', end: '192.88.99.255', country: 'Reserved', region: '6to4 Relay' },
  { start: '192.168.0.0', end: '192.168.255.255', country: 'Local Network', region: 'Private' },
  { start: '198.18.0.0', end: '198.19.255.255', country: 'Reserved', region: 'Benchmark' },
  { start: '224.0.0.0', end: '239.255.255.255', country: 'Multicast', region: 'Reserved' },
  { start: '240.0.0.0', end: '255.255.255.255', country: 'Reserved', region: 'Future Use' },
];

// Convert IP address to long integer for comparison
const ipToLong = (ip: string): number => {
  try {
    const parts = ip.split('.')
      .map(part => {
        const num = parseInt(part, 10);
        if (isNaN(num) || num < 0 || num > 255) {
          throw new Error(`Invalid IP address part: ${part}`);
        }
        return num;
      });
    
    if (parts.length !== 4) {
      throw new Error(`Invalid IP address format: ${ip}`);
    }
    
    return (parts[0] * 16777216) + (parts[1] * 65536) + (parts[2] * 256) + parts[3];
  } catch (error) {
    throw new Error(`Failed to convert IP to number: ${error.message}`);
  }
};

// Precompute numeric ranges for better performance
const NUMERIC_IP_RANGES: NumericIPRange[] = IP_RANGES.map(range => ({
  ...range,
  startNum: ipToLong(range.start),
  endNum: ipToLong(range.end)
})).sort((a, b) => a.startNum - b.startNum);

// Validate IP address format
const isValidIPv4 = (ip: string): boolean => {
  const pattern = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
  if (!pattern.test(ip)) return false;
  
  return ip.split('.').every(part => {
    const num = parseInt(part, 10);
    return num >= 0 && num <= 255;
  });
};

// Check if IP is in reserved ranges
const isReservedIP = (ip: string): boolean => {
  const ipNum = ipToLong(ip);
  return NUMERIC_IP_RANGES.some(range => 
    (range.country === 'Local Network' || range.country === 'Localhost' || 
     range.country === 'Link Local' || range.country === 'Multicast' || 
     range.country === 'Reserved') && 
    ipNum >= range.startNum && ipNum <= range.endNum
  );
};

// Binary search for finding IP range
const findIPRange = (ipNum: number): NumericIPRange | null => {
  let low = 0;
  let high = NUMERIC_IP_RANGES.length - 1;
  
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const range = NUMERIC_IP_RANGES[mid];
    
    if (ipNum >= range.startNum && ipNum <= range.endNum) {
      return range;
    } else if (ipNum < range.startNum) {
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }
  
  return null;
};

export interface LocationData {
  country: string;
  city: string;
  region: string;
  state?: string;  // Added state field
  timezone: string;
  isp: string;
  isLoading: boolean;
  isReserved?: boolean;
  ipAddress?: string;
  networkType?: string;
  deviceCategory?: {
    type: 'Server' | 'IoT' | 'Mobile' | 'Desktop' | 'Unknown';
    subType?: string;
    environment?: 'Production' | 'Development' | 'Testing' | 'Internal';
  };
}

// Add network classification constants
const NETWORK_TYPES = {
  PRIVATE: 'Private Network',
  PUBLIC: 'Public Internet',
  DATACENTER: 'Datacenter',
  CDN: 'Content Delivery Network',
  MOBILE: 'Mobile Carrier',
  CORPORATE: 'Corporate Network',
  EDUCATIONAL: 'Educational Institution',
  IOT: 'IoT Network',
  VPN: 'VPN Service'
} as const;

// Add US states mapping
const US_STATES = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
} as const;

// Enhanced IP classification
const classifyNetwork = (ip: string, range: NumericIPRange): LocationData['deviceCategory'] => {
  // Check for special ranges first
  if (isReservedIP(ip)) {
    if (ip.startsWith('10.')) {
      return {
        type: 'Desktop',
        environment: 'Internal',
        subType: 'Corporate Network'
      };
    }
    if (ip.startsWith('192.168.')) {
      return {
        type: 'Desktop',
        environment: 'Internal',
        subType: 'Home Network'
      };
    }
    if (ip.startsWith('172.16.')) {
      return {
        type: 'Desktop',
        environment: 'Internal',
        subType: 'Development Environment'
      };
    }
  }

  // Classify based on range patterns
  if (range.isp?.toLowerCase().includes('amazon')) {
    return {
      type: 'Server',
      environment: 'Production',
      subType: 'AWS'
    };
  }
  
  if (range.isp?.toLowerCase().includes('google')) {
    return {
      type: 'Server',
      environment: 'Production',
      subType: 'GCP'
    };
  }

  // Mobile carrier detection
  const mobileCarriers = ['t-mobile', 'verizon', 'at&t', 'sprint', 'vodafone', 'orange'];
  if (mobileCarriers.some(carrier => range.isp?.toLowerCase().includes(carrier))) {
    return {
      type: 'Mobile',
      subType: 'Cellular Network'
    };
  }

  // IoT network detection
  if (range.isp?.toLowerCase().includes('iot') || range.region?.toLowerCase().includes('iot')) {
    return {
      type: 'IoT',
      subType: 'Smart Device Network'
    };
  }

  return {
    type: 'Unknown',
    environment: 'Production'
  };
};

export const getLocationFromIP = (ip: string): LocationData => {
  try {
    // Handle IPv6 mapped IPv4 addresses
    if (ip.startsWith('::ffff:')) {
      ip = ip.substring(7);
    }
    
    // Handle localhost special case
    if (ip === '127.0.0.1' || ip === 'localhost') {
      return {
        country: 'Localhost',
        city: 'Local',
        region: 'Local',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isp: 'Local',
        isLoading: false,
        isReserved: true,
        ipAddress: ip
      };
    }
    
    // Validate IP format
    if (!isValidIPv4(ip)) {
      throw new Error(`Invalid IPv4 address format: ${ip}`);
    }
    
    const ipNum = ipToLong(ip);
    const range = findIPRange(ipNum);
    
    if (range) {
      const deviceCategory = classifyNetwork(ip, range);
      
      // Enhanced location data for US addresses
      const locationData: LocationData = {
        country: range.country,
        city: range.city || 'Unknown',
        region: range.region || range.country,
        timezone: range.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        isp: range.isp || 'Unknown',
        isLoading: false,
        isReserved: isReservedIP(ip),
        ipAddress: ip,
        networkType: determineNetworkType(range, ip),
        deviceCategory
      };

      // Add state information for US addresses
      if (range.country === 'United States' && range.state) {
        locationData.state = range.state;
        // Update region to include state if not a special region (like AWS)
        if (!range.region.match(/AWS|Level 3|IBM|DoD|AT&T|Xerox|HP/)) {
          locationData.region = `${range.state}, United States`;
        }
      }

      return locationData;
    }
    
    // No match found
    return {
      country: 'Unknown',
      city: 'Unknown',
      region: 'Unknown',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isp: 'Unknown',
      isLoading: false,
      ipAddress: ip
    };
  } catch (error) {
    console.error('Error processing IP:', error);
    return {
      country: 'Error',
      city: 'Unknown',
      region: 'Unknown',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isp: 'Unknown',
      isLoading: false,
      error: error.message
    };
  }
};

function determineNetworkType(range: NumericIPRange, ip: string): string {
  // Check reserved ranges first with exact matches
  if (isReservedIP(ip)) {
    if (ip.match(/^(10\.|172\.(1[6-9]|2[0-9]|3[0-1])\.|192\.168\.)/)) {
      return NETWORK_TYPES.PRIVATE;
    }
    if (ip.match(/^127\./)) {
      return NETWORK_TYPES.PRIVATE + '_LOOPBACK';
    }
    if (ip.match(/^169\.254\./)) {
      return NETWORK_TYPES.PRIVATE + '_LINK_LOCAL';
    }
  }
  
  const ispLower = (range.isp || '').toLowerCase();
  
  // Cloud provider detection
  if (ispLower.match(/\b(aws|amazon\s+web\s+services)\b/)) {
    return NETWORK_TYPES.DATACENTER + '_AWS';
  }
  if (ispLower.match(/\b(azure|microsoft\s+cloud)\b/)) {
    return NETWORK_TYPES.DATACENTER + '_AZURE';
  }
  if (ispLower.match(/\b(google\s+cloud|gcp)\b/)) {
    return NETWORK_TYPES.DATACENTER + '_GCP';
  }
  
  // CDN detection
  const cdnProviders = {
    'cloudflare': '_CLOUDFLARE',
    'akamai': '_AKAMAI',
    'fastly': '_FASTLY',
    'cloudfront': '_CLOUDFRONT'
  };
  
  for (const [provider, suffix] of Object.entries(cdnProviders)) {
    if (ispLower.includes(provider)) {
      return NETWORK_TYPES.CDN + suffix;
    }
  }
  
  // Educational institutions
  if (ispLower.match(/\b(university|college|edu)\b/)) {
    return NETWORK_TYPES.EDUCATIONAL;
  }
  
  // VPN/Proxy detection with specific providers
  const vpnProviders = [
    'nordvpn', 'expressvpn', 'protonvpn', 'privateinternetaccess',
    'torguard', 'cyberghost', 'mullvad'
  ];
  
  if (vpnProviders.some(vpn => ispLower.includes(vpn)) || 
      ispLower.match(/\b(vpn|proxy)\b/)) {
    return NETWORK_TYPES.VPN;
  }

  // Mobile carriers
  const mobileCarriers = {
    't-mobile': '_TMOBILE',
    'verizon': '_VERIZON',
    'at&t': '_ATT',
    'sprint': '_SPRINT',
    'vodafone': '_VODAFONE',
    'orange': '_ORANGE'
  };

  for (const [carrier, suffix] of Object.entries(mobileCarriers)) {
    if (ispLower.includes(carrier)) {
      return NETWORK_TYPES.MOBILE + suffix;
    }
  }

  return NETWORK_TYPES.PUBLIC;
}

// Ability to add custom ranges programmatically
export const addCustomIPRange = (range: IPRange): void => {
  const newNumericRange: NumericIPRange = {
    ...range,
    startNum: ipToLong(range.start),
    endNum: ipToLong(range.end)
  };
  
  // Insert into the sorted array at the correct position
  const index = NUMERIC_IP_RANGES.findIndex(r => r.startNum > newNumericRange.startNum);
  if (index === -1) {
    NUMERIC_IP_RANGES.push(newNumericRange);
  } else {
    NUMERIC_IP_RANGES.splice(index, 0, newNumericRange);
  }
};

// Add MTN Cameroon network range
addCustomIPRange({
  start: '129.0.189.0',
  end: '129.0.189.255',
  country: 'Cameroon',
  region: 'Africa Region',
  isp: 'MTN Cameroon',
  timezone: 'Africa/Douala',
  networkType: NETWORK_TYPES.MOBILE + '_MTN',
  deviceCategory: {
    type: 'Mobile',
    subType: 'Cellular Network',
    environment: 'Production'
  }
});
