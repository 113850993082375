import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Shield, Coins, CreditCard, Brain, Globe, ChevronRight } from 'lucide-react';
import authService from '@/services/authService';
import { SignUpForm } from '@/components/auth/signup/SignUpForm';
import { cn } from '@/lib/utils';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);
  
  // Define primary gradient to match Login page
  const primaryGradient = "from-blue-600 via-blue-500 to-blue-400";

  useEffect(() => {
    document.title = 'EvoCash | Sign Up';
    if (authService.isAuthenticated()) {
      navigate('/dashboard');
    }
    
    // Add mounted animation
    setTimeout(() => setMounted(true), 100);
  }, [navigate]);

  const triggerHapticFeedback = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(5);
    }
  };

  const handleBackToHome = () => {
    triggerHapticFeedback();
    navigate('/');
  };

  // Feature list with iOS-style animations
  const features = [
    {
      icon: Coins,
      title: "Asset Tokenization",
      description: "Transform real-world assets into digital tokens",
      gradient: "from-amber-600 via-amber-500 to-amber-400"
    },
    {
      icon: CreditCard,
      title: "Virtual Debit Card",
      description: "Spend your digital assets globally with ease",
      gradient: "from-indigo-600 via-indigo-500 to-indigo-400"
    },
    {
      icon: Brain,
      title: "EVA - Financial AI Agent",
      description: "Your personal AI to navigate finances",
      gradient: "from-purple-600 via-purple-500 to-purple-400"
    },
    {
      icon: Globe,
      title: "Cross-Border Transactions",
      description: "Borderless transfers without limitations",
      gradient: primaryGradient
    }
  ];

  return (
    <div className="min-h-[100dvh] bg-black relative flex flex-col lg:flex-row overflow-hidden">
      {/* Dynamic background similar to Login */}
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-900 via-gray-950 to-black"></div>
      
      {/* Animated grid overlay */}
      <div className="absolute inset-0 bg-grid-white/[0.02] bg-[length:24px_24px] pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
      </div>
      
      {/* Dynamic accent lighting */}
      <motion.div 
        animate={{
          opacity: [0.2, 0.4, 0.2],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className="absolute inset-0 pointer-events-none"
        style={{
          background: 'radial-gradient(circle at 20% 20%, var(--blue-500), transparent 70%)'
        }}
      />

      {/* iOS-style back button with subtle animation */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="absolute top-safe-top left-4 z-50 pointer-events-auto md:top-6"
      >
        <Button
          variant="ghost"
          size="sm"
          onClick={handleBackToHome}
          className="flex items-center space-x-1 text-gray-300 backdrop-blur-sm bg-black/10 p-2 rounded-full hover:bg-black/20"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="sr-only sm:not-sr-only sm:inline-block text-sm ml-1">Back</span>
        </Button>
      </motion.div>

      {/* Enhanced main content container */}
      <div className="flex-1 flex items-center justify-center p-4 relative min-h-[100dvh] lg:min-h-screen z-10">
        <AnimatePresence>
          {mounted && (
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ 
                type: "spring", 
                stiffness: 100, 
                damping: 15, 
                duration: 0.6 
              }}
              className="w-full max-w-[min(500px,100%-2rem)] mx-auto"
            >
              {/* Enhanced card with iOS-like glass effect */}
              <motion.div 
                className={cn(
                  "p-6 sm:p-8 rounded-3xl",
                  "backdrop-blur-xl bg-black/40",
                  "border border-gray-800/50",
                  "shadow-[0_8px_32px_rgba(0,0,0,0.4)]"
                )}
                whileHover={{ boxShadow: "0 12px 40px rgba(0,0,0,0.5)" }}
              >
                {/* Enhanced Header with iOS animations */}
                <div className="text-center space-y-4 mb-8">
                  <motion.div 
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ 
                      type: "spring",
                      delay: 0.2,
                      duration: 0.8
                    }}
                    className="flex items-center justify-center mb-4"
                  >
                    <motion.div
                      whileHover={{ scale: 1.05, rotate: 5 }}
                      whileTap={{ scale: 0.95 }}
                      className="size-16 rounded-full relative"
                    >
                      <motion.div 
                        animate={{
                          opacity: [0.5, 0.8, 0.5],
                          scale: [1, 1.1, 1],
                        }}
                        transition={{
                          duration: 4,
                          repeat: Infinity,
                          repeatType: "reverse",
                        }}
                        className={cn(
                          "absolute -inset-3 rounded-full",
                          "bg-gradient-to-r",
                          primaryGradient,
                          "blur-md opacity-30"
                        )}
                      />
                      <div className="size-16 rounded-full bg-black/40 backdrop-blur-sm flex items-center justify-center border border-gray-700/50 shadow-inner">
                        <img 
                          src="/logo.svg"
                          alt="EvoCash Logo" 
                          className="w-8 h-8 object-contain"
                        />
                      </div>
                    </motion.div>
                  </motion.div>
                  
                  <motion.h1 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    className="text-2xl sm:text-3xl font-bold tracking-tight text-white"
                  >
                    Create Your Account
                  </motion.h1>
                  
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    className="text-gray-400 text-sm max-w-sm mx-auto"
                  >
                    Join the future of finance with tokenized assets
                  </motion.p>
                </div>

                {/* SignUp Form wrapped in motion */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.6 }}
                  className="space-y-6"
                >
                  <SignUpForm />
                  
                  <div className="text-center text-sm pt-2">
                    <span className="text-gray-400">Already have an account?</span>{' '}
                    <Link to="/login" className="text-blue-400 hover:text-blue-300 font-medium">
                      Sign in
                    </Link>
                  </div>
                </motion.div>
                
                {/* iOS-style security note */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                  className="mt-8 pt-6 border-t border-gray-800/50 flex items-center justify-center"
                >
                  <div className="flex items-center space-x-2 text-xs text-gray-500">
                    <Shield className="w-3 h-3" />
                    <span>Protected by bank-level security</span>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Side feature banner with iOS animations - Only visible on large screens */}
      <div className="hidden lg:block lg:flex-1 relative overflow-hidden border-l border-gray-800/50">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black" />
        <div className="absolute inset-0 bg-grid-white/[0.03]" />
        
        <div className="relative w-full h-full flex items-center justify-center p-8 xl:p-12">
          <div className="max-w-xl">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.6 }}
              className="text-3xl xl:text-4xl font-bold text-white mb-6"
            >
              The Future of Finance
            </motion.h2>
            
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              className="text-gray-400 mb-10 max-w-lg"
            >
              Experience the power of blockchain technology combined with traditional finance
            </motion.p>
            
            <div className="space-y-6">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, x: 30 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ 
                    delay: 0.8 + (index * 0.1), 
                    duration: 0.6,
                    type: "spring",
                    stiffness: 100
                  }}
                  className="flex items-start space-x-4"
                >
                  <motion.div
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    className="shrink-0 mt-1"
                  >
                    <motion.div
                      animate={{
                        boxShadow: ['0 0 0 rgba(59, 130, 246, 0)', '0 0 12px rgba(59, 130, 246, 0.3)', '0 0 0 rgba(59, 130, 246, 0)']
                      }}
                      transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: index * 0.5
                      }}
                      className={cn(
                        "size-10 rounded-xl flex items-center justify-center",
                        "bg-gradient-to-br",
                        feature.gradient
                      )}
                    >
                      <feature.icon className="size-5 text-white" />
                    </motion.div>
                  </motion.div>
                  
                  <div>
                    <h3 className="text-white font-medium mb-1">{feature.title}</h3>
                    <p className="text-gray-400 text-sm">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
            
            {/* Added iOS-style rating display */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.3, duration: 0.6 }}
              className="mt-12 pt-8 border-t border-gray-800/50"
            >
              <div className="flex items-center space-x-3">
                <div className="flex">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <motion.svg 
                      key={star}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 1.4 + (star * 0.1) }}
                      className="w-4 h-4 text-blue-500" 
                      fill="currentColor" 
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </motion.svg>
                  ))}
                </div>
                <div className="text-sm text-gray-400">
                  <span className="font-medium text-white">4.9</span> App Store rating
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;