// notificationApi.ts
import apiClient from './apiClient';
import { Notification } from '@/components/dashboard/notifications/NotificationModel'; // Ensure you import the Notification model

const notificationService = {
  // Fetch notifications for a user with pagination
  getNotifications: async (page: number = 1, limit: number = 5): Promise<Notification[]> => {
    try {
      const response = await apiClient.get(`/notifications?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      return null;
    }
  },

  // Mark a notification as read
  markAsRead: async (notificationId: string): Promise<Notification> => {
    const response = await apiClient.patch(`/notifications/${notificationId}/read`);
    return response.data;
  },

  //not yet implemented on backend
  // Delete a notification
  deleteNotification: async (notificationId: string): Promise<{ status: boolean; message?: string }> => {
    const response = await apiClient.delete(`/notifications/${notificationId}`);
    return response.data;
  },

  // Delete all notifications for a user
  deleteAllNotifications: async (userId: string): Promise<{ status: boolean; message?: string }> => {
    const response = await apiClient.delete(`/notifications/user/${userId}`);
    return response.data;
  },
};

export default notificationService;
