import { ReactNode } from 'react';
import { cn } from '@/lib/utils';
import DashboardBottomNav from '../dashboard/DashboardBottomNav';

interface MobileLayoutProps {
  children: ReactNode;
  className?: string;
  hideBottomNav?: boolean;
}

export function MobileLayout({ children, className, hideBottomNav = false }: MobileLayoutProps) {
  return (
    <div className={cn(
      "min-h-screen flex flex-col bg-background",
      !hideBottomNav && "pb-[env(safe-area-inset-bottom)]", // Remove extra padding
      className
    )}>
      <main className="flex-1 relative">
        {children}
      </main>
      
      {!hideBottomNav && <DashboardBottomNav />}
    </div>
  );
}
