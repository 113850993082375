import { Component, ReactNode } from 'react';
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { 
  BrowserRouter, 
  Routes, 
  Route,
  UNSAFE_NavigationContext
} from "react-router-dom";
import { AuthGuard } from "./components/auth/AuthGuard";
import { UserProvider } from "./contexts/UserContext";
import { PageTitleProvider } from "./contexts/PageTitleContext";
import { useState, useEffect } from "react";
import { ThemeProvider } from '@/contexts/ThemeContext';
import { ChatBot } from "./components/chat/ChatBot";
import { NotificationProvider } from '@/contexts/NotificationContext';
import { WalletProvider } from "@/contexts/WalletContext";
import { TransactionProvider } from "./contexts/TransactionContext";
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { QuoteScreen } from "./components/auth/QuoteScreen";

// Add ErrorBoundary component
class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="text-center">
            <h2 className="text-xl font-bold">Something went wrong</h2>
            <button 
              className="mt-4 px-4 py-2 bg-primary text-white rounded"
              onClick={() => this.setState({ hasError: false })}
            >
              Try again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Pages
import Index from "./pages/Index";
import Dashboard from "./pages/Dashboard";
import Portfolio from "./pages/dashboard/Portfolio";
import Transactions from "./pages/dashboard/Transactions";
import Trade from "./pages/dashboard/Trade";
import Wallet from "./pages/dashboard/Wallet";
import DepositPage from "./pages/dashboard/DepositPage";
import WithdrawPage from "./pages/dashboard/WithdrawPage";
import TransferPage from "./pages/dashboard/TransferPage";
import BuyPage from "./pages/dashboard/BuyPage";
import PaymentMethodPage from "./pages/dashboard/PaymentMethodPage";
import ProfilePage from "./pages/dashboard/Profile";
import Settings from "./pages/dashboard/Settings";
import Markets from "./pages/Markets";
import GoldPriceChart from './pages/GoldPriceChart';

import Notifications from './pages/dashboard/Notifications';
import LoginHistory from './pages/dashboard/LoginHistory';

import About from "./pages/About";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import FAQ from "./pages/FAQ";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import SignUpPage from "./pages/SignUp";
import VerifyOTPPage from "./pages/VerifyOTP";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyResetOTP from "./pages/VerifyResetOTP";
import ResetPassword from "./pages/ResetPassword";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";
import Help from "./pages/Help";
import NewsPage from "./pages/dashboard/NewsPage";

UNSAFE_NavigationContext.future = {
  v7_startTransition: true,
  v7_relativeSplatPath: true
};

const App = () => {
  const [queryClient] = useState(() => new QueryClient());
  const [showQuoteScreen, setShowQuoteScreen] = useState(false);
  const [appInitialized, setAppInitialized] = useState(false);

  useEffect(() => {
    const initApp = async () => {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        await StatusBar.setStyle({ style: Style.Dark });
        await StatusBar.setBackgroundColor({ color: '#000000' });
        if (Capacitor.getPlatform() === 'ios') {
          await StatusBar.show();
        }
      }

      // Show quote screen only on mobile platforms
      if (Capacitor.isNativePlatform()) {
        setShowQuoteScreen(true);
      }
      
      // Mark initialization as complete
      setAppInitialized(true);
    };

    initApp();
  }, []);

  // Don't render any routes until app is initialized
  if (!appInitialized) {
    return null; // Or a loading spinner if preferred
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <BrowserRouter>
            <UserProvider>
              <NotificationProvider>
                <WalletProvider>
                  <TransactionProvider>
                    <PageTitleProvider>
                      <Toaster />
                      <Sonner />
                      <ThemeProvider defaultTheme="system">
                        <Routes>
                          {/* Quote Screen Route */}
                          {showQuoteScreen && (
                            <Route path="/" element={<QuoteScreen />} />
                          )}
                          {/* Other routes */}
                          {!showQuoteScreen && (
                            <Route path="/" element={<Index />} />
                          )}
                          <Route path="/news" element={<News />} />
                          <Route path="/about" element={<About />} />
                          <Route path="/contact" element={<Contact />} />
                          <Route path="/terms" element={<Terms />} />
                          <Route path="/privacy" element={<Privacy />} />
                          <Route path="/faq" element={<FAQ />} />
                          <Route path="/help" element={<Help />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/signup" element={<SignUpPage />} />
                          <Route path="/verify-otp" element={<VerifyOTPPage />} />
                          <Route path="/forgot-password" element={<ForgotPassword />} />
                          <Route path="/verify-reset-otp" element={<VerifyResetOTP />} />
                          <Route path="/reset-password" element={<ResetPassword />} />
                          <Route path="/markets" element={<Markets />} />
                          <Route path="/markets/chart" element={<GoldPriceChart />} />

                          {/* Protected routes */}
                          <Route path="/dashboard" element={
                            <AuthGuard>
                              <Dashboard />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/portfolio" element={
                            <AuthGuard>
                              <Portfolio />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/transactions" element={
                            <AuthGuard>
                              <Transactions />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/trade" element={
                            <AuthGuard>
                              <Trade />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/wallet" element={
                            <AuthGuard>
                              <Wallet />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/login_history" element={
                            <AuthGuard>
                              <LoginHistory />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/wallet/deposit" element={
                            <AuthGuard>
                              <DepositPage />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/wallet/withdraw" element={
                            <AuthGuard>
                              <WithdrawPage />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/wallet/transfer" element={
                            <AuthGuard>
                              <TransferPage />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/wallet/payment" element={
                            <AuthGuard>
                              <PaymentMethodPage />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/wallet/payment/buy" element={
                            <AuthGuard>
                              <BuyPage />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/notifications" element={
                            <AuthGuard>
                              <Notifications />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/profile" element={
                            <AuthGuard>
                              <ProfilePage />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/settings" element={
                            <AuthGuard>
                              <Settings />
                            </AuthGuard>
                          } />
                          <Route path="/dashboard/news" element={
                            <AuthGuard>
                              <NewsPage />
                            </AuthGuard>
                          } />
                          {/* Catch-all route */}
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                        {/* Only show ChatBot when QuoteScreen is not active */}
                        {!showQuoteScreen && <ChatBot />}
                      </ThemeProvider>
                    </PageTitleProvider>
                  </TransactionProvider>
                </WalletProvider>
              </NotificationProvider>
            </UserProvider>
          </BrowserRouter>
        </TooltipProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
