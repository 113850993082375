
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import './assets/logo.svg';


// Create a root
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

// Render the app without ClerkProvider
root.render(
  <App />
);
