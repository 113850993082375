import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertCircle, Send, Wallet, Building, Check, ExternalLink, Clock } from 'lucide-react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';

const mockWithdrawalMethods = {
  fiat: [
    { id: '1', name: 'Bank Transfer', logo: '🏦' },
    { id: '2', name: 'Mobile Money', logo: '📱' },
  ],
  crypto: [
    { id: '1', name: 'External Wallet', logo: '👛' },
  ]
};

const mockBalances = {
  '1': { available: 1250.50, symbol: 'USD', fee: 5 },
  '2': { available: 450.75, symbol: 'EUR', fee: 5 },
  '3': { available: 0.2, symbol: 'ETH', fee: 0.001 },
  '4': { available: 0.003, symbol: 'BTC', fee: 0.0001 },
};

const WithdrawPage = () => {
  const { assetId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const asset = location.state?.asset || { assetName: 'Unknown Asset' };
  
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [withdrawalAddress, setWithdrawalAddress] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<string>('1');
  
  const isCrypto = ['3', '4'].includes(assetId || '');
  
  const balanceInfo = assetId ? mockBalances[assetId as keyof typeof mockBalances] : null;
  
  const withdrawalMethods = isCrypto 
    ? mockWithdrawalMethods.crypto 
    : mockWithdrawalMethods.fiat;

  useEffect(() => {
    document.title = `EvoCash | Withdraw ${asset.assetName}`;
    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [asset.assetName]);

  const handleWithdraw = () => {
    if (!withdrawalAmount || parseFloat(withdrawalAmount) <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }
    
    if (parseFloat(withdrawalAmount) > (balanceInfo?.available || 0)) {
      toast.error('Withdrawal amount exceeds available balance');
      return;
    }
    
    if (isCrypto && !withdrawalAddress) {
      toast.error('Please enter a withdrawal address');
      return;
    }
    
    setSubmitting(true);
    
    setTimeout(() => {
      toast.success(`Withdrawal request for ${withdrawalAmount} ${balanceInfo?.symbol} submitted successfully`);
      setSubmitting(false);
      navigate('/dashboard/wallet');
    }, 1500);
  };

  // Calculate the receive amount after fees
  const calculateReceiveAmount = () => {
    if (!withdrawalAmount || !balanceInfo) return 0;
    const amount = parseFloat(withdrawalAmount);
    if (isNaN(amount)) return 0;
    return Math.max(0, amount - balanceInfo.fee);
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center max-w-4xl mx-auto animate-fade-in">
        <div className="flex items-center gap-3 self-start mb-6 w-full">
          <Button 
            variant="ghost" 
            size="icon"
            onClick={() => navigate('/dashboard/wallet')}
            className="rounded-full h-9 w-9"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight flex items-center gap-2">
              Withdraw {asset.assetName}
              {asset.logo && <span className="text-xl">{asset.logo}</span>}
            </h1>
            <p className="text-muted-foreground text-sm mt-1">
              {isCrypto 
                ? "Send funds to an external wallet address" 
                : "Withdraw funds to your bank account"}
            </p>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center py-10 w-full">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary"></div>
          </div>
        ) : (
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="md:col-span-2 shadow-sm">
              <CardHeader className="pb-4">
                <CardTitle className="text-xl flex items-center">
                  <Send className="h-5 w-5 mr-2 text-primary" />
                  Withdrawal Details
                </CardTitle>
                <CardDescription>
                  Please enter the amount and destination for your withdrawal
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-4">
                  <div>
                    <Label htmlFor="amount" className="text-base">Amount</Label>
                    <div className="relative mt-1.5">
                      <Input
                        id="amount"
                        type="number"
                        placeholder="0.00"
                        value={withdrawalAmount}
                        onChange={(e) => setWithdrawalAmount(e.target.value)}
                        className="pl-3 pr-16 py-6 text-lg"
                      />
                      {balanceInfo && (
                        <div className="absolute inset-y-0 right-3 flex items-center">
                          <span className="text-muted-foreground font-medium">{balanceInfo.symbol}</span>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between mt-3 gap-2">
                      {[25, 50, 75, 100].map((percent) => (
                        <Button 
                          key={percent}
                          type="button" 
                          variant="outline"
                          size="sm"
                          className="flex-1 text-xs font-medium"
                          onClick={() => balanceInfo && setWithdrawalAmount(((balanceInfo.available * percent) / 100).toString())}
                        >
                          {percent}%
                        </Button>
                      ))}
                    </div>
                  </div>
                  
                  {isCrypto ? (
                    <div className="mt-6">
                      <Label htmlFor="address" className="text-base">Recipient Address</Label>
                      <div className="relative mt-1.5">
                        <Input
                          id="address"
                          type="text"
                          placeholder={`Enter your ${asset.assetName} address`}
                          value={withdrawalAddress}
                          onChange={(e) => setWithdrawalAddress(e.target.value)}
                          className="py-6"
                        />
                        <div className="absolute inset-y-0 right-3 flex items-center">
                          <Button variant="ghost" size="sm" className="h-7 px-2">
                            <ExternalLink className="h-3.5 w-3.5" />
                          </Button>
                        </div>
                      </div>
                      <p className="text-xs text-muted-foreground mt-1.5">
                        Double check the address to avoid loss of funds
                      </p>
                    </div>
                  ) : (
                    <div className="mt-6">
                      <Label htmlFor="method" className="text-base">Withdrawal Method</Label>
                      <div className="grid grid-cols-1 gap-3 mt-2">
                        {withdrawalMethods.map((method) => (
                          <div 
                            key={method.id}
                            className={cn(
                              "p-4 border rounded-lg flex items-center gap-3 cursor-pointer transition-all",
                              selectedMethod === method.id 
                                ? "border-primary bg-primary/5 ring-1 ring-primary" 
                                : "hover:border-muted-foreground/30"
                            )}
                            onClick={() => setSelectedMethod(method.id)}
                          >
                            <div className="flex-shrink-0 h-10 w-10 rounded-full bg-muted flex items-center justify-center text-xl">
                              {method.logo}
                            </div>
                            <div className="flex-1">
                              <p className="font-medium">{method.name}</p>
                              <p className="text-xs text-muted-foreground">
                                {method.id === '1' ? '1-3 business days processing time' : 'Instant transfers to supported providers'}
                              </p>
                            </div>
                            <div className={cn(
                              "h-5 w-5 rounded-full border flex items-center justify-center",
                              selectedMethod === method.id ? "border-primary bg-primary" : "border-muted-foreground/30"
                            )}>
                              {selectedMethod === method.id && (
                                <Check className="h-3 w-3 text-white" />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
              <CardFooter className="border-t pt-6 flex flex-col space-y-4">
                <Button 
                  className="w-full py-6 text-base"
                  onClick={handleWithdraw}
                  disabled={
                    submitting || 
                    !withdrawalAmount || 
                    parseFloat(withdrawalAmount) <= 0 ||
                    parseFloat(withdrawalAmount) > (balanceInfo?.available || 0) ||
                    (isCrypto && !withdrawalAddress)
                  }
                >
                  {submitting ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary-foreground mr-2"></div>
                  ) : (
                    <Send className="h-5 w-5 mr-2" />
                  )}
                  {submitting ? 'Processing...' : `Withdraw ${balanceInfo?.symbol}`}
                </Button>
                <p className="text-xs text-center text-muted-foreground">
                  By proceeding, you agree to our <a href="#" className="underline">Terms of Service</a> and <a href="#" className="underline">Privacy Policy</a>
                </p>
              </CardFooter>
            </Card>

            <div className="space-y-6">
              <Card className="shadow-sm">
                <CardHeader className="pb-4">
                  <CardTitle className="text-base flex items-center">
                    <Wallet className="h-4 w-4 mr-2 text-primary" />
                    Withdrawal Summary
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  {balanceInfo && (
                    <>
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-muted-foreground">Available balance</span>
                          <span className="font-medium">{balanceInfo.available} {balanceInfo.symbol}</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-muted-foreground">Withdrawal amount</span>
                          <span className="font-medium">
                            {withdrawalAmount ? parseFloat(withdrawalAmount).toFixed(6) : '0'} {balanceInfo.symbol}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-muted-foreground">Fee</span>
                          <span className="font-medium text-amber-600">
                            {balanceInfo.fee} {balanceInfo.symbol}
                          </span>
                        </div>
                        <div className="pt-2 mt-2 border-t">
                          <div className="flex justify-between items-center">
                            <span className="font-medium">You will receive</span>
                            <span className="font-bold">
                              {calculateReceiveAmount().toFixed(6)} {balanceInfo.symbol}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>

              <Card className="shadow-sm border-amber-200 dark:border-amber-800 bg-amber-50/50 dark:bg-amber-950/10">
                <CardContent className="pt-6">
                  <div className="flex gap-3">
                    <div className="flex-shrink-0 mt-1">
                      <Clock className="h-5 w-5 text-amber-600" />
                    </div>
                    <div>
                      <h3 className="font-medium text-amber-800 dark:text-amber-400">Processing Time</h3>
                      <p className="text-sm text-amber-700/80 dark:text-amber-300/80 mt-1">
                        {isCrypto 
                          ? "Crypto withdrawals typically complete in 10-30 minutes depending on network conditions."
                          : "Bank transfers usually process in 1-3 business days, depending on your bank."
                        }
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <div className="rounded-lg border p-4 bg-muted/30">
                <div className="flex gap-3">
                  <div className="flex-shrink-0 mt-0.5">
                    <AlertCircle className="h-5 w-5 text-muted-foreground" />
                  </div>
                  <div>
                    <h3 className="font-medium text-sm">Important Notes</h3>
                    <ul className="list-disc list-inside space-y-1 mt-2 text-xs text-muted-foreground">
                      {isCrypto ? (
                        <>
                          <li>Verify the recipient address carefully. Incorrect addresses can result in permanent loss of funds.</li>
                          <li>This transaction cannot be reversed once completed.</li>
                          <li>Network fee: {balanceInfo?.fee} {balanceInfo?.symbol}</li>
                        </>
                      ) : (
                        <>
                          <li>Ensure your bank account details are correct and up to date.</li>
                          <li>Minimum withdrawal: 10 {balanceInfo?.symbol}</li>
                          <li>Fee: {balanceInfo?.fee} {balanceInfo?.symbol}</li>
                          <li>Weekends and holidays may extend processing times.</li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default WithdrawPage;
