import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { PaymentMethod } from '@/components/payment_method/PaymentMethodModel';
import { useWallet } from '@/contexts/WalletContext';
import { 
  Shield, 
  CreditCard, 
  Clock, 
  Check, 
  Zap, 
  Lock, 
  Globe, 
  ChevronRight,
  Wallet,
  Loader2,
  BadgeCheck,
  Sparkles
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import defaultImage from '@/../assets/Flutterwave-Logo.png';

interface PaymentMethodSelectorProps {
  assetId: string;
  assetName: string;
  countryCode: string;
  onContinue: (paymentMethod: PaymentMethod) => void;
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({ 
  assetId, 
  assetName, 
  countryCode, 
  onContinue 
}) => {
  const { fetchPaymentMethods, paymentMethods } = useWallet();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const fetchMethods = async () => {
      try {
        await fetchPaymentMethods(countryCode, assetId);
      } catch (error) {
        toast.error("Failed to load payment methods");
      } finally {
        setLoading(false);
      }
    };

    fetchMethods();
  }, [assetId, countryCode, fetchPaymentMethods]);

  const handleSelectPaymentMethod = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
    toast.success(`${method.paymentName} selected`, {
      description: method.paymentMethodDescription,
    });
  };

  const handleContinue = async () => {
    if (!selectedPaymentMethod) return;
    
    setIsProcessing(true);
    try {
      await onContinue(selectedPaymentMethod);
    } catch (error) {
      toast.error("Payment processing failed", {
        description: "Please try again or select another method",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const renderCreditCardMethod = (method: PaymentMethod) => {
    const isSelected = selectedPaymentMethod?._id === method._id;
    
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <Card
          className={cn(
            "relative overflow-hidden cursor-pointer transition-all duration-300 group",
            "border-2 hover:border-primary/50",
            "bg-gradient-to-br from-background to-muted/30",
            "shadow-sm hover:shadow-md",
            isSelected ? "border-primary ring-2 ring-primary/20" : "border-transparent"
          )}
          onClick={() => handleSelectPaymentMethod(method)}
        >
          {/* Premium Badge */}
          {isSelected && (
            <div className="absolute top-4 right-4 bg-gradient-to-r from-primary to-blue-600 text-primary-foreground px-3 py-1 rounded-full text-xs font-medium flex items-center gap-1 z-10">
              <BadgeCheck className="h-3 w-3" />
              <span>Recommended</span>
            </div>
          )}

          <CardHeader className="space-y-4 pb-4">
            <div className="flex items-start gap-4">
              <div className={cn(
                "p-3 rounded-xl transition-all duration-300",
                "group-hover:bg-primary/10",
                isSelected ? "bg-primary text-primary-foreground" : "bg-muted"
              )}>
                <CreditCard className="h-6 w-6" />
              </div>
              <div className="space-y-1 flex-1">
                <CardTitle className="text-xl font-semibold flex items-center gap-2">
                  Credit/Debit Card
                  {isSelected && <Sparkles className="h-4 w-4 text-yellow-400" />}
                </CardTitle>
                <CardDescription className="text-sm">
                  Instant processing with bank-level security
                </CardDescription>
              </div>
            </div>
          </CardHeader>

          <CardContent className="space-y-4">
            {/* Features Grid */}
            <div className="grid grid-cols-2 gap-3">
              {[
                { icon: <Zap className="h-4 w-4" />, text: "Instant", color: "text-yellow-500" },
                { icon: <Lock className="h-4 w-4" />, text: "Secure", color: "text-green-500" },
                { icon: <Globe className="h-4 w-4" />, text: "Global", color: "text-blue-500" },
                { icon: <Wallet className="h-4 w-4" />, text: "No Fees", color: "text-purple-500" },
              ].map((feature, index) => (
                <div key={index} className="flex items-center gap-2 text-sm">
                  <span className={cn("h-4 w-4", feature.color)}>{feature.icon}</span>
                  <span>{feature.text}</span>
                </div>
              ))}
            </div>

            {/* Payment Info */}
            <div className="space-y-2 pt-4 border-t border-muted">
              {[
                { label: "Processing Time", value: "Instant", icon: <Clock className="h-4 w-4 text-muted-foreground" /> },
                { label: "Success Rate", value: "99.9%", icon: <Check className="h-4 w-4 text-muted-foreground" /> },
                { label: "Daily Limit", value: "Up to $50,000", icon: <Shield className="h-4 w-4 text-muted-foreground" /> },
              ].map((info, index) => (
                <div key={index} className="flex justify-between items-center text-sm">
                  <div className="flex items-center gap-2 text-muted-foreground">
                    {info.icon}
                    <span>{info.label}</span>
                  </div>
                  <span className="font-medium">{info.value}</span>
                </div>
              ))}
            </div>

            {/* Card Logos */}
            <div className="flex items-center justify-between pt-4 border-t border-muted">
              <div className="flex gap-2">
                {['visa', 'mastercard', 'amex'].map((card) => (
                  <div key={card} className="bg-background p-1.5 rounded-md border">
                    <img 
                      src={`/${card}.svg`} 
                      alt={card} 
                      className="h-5 object-contain" 
                    />
                  </div>
                ))}
              </div>
              <ChevronRight className={cn(
                "h-5 w-5 transition-all duration-300 text-muted-foreground",
                isSelected && "transform rotate-90 text-primary"
              )} />
            </div>
          </CardContent>

          {/* Selection Indicator */}
          {isSelected && (
            <div className="absolute inset-x-0 bottom-0 h-1.5 bg-gradient-to-r from-primary to-blue-600" />
          )}
        </Card>
      </motion.div>
    );
  };

  const renderAlternativeMethod = (method: PaymentMethod) => {
    const isSelected = selectedPaymentMethod?._id === method._id;
    
    return (
      <motion.div
        key={method._id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <Card
          className={cn(
            "relative overflow-hidden cursor-pointer transition-all duration-300 group",
            "border hover:border-primary/50",
            "bg-gradient-to-br from-background to-muted/20",
            "shadow-sm hover:shadow-md",
            isSelected ? "border-primary ring-2 ring-primary/20" : "border-muted"
          )}
          onClick={() => handleSelectPaymentMethod(method)}
        >
          {/* Popular badge for some methods */}
          {method.popular && (
            <div className="absolute top-4 right-4 bg-amber-500/10 text-amber-600 px-2 py-1 rounded-full text-xs font-medium">
              Popular
            </div>
          )}

          <CardHeader className="pb-3">
            <div className="flex items-center gap-4">
              <div className={cn(
                "p-2 rounded-lg border bg-background",
                "group-hover:bg-primary/10 transition-colors",
                isSelected && "bg-primary/10 border-primary/20"
              )}>
                <img 
                  src={method.paymentMethodImage || defaultImage} 
                  alt={method.paymentName} 
                  className="h-12 w-12 object-contain" 
                  onError={(e) => (e.currentTarget.src = defaultImage)}
                />
              </div>
              <div>
                <CardTitle className="text-lg font-semibold">{method.paymentName}</CardTitle>
                <CardDescription className="text-sm line-clamp-1">
                  {method.paymentMethodDescription}
                </CardDescription>
              </div>
            </div>
          </CardHeader>

          <CardContent className="space-y-3">
            <div className="grid grid-cols-2 gap-3 text-sm">
              <div className="flex items-center gap-2">
                <Clock className="h-4 w-4 text-muted-foreground" />
                <span>Processing: {method.processingTime || '1-3 days'}</span>
              </div>
              <div className="flex items-center gap-2">
                <Wallet className="h-4 w-4 text-muted-foreground" />
                <span>Fees: {method.fees || '0.5-2%'}</span>
              </div>
            </div>

            <div className="pt-3 border-t border-muted">
              <div className="flex justify-between items-center text-sm">
                <span className="text-muted-foreground">Exchange Rate</span>
                <span className="font-medium">{method.rate || 'Market rate'}</span>
              </div>
            </div>
          </CardContent>

          {isSelected && (
            <div className="absolute inset-x-0 bottom-0 h-1 bg-gradient-to-r from-primary to-blue-600" />
          )}
        </Card>
      </motion.div>
    );
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64 gap-4">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <p className="text-muted-foreground">Loading payment methods...</p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold tracking-tight">Select Payment Method</h2>
          <div className="flex items-center gap-2 text-sm text-muted-foreground bg-muted/40 px-3 py-1.5 rounded-full">
            <Shield className="h-4 w-4 text-green-500" />
            <span>256-bit SSL encryption</span>
          </div>
        </div>
        <p className="text-muted-foreground">
          Choose how you want to fund your {assetName} purchase
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AnimatePresence>
          {paymentMethods.map(method => 
            method.paymentType === 'card' 
              ? renderCreditCardMethod(method)
              : renderAlternativeMethod(method)
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {selectedPaymentMethod && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            className="flex justify-end"
          >
            <Button
              onClick={handleContinue}
              size="lg"
              className="min-w-[220px] shadow-lg"
              disabled={isProcessing}
            >
              {isProcessing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  Continue with {selectedPaymentMethod.paymentName}
                  <ChevronRight className="ml-2 h-4 w-4" />
                </>
              )}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PaymentMethodSelector;