/**
 * Advanced UIController - Enterprise-grade DOM interactions for AI agent
 * Provides methods for sophisticated UI manipulation with visual feedback and confirmations
 */
class UIController {
  private debugMode: boolean = false;
  private highlightColor: string = 'rgba(75, 85, 255, 0.3)';
  private highlightBorder: string = '2px solid rgba(75, 85, 255, 0.8)';
  private activeHighlights: HTMLElement[] = [];
  private overlayContainer: HTMLDivElement | null = null;
  private userConfirmationCallback: ((confirmed: boolean) => void) | null = null;
  private executedActions: {type: string, element: string, timestamp: number}[] = [];
  private lastScrollPosition: number = 0;
  
  constructor(options: { 
    debug?: boolean;
    highlightColor?: string;
    highlightBorder?: string;
    trackActions?: boolean;
  } = {}) {
    this.debugMode = options.debug || false;
    if (options.highlightColor) this.highlightColor = options.highlightColor;
    if (options.highlightBorder) this.highlightBorder = options.highlightBorder;
    
    // Create overlay container for visual feedbacks
    this.initOverlayContainer();
    
    // Capture viewport changes
    window.addEventListener('resize', this.handleViewportChange);
    window.addEventListener('scroll', this.handleScrollEvent);
    
    this.logDebug('UIController initialized in', this.debugMode ? 'debug mode' : 'normal mode');
  }
  
  /**
   * Initialize overlay container for visual feedbacks and confirmations
   */
  private initOverlayContainer(): void {
    if (document.getElementById('ai-ui-controller-overlay')) return;
    
    this.overlayContainer = document.createElement('div');
    this.overlayContainer.id = 'ai-ui-controller-overlay';
    this.overlayContainer.style.position = 'fixed';
    this.overlayContainer.style.zIndex = '9999';
    this.overlayContainer.style.pointerEvents = 'none'; // Don't block interactions by default
    this.overlayContainer.style.top = '0';
    this.overlayContainer.style.left = '0';
    this.overlayContainer.style.width = '100%';
    this.overlayContainer.style.height = '100%';
    
    document.body.appendChild(this.overlayContainer);
  }
  
  /**
   * Handle viewport changes (resize, scroll, etc)
   */
  private handleViewportChange = (): void => {
    // Update any active highlights or overlays
    this.refreshActiveHighlights();
  }
  
  /**
   * Handle scroll events to maintain highlights and visual elements
   */
  private handleScrollEvent = (): void => {
    const currentPosition = window.scrollY;
    const scrollDelta = currentPosition - this.lastScrollPosition;
    this.lastScrollPosition = currentPosition;
    
    // Update highlights with scroll delta
    this.refreshActiveHighlights();
  }
  
  /**
   * Find an element using enhanced selection strategies with visual feedback
   */
  public async findElement(
    selector: string, 
    options: {
      fallbackSelectors?: string[],
      highlight?: boolean,
      searchInIframes?: boolean,
      searchInShadowDOM?: boolean,
      timeout?: number,
      retryInterval?: number
    } = {}
  ): Promise<Element | null> {
    const {
      fallbackSelectors = [],
      highlight = false,
      searchInIframes = true,
      searchInShadowDOM = true,
      timeout = 5000,
      retryInterval = 100
    } = options;
    
    // Enhanced strategies including shadow DOM and iframe search
    const strategies = [
      // Direct selector (ID, class, etc)
      () => document.querySelector(selector),
      
      // Try by ID without # prefix
      () => document.getElementById(selector),
      
      // Try by button or link text (with exact match)
      () => {
        const buttons = Array.from(document.querySelectorAll('button, a, [role="button"], .btn, input[type="submit"], input[type="button"]'));
        return buttons.find(el => el.textContent?.trim().toLowerCase() === selector.toLowerCase());
      },
      
      // Try finding clickable elements by partial text
      () => {
        const clickables = Array.from(document.querySelectorAll('button, a, [role="button"], .btn, input[type="submit"], input[type="button"]'));
        return clickables.find(el => el.textContent?.trim().toLowerCase().includes(selector.toLowerCase()));
      },
      
      // Try finding by aria attributes (enhanced accessibility)
      () => document.querySelector(`[aria-label="${selector}"], [aria-description="${selector}"], [title="${selector}"]`),
      
      // Try finding by aria attributes with partial match
      () => {
        const elements = Array.from(document.querySelectorAll('[aria-label], [aria-description], [title]'));
        return elements.find(el => {
          const ariaLabel = el.getAttribute('aria-label')?.toLowerCase() || '';
          const ariaDesc = el.getAttribute('aria-description')?.toLowerCase() || '';
          const title = el.getAttribute('title')?.toLowerCase() || '';
          const selectorLower = selector.toLowerCase();
          
          return ariaLabel.includes(selectorLower) || 
                 ariaDesc.includes(selectorLower) || 
                 title.includes(selectorLower);
        });
      },

      // Try finding by name or id attribute (case insensitive)
      () => {
        const selectorLower = selector.toLowerCase();
        const elements = Array.from(document.querySelectorAll('[name], [id]'));
        return elements.find(el => {
          const name = el.getAttribute('name')?.toLowerCase() || '';
          const id = el.getAttribute('id')?.toLowerCase() || '';
          return name === selectorLower || id === selectorLower;
        });
      },
      
      // Try finding by placeholder with exact match
      () => document.querySelector(`input[placeholder="${selector}"], textarea[placeholder="${selector}"]`),
      
      // Try finding by placeholder with partial match
      () => document.querySelector(`input[placeholder*="${selector}"], textarea[placeholder*="${selector}"]`),
      
      // Try finding by text content with exact match (case-insensitive)
      () => {
        const iterator = document.createNodeIterator(document.body, NodeFilter.SHOW_TEXT);
        let node;
        while (node = iterator.nextNode()) {
          const text = node.textContent?.trim().toLowerCase() || '';
          if (text === selector.toLowerCase() && node.parentElement) {
            return node.parentElement;
          }
        }
        return null;
      },
      
      // Try finding by text content with partial match
      () => {
        const iterator = document.createNodeIterator(document.body, NodeFilter.SHOW_TEXT);
        let node;
        while (node = iterator.nextNode()) {
          const text = node.textContent?.trim().toLowerCase() || '';
          if (text.includes(selector.toLowerCase()) && node.parentElement) {
            return node.parentElement;
          }
        }
        return null;
      },
      
      // Try finding by label association (for inputs)
      () => {
        // First try to find the label
        const labels = Array.from(document.querySelectorAll('label'));
        const matchingLabel = labels.find(label => 
          label.textContent?.trim().toLowerCase() === selector.toLowerCase() ||
          label.textContent?.trim().toLowerCase().includes(selector.toLowerCase())
        );
        
        if (matchingLabel) {
          // Try to find associated input via 'for' attribute
          const forId = matchingLabel.getAttribute('for');
          if (forId) {
            return document.getElementById(forId);
          }
          
          // Try to find nested input
          return matchingLabel.querySelector('input, textarea, select, [role="textbox"]');
        }
        return null;
      },
      
      // Try finding using data- attributes
      () => {
        // Look for data-test, data-testid, data-cy, data-qa, data-automation-id
        return document.querySelector(
          `[data-test="${selector}"], [data-testid="${selector}"], [data-cy="${selector}"], [data-qa="${selector}"], [data-automation-id="${selector}"]`
        );
      },
      
      // Try using more complex CSS attribute selectors
      () => {
        // Check for elements with class/id containing the selector
        return document.querySelector(
          `[class*="${selector}"], [id*="${selector}"]`
        );
      }
    ];
    
    // Add shadow DOM search if enabled
    if (searchInShadowDOM) {
      strategies.push(() => this.searchInShadowDOM(selector));
    }
    
    // Add iframe search if enabled
    if (searchInIframes) {
      strategies.push(() => this.searchInIframes(selector));
    }

    // Try with timeout and retries if needed
    const startTime = Date.now();
    
    while (Date.now() - startTime < timeout) {
      // Try each strategy
      for (const strategy of strategies) {
        try {
          const element = strategy();
          if (element) {
            this.logDebug('Element found with strategy:', strategy.name || 'anonymous', 'for selector:', selector);
            
            // Apply highlighting if requested
            if (highlight && element instanceof HTMLElement) {
              this.highlightElement(element, `Found: ${selector}`);
            }
            
            return element;
          }
        } catch (e) {
          // Ignore errors from invalid selectors
        }
      }
      
      // Try fallback selectors if provided
      for (const fallbackSelector of fallbackSelectors) {
        try {
          const element = await this.findElement(fallbackSelector, { 
            highlight, 
            searchInIframes: false, // Avoid recursive iframe search
            searchInShadowDOM: false, // Avoid recursive shadow DOM search
            timeout: 0 // Don't retry for fallbacks
          });
          
          if (element) {
            this.logDebug('Element found with fallback selector:', fallbackSelector);
            return element;
          }
        } catch (e) {
          // Ignore errors from invalid selectors
        }
      }
      
      // If we still haven't found anything, wait before retrying
      if (Date.now() - startTime < timeout) {
        await new Promise(resolve => setTimeout(resolve, retryInterval));
      } else {
        break;
      }
    }
    
    this.logDebug('Element not found after all strategies for:', selector);
    return null;
  }
  
  /**
   * Search for elements within Shadow DOM trees
   */
  private searchInShadowDOM(selector: string): Element | null {
    // Function to recursively search shadow roots
    const searchShadowTree = (root: DocumentFragment | Document): Element | null => {
      // Try to find element in this shadow root
      const element = root.querySelector(selector);
      if (element) return element;
      
      // Get all elements with shadow roots
      const elementsWithShadowRoots = Array.from(root.querySelectorAll('*'))
        .filter(el => el.shadowRoot !== null);
      
      // Search each shadow root
      for (const el of elementsWithShadowRoots) {
        if (el.shadowRoot) {
          const found = searchShadowTree(el.shadowRoot);
          if (found) return found;
        }
      }
      
      return null;
    };
    
    return searchShadowTree(document);
  }
  
  /**
   * Search for elements within iframes
   */
  private searchInIframes(selector: string): Element | null {
    try {
      // Get all iframes
      const iframes = Array.from(document.querySelectorAll('iframe'));
      
      for (const iframe of iframes) {
        try {
          // Only proceed if we can access iframe content (same origin)
          const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
          
          if (iframeDocument) {
            // Try to find element in iframe
            const element = iframeDocument.querySelector(selector);
            if (element) return element;
          }
        } catch (e) {
          // Skip cross-origin iframes (security restriction)
          this.logDebug('Cross-origin iframe access restricted:', e);
        }
      }
    } catch (e) {
      this.logDebug('Error searching in iframes:', e);
    }
    
    return null;
  }

  /**
   * Visually highlight an element to show user what the AI is interacting with
   */
  public highlightElement(
    element: HTMLElement, 
    label?: string, 
    duration: number = 2000
  ): HTMLElement {
    // Create highlight overlay
    const highlighter = document.createElement('div');
    highlighter.className = 'ai-ui-controller-highlight';
    
    // Get element position
    const rect = element.getBoundingClientRect();
    
    // Style the highlighter
    highlighter.style.position = 'absolute';
    highlighter.style.left = `${rect.left + window.scrollX}px`;
    highlighter.style.top = `${rect.top + window.scrollY}px`;
    highlighter.style.width = `${rect.width}px`;
    highlighter.style.height = `${rect.height}px`;
    highlighter.style.backgroundColor = this.highlightColor;
    highlighter.style.border = this.highlightBorder;
    highlighter.style.borderRadius = '3px';
    highlighter.style.zIndex = '9998';
    highlighter.style.pointerEvents = 'none';
    highlighter.style.boxSizing = 'border-box';
    highlighter.style.transition = 'opacity 0.3s ease-in-out';
    
    // Add label if provided
    if (label) {
      const labelElement = document.createElement('div');
      labelElement.textContent = label;
      labelElement.style.position = 'absolute';
      labelElement.style.top = '-24px';
      labelElement.style.left = '0';
      labelElement.style.backgroundColor = 'rgba(47, 53, 178, 0.9)';
      labelElement.style.color = 'white';
      labelElement.style.padding = '2px 8px';
      labelElement.style.borderRadius = '4px';
      labelElement.style.fontSize = '12px';
      labelElement.style.fontWeight = 'bold';
      labelElement.style.whiteSpace = 'nowrap';
      
      highlighter.appendChild(labelElement);
    }
    
    // Add to overlay container
    if (this.overlayContainer) {
      this.overlayContainer.appendChild(highlighter);
      
      // Track active highlights
      this.activeHighlights.push(highlighter);
    }
    
    // Remove highlight after duration
    setTimeout(() => {
      highlighter.style.opacity = '0';
      setTimeout(() => {
        if (highlighter.parentElement) {
          highlighter.parentElement.removeChild(highlighter);
          
          // Remove from active highlights
          const index = this.activeHighlights.indexOf(highlighter);
          if (index !== -1) {
            this.activeHighlights.splice(index, 1);
          }
        }
      }, 300);
    }, duration);
    
    return highlighter;
  }
  
  /**
   * Refresh positions of active highlight elements
   */
  private refreshActiveHighlights(): void {
    this.activeHighlights.forEach(highlighter => {
      // Find the highlighted element by data attribute
      const elementSelector = highlighter.getAttribute('data-element-selector');
      if (!elementSelector) return;
      
      const element = document.querySelector(elementSelector);
      if (element) {
        const rect = element.getBoundingClientRect();
        
        // Update position
        highlighter.style.left = `${rect.left + window.scrollX}px`;
        highlighter.style.top = `${rect.top + window.scrollY}px`;
      }
    });
  }

  /**
   * Show a confirmation dialog to user
   */
  public async getUserConfirmation(
    message: string,
    options: {
      title?: string,
      confirmText?: string,
      cancelText?: string,
      timeout?: number,
      isDestructive?: boolean
    } = {}
  ): Promise<boolean> {
    const {
      title = 'Confirm Action',
      confirmText = 'Confirm',
      cancelText = 'Cancel',
      timeout = 20000,
      isDestructive = false
    } = options;
    
    // Create confirmation dialog
    const dialog = document.createElement('div');
    dialog.className = 'ai-ui-controller-confirmation';
    
    // Style the dialog
    dialog.style.position = 'fixed';
    dialog.style.top = '50%';
    dialog.style.left = '50%';
    dialog.style.transform = 'translate(-50%, -50%)';
    dialog.style.backgroundColor = 'white';
    dialog.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.15)';
    dialog.style.borderRadius = '8px';
    dialog.style.padding = '20px';
    dialog.style.maxWidth = '400px';
    dialog.style.width = '90%';
    dialog.style.zIndex = '10000';
    dialog.style.color = '#333';
    dialog.style.display = 'flex';
    dialog.style.flexDirection = 'column';
    dialog.style.gap = '16px';
    
    // Add title
    const titleElement = document.createElement('h3');
    titleElement.textContent = title;
    titleElement.style.margin = '0';
    titleElement.style.color = '#111';
    dialog.appendChild(titleElement);
    
    // Add message
    const messageElement = document.createElement('p');
    messageElement.textContent = message;
    messageElement.style.margin = '0';
    dialog.appendChild(messageElement);
    
    // Add timer indicator if timeout provided
    let timerElement: HTMLDivElement | null = null;
    let intervalId: number | null = null;
    
    if (timeout > 0) {
      timerElement = document.createElement('div');
      timerElement.style.width = '100%';
      timerElement.style.height = '4px';
      timerElement.style.backgroundColor = '#eee';
      timerElement.style.borderRadius = '2px';
      timerElement.style.overflow = 'hidden';
      
      const progress = document.createElement('div');
      progress.style.height = '100%';
      progress.style.backgroundColor = isDestructive ? '#e53e3e' : '#4299e1';
      progress.style.width = '100%';
      progress.style.transition = 'width linear';
      timerElement.appendChild(progress);
      
      dialog.appendChild(timerElement);
      
      const startTime = Date.now();
      const updateProgress = () => {
        const elapsed = Date.now() - startTime;
        const percent = 100 - (elapsed / timeout * 100);
        progress.style.width = `${Math.max(0, percent)}%`;
        
        if (elapsed >= timeout) {
          if (intervalId !== null) {
            clearInterval(intervalId);
            intervalId = null;
          }
          // Auto-cancel on timeout
          removeDialog(false);
        }
      };
      
      intervalId = window.setInterval(updateProgress, 100);
    }
    
    // Add buttons
    const buttonsContainer = document.createElement('div');
    buttonsContainer.style.display = 'flex';
    buttonsContainer.style.justifyContent = 'flex-end';
    buttonsContainer.style.gap = '12px';
    buttonsContainer.style.marginTop = '8px';
    
    // Cancel button
    const cancelButton = document.createElement('button');
    cancelButton.textContent = cancelText;
    cancelButton.style.padding = '8px 16px';
    cancelButton.style.borderRadius = '4px';
    cancelButton.style.border = '1px solid #ddd';
    cancelButton.style.backgroundColor = '#f9f9f9';
    cancelButton.style.cursor = 'pointer';
    cancelButton.onclick = () => removeDialog(false);
    
    // Confirm button
    const confirmButton = document.createElement('button');
    confirmButton.textContent = confirmText;
    confirmButton.style.padding = '8px 16px';
    confirmButton.style.borderRadius = '4px';
    confirmButton.style.border = 'none';
    confirmButton.style.backgroundColor = isDestructive ? '#e53e3e' : '#4299e1';
    confirmButton.style.color = 'white';
    confirmButton.style.cursor = 'pointer';
    confirmButton.onclick = () => removeDialog(true);
    
    // Hover states
    cancelButton.onmouseover = () => {
      cancelButton.style.backgroundColor = '#eee';
    };
    cancelButton.onmouseout = () => {
      cancelButton.style.backgroundColor = '#f9f9f9';
    };
    
    confirmButton.onmouseover = () => {
      confirmButton.style.backgroundColor = isDestructive ? '#c53030' : '#3182ce';
    };
    confirmButton.onmouseout = () => {
      confirmButton.style.backgroundColor = isDestructive ? '#e53e3e' : '#4299e1';
    };
    
    buttonsContainer.appendChild(cancelButton);
    buttonsContainer.appendChild(confirmButton);
    dialog.appendChild(buttonsContainer);
    
    // Add overlay for modal effect
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '9999';
    
    // Add to overlay container with pointer events enabled
    if (this.overlayContainer) {
      this.overlayContainer.style.pointerEvents = 'auto'; // Enable pointer events for interaction
      this.overlayContainer.appendChild(overlay);
      this.overlayContainer.appendChild(dialog);
    }
    
    // Create a promise that resolves when the dialog is answered
    return new Promise<boolean>(resolve => {
      this.userConfirmationCallback = (confirmed: boolean) => {
        resolve(confirmed);
      };
      
      // Function to remove dialog and resolve promise
      const removeDialog = (confirmed: boolean) => {
        if (intervalId !== null) {
          clearInterval(intervalId);
          intervalId = null;
        }
        
        if (this.overlayContainer) {
          // Fade out
          overlay.style.opacity = '0';
          dialog.style.opacity = '0';
          dialog.style.transform = 'translate(-50%, -60%)';
          dialog.style.transition = 'opacity 0.2s ease-out, transform 0.2s ease-out';
          
          setTimeout(() => {
            if (this.overlayContainer) {
              this.overlayContainer.removeChild(overlay);
              this.overlayContainer.removeChild(dialog);
              this.overlayContainer.style.pointerEvents = 'none'; // Reset to not block interactions
            }
          }, 200);
        }
        
        // Call the callback
        if (this.userConfirmationCallback) {
          this.userConfirmationCallback(confirmed);
          this.userConfirmationCallback = null;
        }
      };
    });
  }

  /**
   * Show a toast notification to the user
   */
  public showToast(
    message: string,
    options: {
      type?: 'info' | 'success' | 'warning' | 'error',
      duration?: number,
      icon?: string
    } = {}
  ): void {
    const {
      type = 'info',
      duration = 3000,
      icon
    } = options;
    
    // Create toast element
    const toast = document.createElement('div');
    toast.className = 'ai-ui-controller-toast';
    
    // Style based on type
    const colors = {
      info: { bg: '#4299e1', color: 'white' },
      success: { bg: '#48bb78', color: 'white' },
      warning: { bg: '#ecc94b', color: 'black' },
      error: { bg: '#e53e3e', color: 'white' }
    };
    
    // Style the toast
    toast.style.position = 'fixed';
    toast.style.bottom = '24px';
    toast.style.right = '24px';
    toast.style.backgroundColor = colors[type].bg;
    toast.style.color = colors[type].color;
    toast.style.padding = '12px 20px';
    toast.style.borderRadius = '6px';
    toast.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.15)';
    toast.style.zIndex = '10000';
    toast.style.maxWidth = '300px';
    toast.style.transform = 'translateY(20px)';
    toast.style.opacity = '0';
    toast.style.transition = 'transform 0.3s ease-out, opacity 0.3s ease-out';
    toast.style.display = 'flex';
    toast.style.alignItems = 'center';
    toast.style.gap = '8px';
    
    // Add icon if provided
    if (icon) {
      const iconElement = document.createElement('span');
      iconElement.innerHTML = icon;
      iconElement.style.display = 'flex';
      iconElement.style.alignItems = 'center';
      iconElement.style.justifyContent = 'center';
      toast.appendChild(iconElement);
    } else {
      // Default icons based on type
      const iconElement = document.createElement('span');
      iconElement.innerHTML = type === 'info' ? 'ℹ️' :
                              type === 'success' ? '✅' :
                              type === 'warning' ? '⚠️' : '❌';
      toast.appendChild(iconElement);
    }
    
    // Add message
    const messageElement = document.createElement('span');
    messageElement.textContent = message;
    toast.appendChild(messageElement);
    
    // Add to overlay container
    if (this.overlayContainer) {
      this.overlayContainer.appendChild(toast);
      
      // Animate in
      setTimeout(() => {
        toast.style.transform = 'translateY(0)';
        toast.style.opacity = '1';
      }, 10);
      
      // Remove after duration
      setTimeout(() => {
        toast.style.transform = 'translateY(20px)';
        toast.style.opacity = '0';
        
        setTimeout(() => {
          if (toast.parentElement) {
            toast.parentElement.removeChild(toast);
          }
        }, 300);
      }, duration);
    }
  }

  /**
   * Click on an element with enhanced visual feedback
   */
  public async clickElement(
    selector: string, 
    options: {
      fallbackSelectors?: string[],
      description?: string,
      requireConfirmation?: boolean,
      confirmationMessage?: string,
      showFeedback?: boolean,
      simulateHover?: boolean,
      waitForNavigation?: boolean,
      navigationTimeout?: number
    } = {}
  ): Promise<boolean> {
    const {
      fallbackSelectors = [],
      description = 'element',
      requireConfirmation = false,
      confirmationMessage,
      showFeedback = true,
      simulateHover = true,
      waitForNavigation = false,
      navigationTimeout = 5000
    } = options;
    
    // Find element with highlighting
    const element = await this.findElement(selector, {
      fallbackSelectors,
      highlight: showFeedback
    });
    
    if (!element) {
      this.logDebug(`Could not find element to click: "${selector}"`);
      if (showFeedback) {
        this.showToast(`Could not find "${description}" to click`, { type: 'error' });
      }
      return false;
    }
    
    // Get confirmation if required
    if (requireConfirmation) {
      const message = confirmationMessage || `Do you want me to click on "${description}"?`;
      const confirmed = await this.getUserConfirmation(message);
      
      if (!confirmed) {
        this.showToast(`Action canceled: Did not click on "${description}"`, { type: 'info' });
        return false;
      }
    }
    
    try {
      // Track current URL for navigation detection
      const startUrl = window.location.href;
      
      // Scroll element into view if needed
      if (element instanceof HTMLElement) {
        // Add visual feedback
        if (showFeedback) {
          this.highlightElement(element, `Clicking: ${description}`, 1500);
        }
        
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        
        // Add a slight delay to allow smooth scrolling
        await new Promise(resolve => setTimeout(resolve, 300));
        
        // Simulate hover if requested
        if (simulateHover) {
          const mouseoverEvent = new MouseEvent('mouseover', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          
          element.dispatchEvent(mouseoverEvent);
          
          // Wait a moment to simulate hover
          await new Promise(resolve => setTimeout(resolve, 200));
        }
        
        // If the element is a link or button, use click()
        if (
          element instanceof HTMLAnchorElement || 
          element instanceof HTMLButtonElement ||
          element.tagName === 'BUTTON' ||
          element.tagName === 'A' ||
          element.getAttribute('role') === 'button' ||
          element.classList.contains('btn') ||
          element.classList.contains('button')
        ) {
          element.click();
          this.logDebug(`Clicked on ${description}`);
          
          // Show success toast
          if (showFeedback) {
            this.showToast(`Clicked on "${description}"`, { type: 'success' });
          }
          
          // Record this action
          this.executedActions.push({
            type: 'click',
            element: description,
            timestamp: Date.now()
          });
          
          // Wait for navigation if requested
          if (waitForNavigation) {
            const navigationResult = await this.waitForNavigation(startUrl, navigationTimeout);
            
            if (!navigationResult) {
              this.logDebug(`Navigation did not occur after clicking on ${description}`);
              if (showFeedback) {
                this.showToast(`Clicked on "${description}" but no navigation detected`, { type: 'warning' });
              }
            }
          }
          
          return true;
        } 
        // Otherwise create and dispatch a more sophisticated click event
        else {
          // First try focus
          if ('focus' in element && typeof element.focus === 'function') {
            element.focus();
          }
          
          // Then dispatch click events
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
            detail: 1 // Number of clicks
          });
          
          element.dispatchEvent(clickEvent);
          
          // For good measure, also try mousedown/mouseup
          const mousedownEvent = new MouseEvent('mousedown', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          
          const mouseupEvent = new MouseEvent('mouseup', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          
          element.dispatchEvent(mousedownEvent);
          element.dispatchEvent(mouseupEvent);
          
          this.logDebug(`Dispatched multiple click events on ${description}`);
          
          // Show success toast
          if (showFeedback) {
            this.showToast(`Clicked on "${description}"`, { type: 'success' });
          }
          
          // Record this action
          this.executedActions.push({
            type: 'click',
            element: description,
            timestamp: Date.now()
          });
          
          // Wait for navigation if requested
          if (waitForNavigation) {
            const navigationResult = await this.waitForNavigation(startUrl, navigationTimeout);
            
            if (!navigationResult) {
              this.logDebug(`Navigation did not occur after clicking on ${description}`);
            }
          }
          
          return true;
        }
      }
      
      return false;
    } catch (error) {
      this.logDebug(`Error clicking element: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error clicking on "${description}": ${error.message}`, { type: 'error' });
      }
      
      return false;
    }
  }
  
  /**
   * Fill an input field with text and enhanced visual feedback
   */
  public async fillInput(
    selector: string, 
    text: string,
    options: {
      clear?: boolean,
      submit?: boolean,
      mask?: boolean,
      showFeedback?: boolean,
      requireConfirmation?: boolean,
      description?: string,
      validateAs?: 'email' | 'phone' | 'number' | 'url' | RegExp,
      simulateTyping?: boolean,
      typingSpeed?: number
    } = {}
  ): Promise<boolean> {
    const {
      clear = true,
      submit = false,
      mask = false,
      showFeedback = true,
      requireConfirmation = false,
      description,
      validateAs,
      simulateTyping = false,
      typingSpeed = 50
    } = options;
    
    // Validate input if validation type provided
    if (validateAs) {
      const isValid = this.validateInput(text, validateAs);
      
      if (!isValid) {
        const validationType = typeof validateAs === 'string' ? validateAs : 'pattern';
        this.logDebug(`Input validation failed for ${validationType}: ${text}`);
        
        if (showFeedback) {
          this.showToast(`Invalid ${validationType} format`, { type: 'error' });
        }
        
        return false;
      }
    }
    
    const element = await this.findElement(selector, {
      highlight: showFeedback
    });
    
    if (!element) {
      this.logDebug(`Could not find input element: "${selector}"`);
      
      if (showFeedback) {
        this.showToast(`Could not find input field "${description || selector}"`, { type: 'error' });
      }
      
      return false;
    }
    
    // Get confirmation if required
    if (requireConfirmation) {
      const displayText = mask ? '•'.repeat(Math.min(text.length, 10)) : text;
      const fieldName = description || selector;
      const message = `Do you want me to enter "${displayText}" into the "${fieldName}" field?`;
      
      const confirmed = await this.getUserConfirmation(message);
      
      if (!confirmed) {
        this.showToast(`Action canceled: Did not fill "${fieldName}"`, { type: 'info' });
        return false;
      }
    }
    
    try {
      if (
        element instanceof HTMLInputElement || 
        element instanceof HTMLTextAreaElement ||
        element instanceof HTMLSelectElement
      ) {
        // Show visual feedback
        if (showFeedback && element instanceof HTMLElement) {
          const displayText = mask ? '•'.repeat(Math.min(text.length, 10)) : text;
          this.highlightElement(element, `Filling: ${displayText}`, 1500);
        }
        
        // Scroll element into view if needed
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        
        // Wait a moment for scrolling
        await new Promise(resolve => setTimeout(resolve, 300));
        
        // Focus the element
        element.focus();
        
        // Clear the field if requested
        if (clear) {
          element.value = '';
          
          // Dispatch events to notify of clearing
          element.dispatchEvent(new Event('input', { bubbles: true }));
          element.dispatchEvent(new Event('change', { bubbles: true }));
          
          // Small delay after clearing
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        
        if (simulateTyping) {
          // Simulate typing character by character
          const originalValue = element.value;
          
          for (let i = 0; i < text.length; i++) {
            // Set value up to this character
            element.value = originalValue + text.substring(0, i + 1);
            
            // Dispatch input event for each character
            element.dispatchEvent(new Event('input', { bubbles: true }));
            
            // Wait between characters
            await new Promise(resolve => setTimeout(resolve, typingSpeed));
          }
          
          // Final change event
          element.dispatchEvent(new Event('change', { bubbles: true }));
        } else {
          // Set the value directly
          element.value = text;
          
          // Dispatch events to trigger any listeners
          element.dispatchEvent(new Event('input', { bubbles: true }));
          element.dispatchEvent(new Event('change', { bubbles: true }));
        }
        
        // If submit is true, simulate Enter key press
        if (submit) {
          await new Promise(resolve => setTimeout(resolve, 300)); // Wait a moment before submitting
          
          const enterEvent = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 'Enter',
            code: 'Enter',
            keyCode: 13
          });
          
          element.dispatchEvent(enterEvent);
        }
        
        const fieldName = description || selector;
        const displayText = mask ? '[secure text]' : text;
        this.logDebug(`Filled input "${fieldName}" with text: ${displayText}`);
        
        if (showFeedback) {
          this.showToast(`Entered text in "${fieldName}" field${submit ? ' and submitted' : ''}`, { type: 'success' });
        }
        
        // Record this action
        this.executedActions.push({
          type: 'fill',
          element: fieldName,
          timestamp: Date.now()
        });
        
        return true;
      }
      
      if (showFeedback) {
        this.showToast(`Element "${description || selector}" is not an input field`, { type: 'error' });
      }
      
      return false;
    } catch (error) {
      this.logDebug(`Error filling input: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error entering text in "${description || selector}": ${error.message}`, { type: 'error' });
      }
      
      return false;
    }
  }

  /**
   * Fill a multi-step form with multiple inputs
   */
  public async fillForm(
    fields: Array<{
      selector: string;
      text: string;
      description?: string;
      mask?: boolean;
      validateAs?: 'email' | 'phone' | 'number' | 'url' | RegExp;
    }>,
    options: {
      submitSelector?: string;
      requireConfirmation?: boolean;
      showFeedback?: boolean;
    } = {}
  ): Promise<boolean> {
    const {
      submitSelector,
      requireConfirmation = true,
      showFeedback = true
    } = options;
    
    // Build form description for confirmation
    if (requireConfirmation) {
      const formFields = fields.map(field => {
        const displayText = field.mask ? '•'.repeat(Math.min(field.text.length, 10)) : field.text;
        return `• ${field.description || field.selector}: ${displayText}`;
      }).join('\n');
      
      const message = `I'll fill out the form with the following information:\n\n${formFields}`;
      const confirmed = await this.getUserConfirmation(message, { title: 'Confirm Form Submission' });
      
      if (!confirmed) {
        this.showToast('Form submission canceled', { type: 'info' });
        return false;
      }
    }
    
    try {
      // Fill each field
      for (const field of fields) {
        const success = await this.fillInput(field.selector, field.text, {
          description: field.description,
          mask: field.mask || false,
          validateAs: field.validateAs,
          showFeedback,
          requireConfirmation: false // Already confirmed the whole form
        });
        
        if (!success) {
          if (showFeedback) {
            this.showToast(`Failed to fill field "${field.description || field.selector}"`, { type: 'error' });
          }
          return false;
        }
        
        // Small delay between fields
        await new Promise(resolve => setTimeout(resolve, 300));
      }
      
      // Submit the form if a submit selector is provided
      if (submitSelector) {
        const submitSuccess = await this.clickElement(submitSelector, {
          description: 'Submit Button',
          showFeedback,
          requireConfirmation: false // Already confirmed the whole form
        });
        
        if (!submitSuccess) {
          if (showFeedback) {
            this.showToast('Failed to submit the form', { type: 'error' });
          }
          return false;
        }
        
        if (showFeedback) {
          this.showToast('Form submitted successfully', { type: 'success' });
        }
      }
      
      return true;
    } catch (error) {
      this.logDebug(`Error filling form: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error filling form: ${error.message}`, { type: 'error' });
      }
      
      return false;
    }
  }
  
  /**
   * Validate input based on type
   */
  private validateInput(input: string, validationType: 'email' | 'phone' | 'number' | 'url' | RegExp): boolean {
    if (typeof validationType === 'string') {
      switch (validationType) {
        case 'email':
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
        case 'phone':
          return /^[\d\+\-\(\)\s]{7,20}$/.test(input);
        case 'number':
          return /^-?\d*\.?\d+$/.test(input);
        case 'url':
          try {
            new URL(input);
            return true;
          } catch {
            return false;
          }
      }
    } else if (validationType instanceof RegExp) {
      return validationType.test(input);
    }
    
    return true; // Default: no validation
  }
  
  /**
   * Select an option from a dropdown with enhanced visual feedback
   */
  public async selectOption(
    selector: string,
    option: string,
    options: {
      byValue?: boolean,
      description?: string,
      showFeedback?: boolean,
      requireConfirmation?: boolean
    } = {}
  ): Promise<boolean> {
    const {
      byValue = false,
      description,
      showFeedback = true,
      requireConfirmation = false
    } = options;
    
    const element = await this.findElement(selector, {
      highlight: showFeedback
    });
    
    if (!element || !(element instanceof HTMLSelectElement)) {
      this.logDebug(`Could not find select element: "${selector}"`);
      
      if (showFeedback) {
        this.showToast(`Could not find dropdown "${description || selector}"`, { type: 'error' });
      }
      
      return false;
    }
    
    // Get confirmation if required
    if (requireConfirmation) {
      const fieldName = description || selector;
      const message = `Do you want me to select "${option}" from the "${fieldName}" dropdown?`;
      
      const confirmed = await this.getUserConfirmation(message);
      
      if (!confirmed) {
        if (showFeedback) {
          this.showToast(`Action canceled: Did not change "${fieldName}" dropdown`, { type: 'info' });
        }
        return false;
      }
    }
    
    try {
      // Show visual feedback
      if (showFeedback && element instanceof HTMLElement) {
        this.highlightElement(element, `Selecting: ${option}`, 1500);
      }
      
      // Scroll element into view if needed
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
      // Wait a moment for scrolling
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Focus the element
      element.focus();
      
      // Find the option
      const options = Array.from(element.options);
      let selectedIndex = -1;
      let optionText = '';
      
      if (byValue) {
        // Find option by value
        selectedIndex = options.findIndex(opt => opt.value === option);
        if (selectedIndex >= 0) {
          optionText = options[selectedIndex].text;
        }
      } else {
        // Find option by text
        selectedIndex = options.findIndex(opt => 
          opt.text.trim().toLowerCase() === option.toLowerCase()
        );
        
        // If not found, try partial match
        if (selectedIndex === -1) {
          selectedIndex = options.findIndex(opt => 
            opt.text.trim().toLowerCase().includes(option.toLowerCase())
          );
        }
        
        if (selectedIndex >= 0) {
          optionText = options[selectedIndex].text;
        }
      }
      
      if (selectedIndex >= 0) {
        // Set the selected index
        element.selectedIndex = selectedIndex;
        
        // Dispatch events
        element.dispatchEvent(new Event('change', { bubbles: true }));
        
        const fieldName = description || selector;
        this.logDebug(`Selected option "${optionText}" from "${fieldName}"`);
        
        if (showFeedback) {
          this.showToast(`Selected "${optionText}" from "${fieldName}" dropdown`, { type: 'success' });
        }
        
        // Record this action
        this.executedActions.push({
          type: 'select',
          element: fieldName,
          timestamp: Date.now()
        });
        
        return true;
      }
      
      this.logDebug(`Option "${option}" not found in select "${selector}"`);
      
      if (showFeedback) {
        this.showToast(`Couldn't find option "${option}" in dropdown`, { type: 'error' });
      }
      
      return false;
    } catch (error) {
      this.logDebug(`Error selecting option: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error selecting from dropdown: ${error.message}`, { type: 'error' });
      }
      
      return false;
    }
  }
  
  /**
   * Read content from an element with enhanced detection
   */
  public async readElement(
    selector: string,
    options: {
      attribute?: string,
      description?: string,
      showFeedback?: boolean,
      formatOutput?: boolean,
      waitForElement?: boolean,
      waitTimeout?: number
    } = {}
  ): Promise<string | null> {
    const {
      attribute,
      description,
      showFeedback = true,
      formatOutput = true,
      waitForElement = true,
      waitTimeout = 5000
    } = options;
    
    // Wait for the element if requested
    if (waitForElement) {
      const elementAppeared = await this.waitForElement(selector, waitTimeout);
      
      if (!elementAppeared) {
        this.logDebug(`Timeout waiting for element to read: "${selector}"`);
        
        if (showFeedback) {
          this.showToast(`Couldn't find "${description || selector}" to read`, { type: 'error' });
        }
        
        return null;
      }
    }
    
    const element = await this.findElement(selector, {
      highlight: showFeedback
    });
    
    if (!element) {
      this.logDebug(`Could not find element to read: "${selector}"`);
      
      if (showFeedback) {
        this.showToast(`Couldn't find "${description || selector}" to read`, { type: 'error' });
      }
      
      return null;
    }
    
    try {
      // If an attribute was specified, read that
      if (attribute) {
        const attrValue = element.getAttribute(attribute);
        this.logDebug(`Read attribute "${attribute}" from "${selector}": ${attrValue}`);
        
        if (showFeedback) {
          this.showToast(`Read ${description || selector}`, { type: 'info' });
        }
        
        return attrValue;
      }
      
      // For inputs, read value instead of text content
      if (
        element instanceof HTMLInputElement || 
        element instanceof HTMLTextAreaElement ||
        element instanceof HTMLSelectElement
      ) {
        const value = element.value;
        this.logDebug(`Read value from "${selector}": ${value}`);
        
        if (showFeedback) {
          this.showToast(`Read value from ${description || selector}`, { type: 'info' });
        }
        
        return value;
      }
      
      // Otherwise read the text content
      let content = element.textContent?.trim() || '';
      
      // Format output if requested
      if (formatOutput) {
        // Replace multiple spaces with single space
        content = content.replace(/\s+/g, ' ');
        
        // Replace multiple newlines with single newline
        content = content.replace(/(\r\n|\n|\r)+/g, '\n');
      }
      
      this.logDebug(`Read content from "${selector}": ${content.substring(0, 50)}${content.length > 50 ? '...' : ''}`);
      
      if (showFeedback) {
        this.showToast(`Read content from ${description || selector}`, { type: 'info' });
      }
      
      return content;
    } catch (error) {
      this.logDebug(`Error reading element: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error reading ${description || selector}: ${error.message}`, { type: 'error' });
      }
      
      return null;
    }
  }
  
  /**
   * Read multiple elements and return as a structured object
   */
  public async readElements(
    selectors: { [key: string]: string },
    options: {
      showFeedback?: boolean,
    } = {}
  ): Promise<{ [key: string]: string | null }> {
    const { showFeedback = true } = options;
    
    const result: { [key: string]: string | null } = {};
    
    try {
      // Read each element
      for (const [key, selector] of Object.entries(selectors)) {
        const content = await this.readElement(selector, {
          description: key,
          showFeedback: false // Don't show individual toasts
        });
        
        result[key] = content;
      }
      
      if (showFeedback) {
        const count = Object.keys(selectors).length;
        this.showToast(`Read ${count} elements successfully`, { type: 'info' });
      }
      
      return result;
    } catch (error) {
      this.logDebug(`Error reading multiple elements: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error reading multiple elements: ${error.message}`, { type: 'error' });
      }
      
      return result;
    }
  }
  
  /**
   * Wait for an element to appear on the page with feedback
   */
  public async waitForElement(
    selector: string,
    timeout: number = 5000,
    options: {
      description?: string,
      showFeedback?: boolean,
      pollInterval?: number
    } = {}
  ): Promise<boolean> {
    const {
      description,
      showFeedback = true,
      pollInterval = 100
    } = options;
    
    const startTime = Date.now();
    const elementName = description || selector;
    
    // Show initial feedback if waiting more than a second
    if (showFeedback && timeout > 1000) {
      this.showToast(`Waiting for ${elementName} to appear...`, { type: 'info' });
    }
    
    return new Promise(resolve => {
      // Function to check for element
      const checkForElement = async () => {
        const element = await this.findElement(selector, {
          highlight: false,
          timeout: 0
        });
        
        if (element) {
          const elapsed = Date.now() - startTime;
          this.logDebug(`Element "${selector}" appeared after ${elapsed}ms`);
          
          if (showFeedback) {
            this.showToast(`Found ${elementName} after ${Math.round(elapsed / 100) / 10}s`, { type: 'success' });
          }
          
          resolve(true);
          return;
        }
        
        // Check if we've exceeded timeout
        if (Date.now() - startTime > timeout) {
          this.logDebug(`Timeout waiting for element "${selector}"`);
          
          if (showFeedback) {
            this.showToast(`Timeout waiting for ${elementName}`, { type: 'warning' });
          }
          
          resolve(false);
          return;
        }
        
        // Check again after the poll interval
        setTimeout(checkForElement, pollInterval);
      };
      
      // Start checking
      checkForElement();
    });
  }
  
  /**
   * Wait for navigation to occur
   */
  public async waitForNavigation(
    fromUrl: string,
    timeout: number = 5000,
    options: {
      targetUrl?: string,
      showFeedback?: boolean
    } = {}
  ): Promise<boolean> {
    const { 
      targetUrl,
      showFeedback = true
    } = options;
    
    const startTime = Date.now();
    
    // Normalize the starting URL
    const normalizeUrl = (url: string): string => {
      return url.replace(/#.*$/, ''); // Remove hash part
    };
    
    const normalizedFromUrl = normalizeUrl(fromUrl);
    
    return new Promise(resolve => {
      // Function to check for navigation
      const checkForNavigation = () => {
        const currentUrl = normalizeUrl(window.location.href);
        
        // Check if URL has changed
        const hasNavigated = currentUrl !== normalizedFromUrl;
        
        // If target URL is specified, check for that specific URL
        const reachedTarget = targetUrl ? 
          currentUrl === normalizeUrl(targetUrl) || currentUrl.includes(normalizeUrl(targetUrl)) : 
          true;
        
        if (hasNavigated && reachedTarget) {
          const elapsed = Date.now() - startTime;
          this.logDebug(`Navigation detected after ${elapsed}ms to: ${currentUrl}`);
          
          if (showFeedback) {
            this.showToast(`Page changed successfully`, { type: 'success' });
          }
          
          // Add a small delay to ensure page is loaded
          setTimeout(() => resolve(true), 300);
          return;
        }
        
        // Check if we've exceeded timeout
        if (Date.now() - startTime > timeout) {
          this.logDebug(`Timeout waiting for navigation from: ${fromUrl}`);
          
          if (showFeedback) {
            this.showToast(`Navigation timeout exceeded`, { type: 'warning' });
          }
          
          resolve(false);
          return;
        }
        
        // Check again after a short delay
        setTimeout(checkForNavigation, 100);
      };
      
      // Start checking
      checkForNavigation();
    });
  }

  /**
   * Check if element exists without waiting
   */
  public async elementExists(
    selector: string,
    options: {
      fallbackSelectors?: string[]
    } = {}
  ): Promise<boolean> {
    const element = await this.findElement(selector, {
      fallbackSelectors: options.fallbackSelectors || [],
      timeout: 0
    });
    
    return element !== null;
  }
  
  /**
   * Get a list of recent actions
   */
  public getRecentActions(count: number = 5): {type: string, element: string, timestamp: number}[] {
    return this.executedActions
      .slice(-count)
      .sort((a, b) => b.timestamp - a.timestamp);
  }
  
  /**
   * Upload a file to a file input
   */
  public async uploadFile(
    selector: string,
    fileData: {
      content: string | ArrayBuffer,
      name: string,
      type: string
    },
    options: {
      description?: string,
      showFeedback?: boolean,
      requireConfirmation?: boolean
    } = {}
  ): Promise<boolean> {
    const {
      description,
      showFeedback = true,
      requireConfirmation = true
    } = options;
    
    // This is a security-sensitive operation, always get confirmation
    if (requireConfirmation) {
      const fieldName = description || selector;
      const message = `Do you want me to upload file "${fileData.name}" (${fileData.type}) to the "${fieldName}" field?`;
      
      const confirmed = await this.getUserConfirmation(message, { isDestructive: true });
      
      if (!confirmed) {
        if (showFeedback) {
          this.showToast(`File upload canceled`, { type: 'info' });
        }
        return false;
      }
    }
    
    // Find the file input element
    const element = await this.findElement(selector);
    
    if (!element || !(element instanceof HTMLInputElement) || element.type !== 'file') {
      this.logDebug(`Could not find file input: "${selector}"`);
      
      if (showFeedback) {
        this.showToast(`Could not find file upload field "${description || selector}"`, { type: 'error' });
      }
      
      return false;
    }
    
    try {
      // Create a new File object
      const file = new File([fileData.content], fileData.name, { type: fileData.type });
      
      // Create a DataTransfer object to simulate file selection
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      
      // Set the files property of the input element
      element.files = dataTransfer.files;
      
      // Dispatch change event to notify listeners
      element.dispatchEvent(new Event('change', { bubbles: true }));
      
      this.logDebug(`Uploaded file "${fileData.name}" to "${selector}"`);
      
      if (showFeedback) {
        this.showToast(`Uploaded file "${fileData.name}"`, { type: 'success' });
      }
      
      // Record this action
      this.executedActions.push({
        type: 'upload',
        element: description || selector,
        timestamp: Date.now()
      });
      
      return true;
    } catch (error) {
      this.logDebug(`Error uploading file: ${error.message}`);
      
      if (showFeedback) {
        this.showToast(`Error uploading file: ${error.message}`, { type: 'error' });
      }
      
      return false;
    }
  }
  
  /**
   * Log debug messages if debug mode is enabled
   */
  private logDebug(...args: any[]): void {
    if (this.debugMode) {
      console.log('[UIController]', ...args);
    }
  }
  
  /**
   * Delay execution for a specified time
   */
  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

// Export singleton instance
export const uiController = new UIController({ debug: true });

// Export class for custom instantiation
export default UIController;