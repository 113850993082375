import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, CreditCard, Smartphone, DollarSign, AlertCircle } from 'lucide-react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Asset } from '@/components/wallet/WalletModels';
import { PaymentMethod } from '@/components/payment_method/PaymentMethodModel';
import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Badge } from '@/components/ui/badge';
import { useWallet } from '@/contexts/WalletContext';

const BuyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { buyedAGC, buyedTransaction, buyAGC, getBuyTransactionStatus } = useWallet();
  const asset: Asset = location.state?.asset;
  const paymentMethod: PaymentMethod = location.state?.paymentMethod || {};
  
  const [amount, setAmount] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [creditCardNumber, setCreditCardNumber] = useState<string>('');  
  const [creditCardError, setCreditCardError] = useState<string | null>(null);
  const [expirationDate, setExpirationDate] = useState<string>('');  
  const [expirationDateError, setExpirationDateError] = useState<string | null>(null);
  const [cvv, setCvv] = useState<string>('');  
  const [cvvError, setCvvError] = useState<string | null>(null);
  
  const [amountError, setAmountError] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Format credit card input
  const formatCreditCardNumber = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];
    
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    
    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  // Format expiration date input
  const formatExpirationDate = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    
    if (v.length >= 2) {
      return `${v.substring(0, 2)}/${v.substring(2, 4)}`;
    }
    
    return v;
  };

  // Validate credit card
  const validateCreditCard = () => {
    const cardNumber = creditCardNumber.replace(/\s+/g, '');
    
    if (!cardNumber) {
      setCreditCardError('Credit card number is required');
      return false;
    }
    
    if (!/^\d{16}$/.test(cardNumber)) {
      setCreditCardError('Please enter a valid 16-digit credit card number');
      return false;
    }
    
    setCreditCardError(null);
    return true;
  };

  // Validate expiration date
  const validateExpirationDate = () => {
    const expDate = expirationDate.replace(/\s+/g, '').replace(/\//g, '');
    
    if (!expDate) {
      setExpirationDateError('Expiration date is required');
      return false;
    }
    
    if (!/^\d{4}$/.test(expDate)) {
      setExpirationDateError('Please enter a valid expiration date (MM/YY)');
      return false;
    }
    
    const month = parseInt(expDate.substring(0, 2));
    const year = parseInt('20' + expDate.substring(2, 4));
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    
    if (month < 1 || month > 12) {
      setExpirationDateError('Please enter a valid month (01-12)');
      return false;
    }
    
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      setExpirationDateError('Card has expired');
      return false;
    }
    
    setExpirationDateError(null);
    return true;
  };

  // Validate CVV
  const validateCVV = () => {
    if (!cvv) {
      setCvvError('CVV is required');
      return false;
    }
    
    if (!/^\d{3,4}$/.test(cvv)) {
      setCvvError('Please enter a valid CVV code');
      return false;
    }
    
    setCvvError(null);
    return true;
  };

  // Format phone number
  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    
    if (match) {
      return !match[2] ? match[1] : 
             `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
    }
    
    return value;
  };

  const handleBuy = async () => {
    const amountNumber = Number(amount);
    let valid = true;

    // Validate amount
    if (!amount || amountNumber <= 0) {
      setAmountError('Please enter a valid amount');
      valid = false;
    } else {
      setAmountError(null);
    }

    // Validate phone number if not credit card
    if (paymentMethod.paymentName !== 'CREDITCARD' && (!phoneNumber || phoneNumber.length < 10)) {
      setPhoneNumberError('Please enter a valid phone number');
      valid = false;
    } else {
      setPhoneNumberError(null);
    }

    // Validate credit card fields if credit card payment
    if (paymentMethod.paymentName === 'CREDITCARD') {
      valid = validateCreditCard() && valid;
      valid = validateExpirationDate() && valid;
      valid = validateCVV() && valid;
    }

    if (!valid) return;

    setLoading(true);

    try {
      await buyAGC(paymentMethod._id, amountNumber, { 
        phone: phoneNumber.replace(/\D/g, ''),
        ...(paymentMethod.paymentName === 'CREDITCARD' && {
          cardNumber: creditCardNumber.replace(/\s+/g, ''),
          expirationDate: expirationDate,
          cvv: cvv
        })
      });
      
      if (buyedAGC.status === 'Pending') {
        toast.success('Transaction initiated successfully!', {
          description: 'Check your email for confirmation details.',
          duration: 5000
        });
        navigate('/dashboard/wallet');  
      } 
      setLoading(false);
    } catch (error) {
      toast.error('Transaction failed', {
        description: 'There was an error processing your purchase. Please try again.',
        duration: 5000
      });
      setLoading(false);
    }
  };

  const renderForm = () => {
    if (asset?.type === 'Crypto' || asset?.type === 'Fiat') {
      return (
        <div className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="amount" className="text-sm font-medium">Amount</Label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
              <Input
                id="amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                min="0"
                className="pl-10 w-full"
                placeholder="Enter amount"
                disabled={loading}
              />
            </div>
            {amountError && (
              <div className="flex items-center mt-1 text-red-500 text-sm">
                <AlertCircle className="h-4 w-4 mr-1" />
                <span>{amountError}</span>
              </div>
            )}
          </div>
          
          {paymentMethod.paymentName !== 'CREDITCARD' && (
            <div className="space-y-2">
              <Label htmlFor="phone" className="text-sm font-medium">Phone Number</Label>
              <div className="relative">
                <Smartphone className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                <Input
                  id="phone"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
                  className="pl-10 w-full"
                  placeholder="(123) 456-7890"
                  disabled={loading}
                />
              </div>
              {phoneNumberError && (
                <div className="flex items-center mt-1 text-red-500 text-sm">
                  <AlertCircle className="h-4 w-4 mr-1" />
                  <span>{phoneNumberError}</span>
                </div>
              )}
            </div>
          )}

          {paymentMethod.paymentName === 'CREDITCARD' && (
            <>
              <div className="space-y-2">
                <Label htmlFor="creditCard" className="text-sm font-medium">Credit Card Number</Label>
                <div className="relative">
                  <CreditCard className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                  <Input
                    id="creditCard"
                    type="text"
                    value={creditCardNumber}
                    onChange={(e) => setCreditCardNumber(formatCreditCardNumber(e.target.value))}
                    className="pl-10 w-full"
                    placeholder="0000 0000 0000 0000"
                    maxLength={19}
                    disabled={loading}
                  />
                </div>
                {creditCardError && (
                  <div className="flex items-center mt-1 text-red-500 text-sm">
                    <AlertCircle className="h-4 w-4 mr-1" />
                    <span>{creditCardError}</span>
                  </div>
                )}
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="expDate" className="text-sm font-medium">Expiration Date</Label>
                  <Input
                    id="expDate"
                    type="text"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(formatExpirationDate(e.target.value))}
                    className="w-full"
                    placeholder="MM/YY"
                    maxLength={5}
                    disabled={loading}
                  />
                  {expirationDateError && (
                    <div className="flex items-center mt-1 text-red-500 text-sm">
                      <AlertCircle className="h-4 w-4 mr-1" />
                      <span>{expirationDateError}</span>
                    </div>
                  )}
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="cvv" className="text-sm font-medium">CVV</Label>
                  <Input
                    id="cvv"
                    type="text"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value.replace(/\D/g, ''))}
                    className="w-full"
                    placeholder="123"
                    maxLength={4}
                    disabled={loading}
                  />
                  {cvvError && (
                    <div className="flex items-center mt-1 text-red-500 text-sm">
                      <AlertCircle className="h-4 w-4 mr-1" />
                      <span>{cvvError}</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      );
    }
    return null;
  };

  if (!asset) {
    return (
      <DashboardLayout>
        <div className="flex flex-col items-center justify-center h-64">
          <h2 className="text-xl font-semibold">No asset selected</h2>
          <p className="text-gray-500 mt-2">Please select an asset to continue</p>
          <Button 
            className="mt-4" 
            onClick={() => navigate('/dashboard/wallet')}
          >
            Go to Wallet
          </Button>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 animate-fade-in max-w-3xl mx-auto w-full">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate('/dashboard/wallet/payment', { state: { asset } })}
            aria-label="Go back"
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Buy {asset.assetName}</h1>
            <p className="text-gray-500 text-sm mt-1">Using {paymentMethod.paymentName || 'selected payment method'}</p>
          </div>
        </div>

        <Card className="shadow-sm">
          <CardHeader className="pb-4">
            <div className="flex justify-between items-center">
              <CardTitle className="text-lg">Purchase Details</CardTitle>
              <Badge variant="outline" className="text-sm">
                {asset.type === 'Crypto' ? 'Cryptocurrency' : 'Fiat Currency'}
              </Badge>
            </div>
            <CardDescription>
              Enter your payment information to complete your purchase
            </CardDescription>
          </CardHeader>
          
          <Separator />
          
          <CardContent className="pt-6">
            {renderForm()}
          </CardContent>
          
          <CardFooter className="flex flex-col pt-4">
            <Button 
              onClick={handleBuy} 
              className="w-full" 
              disabled={loading}
              size="lg"
            >
              {loading ? 'Processing...' : `Buy ${asset.assetName}`}
            </Button>
            <p className="text-xs text-gray-500 text-center mt-4">
              By completing this purchase, you agree to our Terms of Service and Privacy Policy
            </p>
          </CardFooter>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default BuyPage;