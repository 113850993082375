import apiClient from './apiClient';

// Extended user service for profile operations
const userService = {
  // Get current user profile
  getProfile: async () => {
    const response = await apiClient.get('/profile');
    // The API returns data in a "data" property
    if (response.data && response.data.data) {
      const secondUserData = response.data.data;
      // Retrieve existing user data
      const existingUserData = JSON.parse(localStorage.getItem('user')) || {};

      // Merge existing data with new data
      const updatedUserData = { ...existingUserData, ...secondUserData };

      // Store the updated user data back to local storage
      localStorage.setItem('user', JSON.stringify(updatedUserData));
    } else {
      console.warn('Profile response missing user data');
    }
    return response.data;
  },

  updateProfile: async (profileData: { lastName: string; firstName: string; avatar: File; deleteAvatar: boolean }) => {
    // Create a FormData object
    const formData = new FormData();
    formData.append('lastName', profileData.lastName);
    formData.append('firstName', profileData.firstName);
    formData.append('avatar', profileData.avatar); // This should be a File object
    formData.append('deleteAvatar', profileData.deleteAvatar.toString()); // Convert boolean to string

    // Make the PUT request with FormData
    const response = await apiClient.put('/profile', formData);
    return response.data;
  },

  // Update current user email
  updateEmail: async (emailData: { oldEmail: string; email: string }) => {
    const response = await apiClient.put('/update-email', emailData);
    return response.data;
  },

  // Update current user country, phoneNumber, and dateOfBirth
  userKYC: async (kycData: { country: string; phoneNumber: string; dateOfBirth: string }) => {
    const response = await apiClient.put('/user/kyc/step1', kycData);
    return response.data;
  },

  // get the connection history of the current user
  loginHistory: async () => {
    const response = await apiClient.get('/login-history');
    return response.data;
  },

  // verify the user phone number during KYC process
  userKycPhoneVefity: async (phoneVerifyData : {otp: string}) => {
    const response = await apiClient.post('/user/kyc/verify-phone', phoneVerifyData);
    return response.data;
  },

  // resend the OTP to the user phone number during KYC process
  userKycResentPhoneOTP: async () => {
    const response = await apiClient.post('/user/kyc/resend-phone-verification', {});
    return response.data;
  },

  // Initiates identity verification process for KYC Level 2
  userKycLevel2: async () => {
    const response = await apiClient.post('/user/kyc/step2', {});
    return response.data;
  },


};

export default userService;