import { useEffect, useState, useMemo, useRef } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import TransactionList from '@/components/transaction/TransactionList';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { useNavigate } from 'react-router-dom';
import { useTransaction } from '@/contexts/TransactionContext';
import { format } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { 
  CreditCard, 
  Wallet, 
  ArrowUpRight, 
  ArrowDownLeft, 
  RefreshCw, 
  DollarSign,
  Download,
  TrendingUp,
  Calendar,
  Filter,
  Search,
  ArrowUp
} from 'lucide-react';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';

const Transactions = () => {
  const navigate = useNavigate();
  const { transactions, fetchTransactions } = useTransaction();
  const [timeframe, setTimeframe] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    document.title = 'EvoCash | Transactions';
    refreshTransactions();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setShowBackToTop(scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const refreshTransactions = async () => {
    setIsLoading(true);
    try {
      await fetchTransactions();
      toast.success('Transactions updated');
    } catch (error) {
      toast.error('Failed to fetch transactions');
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const filteredTransactions = useMemo(() => {
    return transactions.filter(transaction => {
      const matchesSearch = transaction.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          transaction.amount?.toString().includes(searchQuery);
      const matchesType = filterType === 'all' || transaction.transactionType.toLowerCase() === filterType;
      
      if (timeframe === 'today') {
        return matchesSearch && matchesType && 
               format(new Date(transaction.date), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
      } else if (timeframe === 'week') {
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        return matchesSearch && matchesType && new Date(transaction.date) >= weekAgo;
      }
      return matchesSearch && matchesType;
    });
  }, [transactions, searchQuery, filterType, timeframe]);

  const stats = useMemo(() => {
    const total = filteredTransactions.reduce((acc, t) => acc + t.amount, 0);
    return {
      deposits: {
        count: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('deposit')).length,
        total: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('deposit'))
                                 .reduce((acc, t) => acc + t.amount, 0)
      },
      withdrawals: {
        count: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('withdrawal')).length,
        total: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('withdrawal'))
                                 .reduce((acc, t) => acc + t.amount, 0)
      },
      transfers: {
        count: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('transfer')).length,
        total: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('transfer'))
                                 .reduce((acc, t) => acc + t.amount, 0)
      },
      purchases: {
        count: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('purchase')).length,
        total: filteredTransactions.filter(t => t.transactionType.toLowerCase().includes('purchase'))
                                 .reduce((acc, t) => acc + t.amount, 0)
      }
    };
  }, [filteredTransactions]);

  const handleExportTransactions = () => {
    const csv = filteredTransactions.map(t => 
      `${t.date},${t.transactionType},${t.amount},${t.description}`
    ).join('\n');
    
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    
    // Mobile-friendly approach
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      // For mobile, we'll share the file
      if (navigator.share) {
        const file = new File([blob], `transactions-${format(new Date(), 'yyyy-MM-dd')}.csv`, { 
          type: 'text/csv' 
        });
        
        navigator.share({
          title: 'EvoCash Transactions',
          files: [file]
        }).then(() => {
          toast.success('Transactions shared successfully');
        }).catch((error) => {
          // Fall back to download if sharing fails
          downloadFile(url);
        });
      } else {
        downloadFile(url);
      }
    } else {
      downloadFile(url);
    }
    
    window.URL.revokeObjectURL(url);
  };

  const downloadFile = (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = `transactions-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    toast.success('Transactions exported successfully');
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 md:space-y-8" ref={scrollContainerRef}>
        <section>
          <div className="rounded-xl bg-gradient-to-r from-primary/10 via-primary/5 to-background p-4 md:p-8 backdrop-blur-sm border">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 md:gap-6">
              <div className="space-y-1 md:space-y-2">
                <div className="flex items-center gap-2">
                  <div className="h-7 w-7 md:h-8 md:w-8 rounded-full bg-primary/10 flex items-center justify-center">
                    <CreditCard className="h-3.5 w-3.5 md:h-4 md:w-4 text-primary" />
                  </div>
                  <h1 className="text-xl md:text-2xl font-bold tracking-tight">
                    Transactions
                  </h1>
                </div>
                <p className="text-sm md:text-base text-muted-foreground">
                  Track and manage your transaction history
                </p>
              </div>
              
              <div className="flex flex-wrap gap-2 md:gap-3 w-full md:w-auto mt-4 md:mt-0">
                <Button 
                  variant="outline" 
                  size="sm"
                  className="flex-1 md:flex-none py-5 md:py-2"
                  onClick={refreshTransactions}
                  disabled={isLoading}
                >
                  <RefreshCw className={`mr-2 h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
                  Refresh
                </Button>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="flex-1 md:flex-none py-5 md:py-2"
                  onClick={handleExportTransactions}
                >
                  <Download className="mr-2 h-4 w-4" />
                  Export
                </Button>
                <Button 
                  variant="default"
                  size="sm"
                  className="flex-1 md:flex-none py-5 md:py-2"
                  onClick={() => navigate('/dashboard/wallet')}
                >
                  <Wallet className="mr-2 h-4 w-4" />
                  View Wallet
                </Button>
              </div>
            </div>
          </div>
        </section>

        <div className="flex flex-col gap-3 md:flex-row md:gap-4 md:items-center">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search transactions..."
              className="pl-10 h-12 md:h-10 text-base md:text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          
          <div className="grid grid-cols-2 gap-2 md:flex md:gap-4">
            <Select value={filterType} onValueChange={setFilterType}>
              <SelectTrigger className="h-12 md:h-10 md:w-[180px]">
                <Filter className="mr-2 h-4 w-4" />
                <SelectValue placeholder="Filter by type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Types</SelectItem>
                <SelectItem value="deposit">Deposits</SelectItem>
                <SelectItem value="withdrawal">Withdrawals</SelectItem>
                <SelectItem value="transfer">Transfers</SelectItem>
                <SelectItem value="purchase">Purchases</SelectItem>
              </SelectContent>
            </Select>

            <Select value={timeframe} onValueChange={setTimeframe}>
              <SelectTrigger className="h-12 md:h-10 md:w-[180px]">
                <Calendar className="mr-2 h-4 w-4" />
                <SelectValue placeholder="Select timeframe" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Time</SelectItem>
                <SelectItem value="today">Today</SelectItem>
                <SelectItem value="week">Past Week</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4">
          {Object.entries(stats).map(([type, data]) => (
            <Card 
              key={type} 
              className="p-4 md:p-6 hover:bg-muted/50 transition-all duration-300 cursor-pointer transform hover:-translate-y-1"
            >
              <div className="flex items-center gap-3 md:gap-4">
                <div className={`p-2 md:p-3 rounded-xl ${
                  type === 'deposits' ? 'bg-success/10 text-success' :
                  type === 'withdrawals' ? 'bg-warning/10 text-warning' :
                  type === 'transfers' ? 'bg-primary/10 text-primary' :
                  'bg-info/10 text-info'
                }`}>
                  {type === 'deposits' ? <ArrowDownLeft className="h-4 w-4 md:h-5 md:w-5" /> :
                   type === 'withdrawals' ? <ArrowUpRight className="h-4 w-4 md:h-5 md:w-5" /> :
                   type === 'transfers' ? <RefreshCw className="h-4 w-4 md:h-5 md:w-5" /> :
                   <DollarSign className="h-4 w-4 md:h-5 md:w-5" />}
                </div>
                <div className="flex-1">
                  <h3 className="font-medium capitalize text-sm md:text-base">{type}</h3>
                  <div className="mt-1 md:mt-2 space-y-0.5 md:space-y-1">
                    <div className="text-lg md:text-2xl font-semibold">
                      ${data.total.toLocaleString()}
                    </div>
                    <p className="text-xs md:text-sm text-muted-foreground">
                      {data.count} transactions
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <Card className="p-3 md:p-6">
          <Tabs defaultValue="all" className="w-full">
            <TabsList className="mb-4 w-full flex justify-between">
              <TabsTrigger value="all" className="flex-1 py-2.5 text-sm">All</TabsTrigger>
              <TabsTrigger value="recent" className="flex-1 py-2.5 text-sm">Recent</TabsTrigger>
              <TabsTrigger value="pending" className="flex-1 py-2.5 text-sm">Pending</TabsTrigger>
            </TabsList>
            
            <TabsContent value="all">
              <TransactionList 
                transactions={filteredTransactions}
                className="shadow-sm" 
              />
            </TabsContent>
            
            <TabsContent value="recent">
              <TransactionList 
                transactions={filteredTransactions.slice(0, 5)}
                className="shadow-sm" 
              />
            </TabsContent>
            
            <TabsContent value="pending">
              <TransactionList 
                transactions={filteredTransactions.filter(t => t.status === 'pending')}
                className="shadow-sm" 
              />
            </TabsContent>
          </Tabs>
        </Card>
      </div>
      
      {showBackToTop && (
        <Button
          className="fixed bottom-6 right-6 h-10 w-10 rounded-full p-0 shadow-lg md:hidden"
          onClick={scrollToTop}
          aria-label="Back to top"
        >
          <ArrowUp className="h-5 w-5" />
        </Button>
      )}
    </DashboardLayout>
  );
};

export default Transactions;

