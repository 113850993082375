import { useState } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { Lock, Eye, EyeOff } from 'lucide-react';
import { SignUpFormData } from './SignUpForm';

interface PasswordFieldsProps {
  formData: SignUpFormData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordFields = ({ formData, handleChange }: PasswordFieldsProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };
  
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  const inputVariants = {
    focus: { 
      scale: 1.01,
      boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.3)",
      transition: { 
        type: "spring", 
        stiffness: 700, 
        damping: 30 
      }
    },
  };

  const buttonVariants = {
    hover: { scale: 1.1 },
    tap: { scale: 0.9 }
  };

  // Password strength indicator
  const getPasswordStrength = (password: string) => {
    if (!password) return { strength: 0, color: "bg-gray-700" };
    if (password.length < 8) return { strength: 25, color: "bg-red-500" };
    
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecial = /[^A-Za-z0-9]/.test(password);
    
    const criteria = [hasLowercase, hasUppercase, hasNumber, hasSpecial];
    const metCriteria = criteria.filter(Boolean).length;
    
    if (metCriteria === 1) return { strength: 25, color: "bg-red-500" };
    if (metCriteria === 2) return { strength: 50, color: "bg-orange-500" };
    if (metCriteria === 3) return { strength: 75, color: "bg-yellow-500" };
    if (metCriteria === 4) return { strength: 100, color: "bg-green-500" };
    
    return { strength: 0, color: "bg-gray-700" };
  };

  const passwordStrength = getPasswordStrength(formData.password);

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <label htmlFor="password" className="text-sm font-medium text-gray-300">
          Password
        </label>
        <motion.div
          className="relative"
          whileFocus="focus"
        >
          <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
            <Lock className="h-4 w-4" />
          </span>
          <motion.input
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            required
            value={formData.password}
            onChange={handleChange}
            className={cn(
              "w-full px-10 py-3 rounded-xl",
              "bg-black/40 backdrop-blur-sm",
              "border border-gray-700/50",
              "text-white placeholder:text-gray-500",
              "focus:outline-none focus:ring-0",
              "transition-all duration-200"
            )}
            placeholder="••••••••"
            variants={inputVariants}
          />
          <motion.button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-3 flex items-center text-gray-400 hover:text-gray-300"
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
          >
            {showPassword ? (
              <EyeOff className="h-4 w-4" />
            ) : (
              <Eye className="h-4 w-4" />
            )}
          </motion.button>
        </motion.div>
        
        {/* Password strength indicator */}
        {formData.password && (
          <div className="mt-2">
            <div className="h-1 w-full bg-gray-800 rounded-full overflow-hidden">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${passwordStrength.strength}%` }}
                className={`h-full ${passwordStrength.color}`}
                transition={{ duration: 0.3 }}
              />
            </div>
            <div className="flex justify-between mt-1">
              <span className="text-xs text-gray-500">Weak</span>
              <span className="text-xs text-gray-500">Strong</span>
            </div>
          </div>
        )}
      </div>
      
      <div className="space-y-1">
        <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-300">
          Confirm Password
        </label>
        <motion.div
          className="relative"
          whileFocus="focus"
        >
          <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
            <Lock className="h-4 w-4" />
          </span>
          <motion.input
            id="confirmPassword"
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            required
            value={formData.confirmPassword}
            onChange={handleChange}
            className={cn(
              "w-full px-10 py-3 rounded-xl",
              "bg-black/40 backdrop-blur-sm",
              "border border-gray-700/50",
              "text-white placeholder:text-gray-500",
              "focus:outline-none focus:ring-0",
              "transition-all duration-200"
            )}
            placeholder="••••••••"
            variants={inputVariants}
          />
          <motion.button
            type="button"
            onClick={toggleConfirmPasswordVisibility}
            className="absolute inset-y-0 right-3 flex items-center text-gray-400 hover:text-gray-300"
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
          >
            {showConfirmPassword ? (
              <EyeOff className="h-4 w-4" />
            ) : (
              <Eye className="h-4 w-4" />
            )}
          </motion.button>
        </motion.div>
        
        {/* Password match indicator */}
        {formData.confirmPassword && (
          <div className="mt-1">
            {formData.password === formData.confirmPassword ? (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-xs text-green-500"
              >
                Passwords match
              </motion.p>
            ) : (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-xs text-red-500"
              >
                Passwords do not match
              </motion.p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};